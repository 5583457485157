import api from './serviceApi'; // Import the axios instance

const groupApi = {
  /**
   * Fetch a group by code.
   * @param {string} include - Optional parameter to include additional data.
   * @returns {Promise<Object>} - The group data.
   */
  getGroups: async (include = '') => {
    try {
      const response = await api.get(`/group`, {
        params: { include }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching group by code:', error);
      throw error;
    }
  },

  /**
   * Fetch a group by code.
   * @param {string} code - The code of the group.
   * @param {string} include - Optional parameter to include additional data.
   * @returns {Promise<Object>} - The group data.
   */
  getGroupByCode: async (code, include = '') => {
    try {
      const response = await api.get(`/group/${code}`, {
        params: { include }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching group by code:', error);
      throw error;
    }
  },

  /**
   * Fetch a list of groups with optional filters and pagination.
   * @param {Object} params - Query parameters such as page, quantity, term, orderBy, etc.
   * @returns {Promise<Object>} - The paginated list of groups.
   */
  getGroupsPaginated: async (params) => {
    try {
      const response = await api.get('/group/pagged', { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching groups:', error);
      throw error;
    }
  },

  /**
   * Export a list of groups with filters.
   * @param {Object} params - Query parameters such as quantityMax, isActive, term, etc.
   * @returns {Promise<Blob>} - The exported file data.
   */
  exportGroups: async (params) => {
    try {
      const response = await api.get('/group/export', { params, responseType: 'blob' });
      return response.data;
    } catch (error) {
      console.error('Error exporting groups:', error);
      throw error;
    }
  },

  /**
   * Fetch all groups.
   * @param {Object} [params] - Optional query parameters.
   * @returns {Promise<Object>} - The list of groups.
   */
  getAllGroups: async (params = {}) => {
    try {
      const response = await api.get('/group', { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching groups:', error);
      throw error;
    }
  },

  /**
   * Fetch all groups.
   * @param {Object} [params] - Optional query parameters.
   * @returns {Promise<Object>} - The list of groups.
   */
  getAllTypeGroups: async (params = {}) => {
    try {
      const response = await api.get('/group/types', { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching groups:', error);
      throw error;
    }
  },

  /**
   * Create a new group.
   * @param {Object} groupData - The group data to create.
   * @returns {Promise<Object>} - The created group data.
   */
  createGroup: async (groupData) => {
    try {
      const response = await api.post('/group', groupData);
      return response.data;
    } catch (error) {
      console.error('Error creating group:', error);
      throw error;
    }
  },

  /**
   * Update an existing group.
   * @param {Object} groupData - The group data to update.
   * @returns {Promise<Object>} - The updated group data.
   */
  updateGroup: async (code, groupData) => {
    try {
      const response = await api.put('/group/' + code, groupData);
      return response.data;
    } catch (error) {
      console.error('Error updating group:', error);
      throw error;
    }
  },

  /**
   * inativate a group by their code.
   * @param {string} groupCode - The group code to inativate.
   * @returns {Promise<Object>} - The result of the deletion.
   */
  setInactive: async (groupCode) => {
    try {
      const response = await api.put('/group/setInactive/' + groupCode);
      return response.data;
    } catch (error) {
      console.error('Error inativating group:', error);
      throw error;
    }
  },

  /**
   * active a group by their code.
   * @param {string} groupCode - The group code to active.
   * @returns {Promise<Object>} - The result of the deletion.
   */
  setActive: async (groupCode) => {
    try {
      const response = await api.put('/group/setActive/' + groupCode);
      return response.data;
    } catch (error) {
      console.error('Error inativating group:', error);
      throw error;
    }
  },

  /**
   * Delete a group.
   * @param {string} code - The code of the group to delete.
   * @returns {Promise<Object>} - The result of the deletion.
   */
  deleteGroup: async (code) => {
    try {
      const response = await api.delete('/group', {
        data: { code }
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting group:', error);
      throw error;
    }
  }
};

export default groupApi;
