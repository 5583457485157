import React, { useState, useEffect } from 'react';
import './ClientList.css';
import personApi from '../../../services/apiService/personApi';
import { setLoading } from '../../../services/redux/loadingSlice';
import { useDispatch } from 'react-redux';
import configService from '../../../services/configService';
import Pagination from '../../../components/common/Pagination/Pagination'; 
import { toast } from 'react-toastify';
import { putDateOnPattern } from '../../../utils/functions';
import FilterComponent from '../../../components/admin/FilterComponent/FilterComponent';
import { saveAs } from 'file-saver';
import UserRegistrationModal from '../../modals/UserRegistrationModal/UserRegistrationModal';

const ClientListPage = ({selectClient}) => {
    const dispatch = useDispatch();
    const [isClientRegisterOpen, setIsClientRegisterOpen] = useState(false);
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [refresh, setRefresh] = useState(false);
    const quantity = configService.getDefaultNumberOfItemsTable(); 
    const orderBy = "Created:Desc";

    const openRegisterClient = () => {
        setIsClientRegisterOpen(true);
    }

    const closeRegisterClient = () => {
        setIsClientRegisterOpen(false);
    }

    useEffect(() => {
        const fetchLogs = async () => {
            dispatch(setLoading(true));
            try {
                const response = await personApi.getPersonsPaginated({ personType: configService.getClientPersonTypeCode(), page, quantity, orderBy, term: searchTerm });
                setItems(response.Results);
                setTotalPages(response.TotalPages);
                setTotalItems(response.TotalCount);
            } catch (error) {
                toast.error('Erro ao buscar os itens.');
            } finally {
                dispatch(setLoading(false));
            }
        };
        fetchLogs();
    }, [page, quantity, searchTerm, refresh, dispatch]);

    const openClient = (code) => {
        const url = `/admin/clientes/${code}`;
        window.open(url, '_blank');
    }

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    const search = ({term} = {}) => {
        setSearchTerm(term);
    }

    const exportFunction = async (term) => {
        try {
            dispatch(setLoading(true));
            const response = await personApi.exportPersons({ term: term });
            
            if (response.Status === 200 && response.Object) {
                saveAs(response.Object, 'reportClient.csv');
                toast.success('Relatório gerado com sucesso!');
            } else {
                toast.error('Erro ao gerar o relatório 1');
            }
        } catch (error) {
            toast.error('Erro ao gerar o relatório');
        }
        finally{
            dispatch(setLoading(false));
        }
    };

    const createClient = async (client) =>{
        try{
            dispatch(setLoading(true));
            var response = await personApi.createPerson(client);

            toast.success(response.Name + ' Criado com sucesso!');
            setRefresh((prev) => !prev);
        }
        catch(error){
            toast.error('Error :' + error);
        }
        finally{
            dispatch(setLoading(false));
        }
    }

    return (
    <div className="container-admin-page">
        <UserRegistrationModal isOpen={isClientRegisterOpen} onClose={closeRegisterClient} onSubmit={createClient} loginAfterDone={false} personTypeCode={configService.getClientPersonTypeCode()} />
        <h1>Clientes</h1>
        <div className='container-admin-page-filters div-with-border'>
            <h3>Filtros</h3>
            <FilterComponent placeHolder={'Nome | Email | Documento'} showTermFilter={true} showEndDate={false} showStartDate={false} submitFilter={search} exportFunction={exportFunction}/>
        </div>
        <div className='container-admin-page-table div-with-border'>
            <div className='right-component'>
                <button className='main-button' onClick={openRegisterClient}>Novo Cliente</button>
            </div>
            <table className="admin-table">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>Documento</th>
                        <th>Data de Criação</th>
                        <th></th>
                    </tr>
                </thead>
            <tbody>
                {items.map((item) => (
                    <tr key={item.Code}>
                        <td><span className='option-link' onClick={() => openClient(item.Code)}>{item.Name}</span></td>
                        <td><span className='option-link' onClick={() => openClient(item.Code)}>{item.Email}</span></td>
                        <td><span className='option-link' onClick={() => openClient(item.Code)}>{item.PhysicalDocument ?? item.LegalDocument}</span></td>
                        <td><span className='option-link' onClick={() => openClient(item.Code)}>{putDateOnPattern(item.Created)}</span></td>
                        <td><span className='option-link' onClick={() => openClient(item.Code)}>{putDateOnPattern(item.Updated)}</span></td>
                        <td><button className='clickable item-active brighten-on-hover' onClick={() => selectClient(item.Code)}>Selecionar</button></td>
                    </tr>
                ))}
                </tbody>
            </table>
            <sub>Total de Clientes: {totalItems}</sub>
            <Pagination page={page} totalPages={totalPages} onPageChange={handlePageChange} />
        </div>
    </div>
    );
};

export default ClientListPage;
