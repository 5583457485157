import './PersonListPage.css';
import React, { useEffect, useState } from 'react';
import personApi from '../../../services/apiService/personApi';
import SearchIcon from '../../../components/icons/SearchIcon/SearchIcon';
import configService from '../../../services/configService';
import { setLoading } from '../../../services/redux/loadingSlice';
import { useDispatch } from 'react-redux';
import ConfirmModal from '../../../components/modals/ConfirmModal/ConfirmModal';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../components/common/Pagination/Pagination'; 
import UserRegistrationModal from '../../../components/modals/UserRegistrationModal/UserRegistrationModal';

const PersonListPage = ({personType, personTypeName, title}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
    const [clients, setClients] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalClients, setTotalClients] = useState(0);
    const [term, setTerm] = useState('');
    const [search, setSearch] = useState('');
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [clientSelected, setClientSelected] = useState();
    const [refresh, setRefresh] = useState(false);
    const [isRegisterOpen, setRegisterOpen] = useState(false);
  
    useEffect(() => {
      const fetchClients = async () => {
        dispatch(setLoading(true));
        try {
          const response = await personApi.getPersonsPaginated({ 
            page, 
            quantity: configService.getDefaultNumberOfItemsTable(), 
            personType: personType,
            term: term,
            orderBy: 'Created:Desc'
          });
          setClients(response.Results);
          setTotalPages(response.TotalPages);
          setTotalClients(response.TotalCount);
        } catch (error) {
          console.error('Error fetching clients:', error);
        } finally {
          dispatch(setLoading(false));
        }
      };
      fetchClients();
    }, [dispatch, page, personType, refresh, term]); 
  
    const handlePageChange = (newPage) => {
      if (newPage > 0 && newPage <= totalPages) {
        setPage(newPage);
      }
    };

    const deleteOption = (client) => {
      setClientSelected(client);
      setIsModalDeleteOpen(true);
    }

    const openClient = (code) => {
      navigate(`${code}`);
    }

    const handleYesModalDelete = async (item) => {
      dispatch(setLoading(true));
      try {
        if(item.IsActive) {
          await personApi.setInactive(item.Code);
        } else {
          await personApi.setActive(item.Code);
        }
        toast.success(personTypeName + ' atualizado com sucesso!');
        // Trigger the refresh to re-fetch data
        setRefresh(prev => !prev);
      } catch (error) {
        toast.error('Error updating client status:' + error);
      } finally {
        dispatch(setLoading(false));
        setIsModalDeleteOpen(false);
      }
    };
  
    const handleNoModalDelete = () =>{
      setIsModalDeleteOpen(false);
    };
  
    return (
      <div className="container-admin-page">
        <h1>
          {title}
        </h1>
        <ConfirmModal
          isOpen={isModalDeleteOpen}
          title="Atenção"
          message={`Deseja realmente ${clientSelected?.IsActive ? 'desativar' : 'ativar'} o usuário ${clientSelected?.Email}?`}
          onYes={handleYesModalDelete}
          onNo={handleNoModalDelete}
          yesLabel="Confirm"
          noLabel="Cancel"
          confirmData={clientSelected}
        />
        <div>
          <UserRegistrationModal 
            personTypeCode={personType}
            loginAfterDone={false}
            isOpen={isRegisterOpen} 
            onClose={() => {
              setRegisterOpen(false);
              setRefresh(prev => !prev);
            }} />
        </div>
        <div className="clients-header">
          <div className='clients-header-search'>
            <input 
              type="text" 
              placeholder="Pesquisar" 
              className="search-bar" 
              value={search} 
              onChange={(e) => setSearch(e.target.value)} 
              onKeyDown={(e) => e.key === 'Enter' && setTerm(search)}
            /> 
            <div className='form-group-input-icon clickable' onClick={() => setTerm(search)}>
              <SearchIcon color='black' />
            </div>
          </div>
          <button className="new-client-btn brighten-on-hover" onClick={() => setRegisterOpen(true)}>+ Novo {personTypeName}</button>
        </div>
        <table className="admin-table">
          <thead>
            <tr>
              <th>Código</th>
              <th>Nome</th>
              <th>Email</th>
              <th>Data Criação</th>
              <th className='text-align-center'>Status</th>
            </tr>
          </thead>
          <tbody>
            {clients.map((client) => (
              <tr key={client.Code}>
                <td><span className='option-link' onClick={() => openClient(client.Code)}>{client.Code}</span></td>
                <td><span className='option-link' onClick={() => openClient(client.Code)}>{client.Name}</span></td>
                <td><span className='option-link' onClick={() => openClient(client.Code)}>{client.Email}</span></td>
                <td>{new Date(client.Created).toLocaleDateString()}</td>
                <td className='text-align-center clickable brighten-on-hover'>
                  <span className={client.IsActive ? 'item-active brighten-on-hover' : 'item-inactive brighten-on-hover'}  onClick={() => deleteOption(client)}>{client.IsActive ? 'Ativo' : 'Inativo'}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <sub>Total de {title}: {totalClients}</sub>
        <Pagination page={page} totalPages={totalPages} onPageChange={handlePageChange} />
      </div>
    );
};
  
export default PersonListPage;