import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../../services/redux/loadingSlice';
import cityApiService from '../../../../services/apiService/cityApiService';
import { toast } from 'react-toastify';
import { maskCEP } from '../../../masks/masks';

const AddressInfoEditForm = ({ formData, onSubmit, onClose }) => {
    const dispatch = useDispatch();
    const [longAddress, setLongAddress] = useState(formData?.LongAddress || '');
    const [number, setNumber] = useState(formData?.Number || '');
    const [complement, setComplement] = useState(formData?.Complement || '');
    const [neighborhood, setNeighborhood] = useState(formData?.Neighborhood || '');
    const [reference, setReference] = useState(formData?.Reference || '');
    const [cityCode, setCityCode] = useState(formData?.CityCode || '');
    const [zipcode, setZipcode] = useState(formData?.Zipcode || '');
    const [stateSelected, setStateSelected] = useState(formData?.City?.StateCode || '');
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [cepFound, setCepFound] = useState(true);

    useEffect(() => {
        async function getStates(){
            try {
                dispatch(setLoading(true));
                const response = await cityApiService.getAllStates();
                
                dispatch(setLoading(false));
        
                setStates(response.data);
            } catch (error) {
                toast.error('Erro ao carregar estados.');
            }
        }
        getStates();
    }, [dispatch])

    useEffect(() => {
        async function getStates(){
            try {
                dispatch(setLoading(true));
                const response = await cityApiService.getCityByState(stateSelected);
                
                dispatch(setLoading(false));
        
                if (response.data) {
                    setCities(response.data);
                }
            } catch (error) {
                toast.error('Erro ao carregar estados.');
            }
        }
        getStates();
    }, [stateSelected, dispatch])

    const handleStateChange = async (e) =>{
        setStateSelected(e.target.value);
    
        if(!cepFound){
          dispatch(setLoading(true));
          const response = await cityApiService.getCityByState(e.target.value);
          dispatch(setLoading(false));
    
          if (response.data) {
            setCities(response.data);
          }
        }
    }

    const handleCepBlur = async () => {
        try {
          dispatch(setLoading(true));
          const response = await cityApiService.getCityByCep(zipcode);
          dispatch(setLoading(false));
    
          if (response.data && response.data.State) {
            const { LongAddress, Complement, Neighborhood, City, CityCode, State } = response.data;
            
            setLongAddress(LongAddress);
            setComplement(Complement);
            setNeighborhood(Neighborhood);
            setCities([{ Code: CityCode, Name: City }]);
            setStates([ State]);
            setCityCode(CityCode);
            setStateSelected(State);
            setCepFound(true);
            
          } else {
            toast.error('CEP não encontrado.');
            setCepFound(false);
          }
        } catch (error) {
          setCepFound(false);
          dispatch(setLoading(false));
          toast.error('Erro ao buscar informações pelo CEP.');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const updatedAddress = { code: formData.Code, longAddress, number, complement, neighborhood, zipcode, cityCode, reference, personCode: formData.PersonCode };
        onSubmit(updatedAddress);
    };

    return (
        <form onSubmit={handleSubmit}>
            <h2>Informação do Endereço</h2>
            <div className='form-group-register'>
                <div className='form-group-inside'>
                    <label>Cep</label>
                    <input type="text" value={maskCEP(zipcode)} onChange={(e) => setZipcode(e.target.value)} onBlur={handleCepBlur} required/>
                </div>
                <div className='form-group-inside'>
                    <label>Estado</label>
                    <select name="stateCode" onChange={handleStateChange} disabled={cepFound} value={stateSelected}>
                        <option value="">Selecione um estado</option>
                        {states.map(state => <option key={state} value={state}>{state}</option>)}
                    </select>
                </div>
                <div className='form-group-inside'>
                    <label>Cidade</label>
                    <select name="cityCode" onChange={(e) => setCityCode(e.target.value)} disabled={cepFound} value={cityCode}>
                        <option value="">Selecione uma cidade</option>
                        {cities.map(city => <option key={city.Code} value={city.Code}>{city.Name}</option>)}
                    </select>
                </div>
            </div>

            <div className="form-group-register">
                <div className='form-group-inside'>
                <label>Logradouro</label>
                <input type="text" name="longAddress" value={longAddress} onChange={(e) => setLongAddress(e.target.value)} required />
                </div>
            </div>

            <div className="form-group-register">
                <div className='form-group-inside'>
                <label>Número</label>
                <input type="text" name="number" value={number} onChange={(e) => setNumber(e.target.value)} required />  
                </div>
                <div className='form-group-inside'>
                <label>Bairro</label>
                <input type="text" name="neighborhood" value={neighborhood} onChange={(e) => setNeighborhood(e.target.value)} required />  
                </div>
                <div className='form-group-inside'>
                <label>Complemento</label>
                <input type="text" name="complement" value={complement} onChange={(e) => setComplement(e.target.value)} />  
                </div>
            </div>

            <div className="form-group-register">
                <div className='form-group-inside'>
                <label>Ponto de Referência</label>
                <input type="text" name="additionalInformation" value={reference} onChange={(e) => setReference(e.target.value)} />
                </div>
            </div>
            
            <div className='space-component'>
                <button className='main-button' onClick={onClose}>Cancelar</button>
                <button className='main-button' type="submit">Salvar</button>
            </div>
        </form>
    );
};

export default AddressInfoEditForm;
