import React from 'react';
import './UpdateInfoModal.css';
import AddressInfoEditForm from './components/AddressInfoEditForm';
import PersonalInfoEditForm from './components/PersonalInfoEditForm';

const UpdateInfoModal = ({ isOpen, onClose, infoType, formData, onSubmit }) => {
  if (!isOpen) return null;

  const renderForm = () => {
    switch (infoType) {
      case 'personal':
        return <PersonalInfoEditForm formData={formData} onSubmit={onSubmit} onClose={onClose}/>;
      case 'address':
        return <AddressInfoEditForm formData={formData} onSubmit={onSubmit} onClose={onClose}/>;
      default:
        return null;
    }
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-container-edit">
        {renderForm()}
      </div>
    </div>
  );
};

export default UpdateInfoModal;
