import React from 'react';

const ShoppingBagIcon = ({ size = 24, color = 'white' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 10L10 3.33333H30L35 10M5 10V33.3333C5 34.2174 5.35119 35.0652 5.97631 35.6904C6.60143 36.3155 7.44928 36.6667 8.33333 36.6667H31.6667C32.5507 36.6667 33.3986 36.3155 34.0237 35.6904C34.6488 35.0652 35 34.2174 35 33.3333V10M5 10H35M26.6667 16.6667C26.6667 18.4348 25.9643 20.1305 24.714 21.3807C23.4638 22.631 21.7681 23.3333 20 23.3333C18.2319 23.3333 16.5362 22.631 15.286 21.3807C14.0357 20.1305 13.3333 18.4348 13.3333 16.6667"
      stroke={color}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ShoppingBagIcon;
