import React from 'react';

const BrandIcon = ({ size = 24, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 6H12.618L10.9 2.553A1 1 0 0 0 10 2H3A1 1 0 0 0 2 3V21a1 1 0 0 0 1 1H21a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1ZM20 20H4V4H9.382l1.723 3.447A1 1 0 0 0 12 8h8ZM12 10a1 1 0 0 1 1 1v2h2a1 1 0 0 1 0 2H13v2a1 1 0 0 1-2 0V15H9a1 1 0 0 1 0-2h2V11a1 1 0 0 1 1-1Z"
      fill={color}
    />
  </svg>
);

export default BrandIcon;
