import React from 'react';

const OptionsIcon = ({ color = '#000000', size = '24px' }) => {
  return (
    <svg 
      fill={color} 
      width={size} 
      height={size} 
      viewBox="0 0 32 32" 
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* First bar (longest) */}
      <rect x="6" y="8" width="20" height="4" />
      {/* Second bar (medium length) */}
      <rect x="6" y="16" width="16" height="4" />
      {/* Third bar (shortest) */}
      <rect x="6" y="24" width="12" height="4" />
    </svg>
  );
};

export default OptionsIcon;
