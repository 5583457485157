import React from 'react';
import './HrefHistory.css';
import { useLocation, Link } from 'react-router-dom';
import HomeIcon from '../../icons/HomeIcon/HomeIcon';
import ArrowIcon from '../../icons/ArrowIcon/ArrowIcon';

const HrefHistory = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);

  return (
    <div className="href-history href-history-client">
    {pathSegments.length > 0 && pathSegments.map((segment, index) => {
        const path = `/${pathSegments.slice(0, index + 1).join('/')}`;
        const isLast = index === pathSegments.length - 1;
        return (
        <span key={index}>
            {
            index === 0 &&
            <>
                <Link className="padding-top-4-px link-history" to={'/'}>
                <HomeIcon color="#009AD6"/>
                </Link>
                <ArrowIcon color="#009AD6" />
            </>
            }
            {!isLast ? (
            <>
                <Link className="text-align-center link-history padding-bottom-4-px" to={path}>{segment}</Link>
                <ArrowIcon color="#009AD6" />
            </>
            ) : (
            <span className="padding-bottom-4-px"></span>
            )}
        </span>
        );
    })}
    </div>
  );
};

export default HrefHistory;
