import api from './serviceApi'; // Import the axios instance

const brandApi = {
  /**
   * Fetch a brand by code.
   * @param {string} include - Optional parameter to include additional data.
   * @returns {Promise<Object>} - The brand data.
   */
  getBrands: async (include = '') => {
    try {
      const response = await api.get(`/brand`, {
        params: { include }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching brand by code:', error);
      throw error;
    }
  },
  /**
   * Fetch a brand by code.
   * @param {string} code - The code of the brand.
   * @param {string} include - Optional parameter to include additional data.
   * @returns {Promise<Object>} - The brand data.
   */
  getBrandByCode: async (code, include = '') => {
    try {
      const response = await api.get(`/brand/${code}`, {
        params: { include }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching brand by code:', error);
      throw error;
    }
  },

  /**
   * Fetch a list of brands with optional filters and pagination.
   * @param {Object} params - Query parameters such as quantity, term, orderBy, etc.
   * @returns {Promise<Object>} - The paginated list of brands.
   */
  getBrandsPaginated: async (params) => {
    try {
      const response = await api.get('/brand/pagged', { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching brands:', error);
      throw error;
    }
  },

  /**
   * Export a list of brands with filters.
   * @param {Object} params - Query parameters such as quantityMax, isActive, term, etc.
   * @returns {Promise<Blob>} - The exported file data.
   */
  exportBrands: async (params) => {
    try {
      const response = await api.get('/brand/export', { params, responseType: 'blob' });
      return response.data;
    } catch (error) {
      console.error('Error exporting brands:', error);
      throw error;
    }
  },

  /**
   * Create a new brand.
   * @param {Object} brandData - The brand data to create.
   * @returns {Promise<Object>} - The created brand data.
   */
  createBrand: async (brandData) => {
    try {
      const response = await api.post('/brand', brandData);
      return response.data;
    } catch (error) {
      console.error('Error creating brand:', error);
      throw error;
    }
  },

  /**
   * Update an existing brand.
   * @param {Object} brandData - The brand data to update.
   * @returns {Promise<Object>} - The updated brand data.
   */
  updateBrand: async (code, brandData) => {
    try {
      const response = await api.put('/brand/' + code, brandData);
      return response.data;
    } catch (error) {
      console.error('Error updating brand:', error);
      throw error;
    }
  },

/**
   * brand a password.
   * @param {Object} brandData - The brand data to update.
   * @returns {Promise<Object>} - The updated brand data.
   */
updatePasswordPerson: async (brandData) => {
  try {
    const response = await api.put('/brand/update-password', brandData);
    return response.data;
  } catch (error) {
    console.error('Error updating password for person:', error);
    throw error;
  }
},

/**
   * inativate a brand by their code.
   * @param {string} brandCode - The brand code to inativate.
   * @returns {Promise<Object>} - The result of the deletion.
   */
setInactive: async (brandCode) => {
  try {
    const response = await api.put('/brand/setInactive/' + brandCode);
    return response.data;
  } catch (error) {
    console.error('Error inativating brand:', error);
    throw error;
  }
},

/**
 * active a brand by their code.
 * @param {string} brandCode - The brand code to active.
 * @returns {Promise<Object>} - The result of the deletion.
 */
setActive: async (brandCode) => {
  try {
    const response = await api.put('/brand/setActive/' + brandCode);
    return response.data;
  } catch (error) {
    console.error('Error inativating brand:', error);
    throw error;
  }
},

  /**
   * Delete a brand.
   * @param {string} code - The code of the brand to delete.
   * @returns {Promise<Object>} - The result of the deletion.
   */
  deleteBrand: async (code) => {
    try {
      const response = await api.delete('/brand', {
        data: { code }
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting brand:', error);
      throw error;
    }
  }
};

export default brandApi;
