import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import modelApi from '../../../services/apiService/modelApi';
import productApi from '../../../services/apiService/productApi';
import { setLoading } from '../../../services/redux/loadingSlice';
import { useDispatch, useSelector } from 'react-redux';
import './ModelClientItemPage.css';
import { toast } from 'react-toastify';
import SectionWithImage from '../../../components/client/SectionWithImage/SectionWithImage';
import { addItem, removeItem, selectAllCartItems } from '../../../services/redux/shoppingCartSlice';
import HrefHistory from '../../../components/client/HrefHistory/HrefHistory';

const ModelClientItemPage = () => {
    const { guid } = useParams();
    const dispatch = useDispatch();
    const [model, setModel] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [refresh, setRefresh] = useState(false);

    const cartItems = useSelector(selectAllCartItems) || [];
    const isItemInCart = (itemCode) => {
        if(cartItems !== undefined)
            return cartItems.find(cartItem => cartItem.Code === itemCode);
        return false;
    }

    useEffect(() => {
        const fetchModel = async () => {
            dispatch(setLoading(true));
            try {
                const response = await modelApi.getModelByCode(guid, 'ModelImage,Product,Product.Category,Product.Group,Product.Brand');
                setModel(response);
                if(response.Product?.length > 0 && !selectedProduct)
                    setSelectedProduct(response.Product[0]);
            } catch (error) {
                toast.error('Erro ao buscar o modelo.');
            } finally {
                dispatch(setLoading(false));
            }
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
          };
        window.addEventListener('resize', handleResize);

        fetchModel();
    }, [guid, refresh, selectedProduct, dispatch]);

    const handleAddToCart = async (item) => {

        dispatch(setLoading(true));
        try {
            const response = await productApi.getProductByCode(item.Code, 'Company,Group,Brand,Model,Model.ModelImage,ProductImage,Category');
            dispatch(addItem(response));
        } catch (error) {
            toast.error('Erro ao buscar o produto.');
        } finally {
            dispatch(setLoading(false));
        }
        setRefresh((prev) => !prev);
    };

    const handleRemoveToCart = (item) => {
        dispatch(removeItem(item));
        setRefresh((prev) => !prev);
    };

    if (!model) return <p>Loading</p>;

    return (
        <div className='model-description'>
            <SectionWithImage
                backgroundImageUrl={'https://storagealbus.blob.core.windows.net/padrao/normas-oficina-mecanica%201.png'}
                title={'Produto'}
            />
            <HrefHistory/>
            <div className="model-description-container">
                <div className="model-details-image">
                    <img 
                        src={model.ModelImage && model.ModelImage.length > 0 ? model.ModelImage[0].Url : 'https://storagealbus.blob.core.windows.net/padrao/nocontent.png'} 
                        alt={model.Name} 
                    />
                    {
                        !isMobile &&
                        <div className="model-measures-container">
                            <div className='model-measures-container-title'>
                                <h1>Medidas</h1>
                            </div>
                            {model.Product.map((product) => (
                                <div key={product.Code} className="measure-item">
                                    <input 
                                        type="checkbox" 
                                        id={product.Code} 
                                        checked={selectedProduct?.Code === product.Code}
                                        onChange={() => setSelectedProduct(product)}
                                    />
                                    <h3>{product.Description}</h3>
                                </div>
                            ))}
                            {
                                isItemInCart(selectedProduct?.Code) ?
                                <div className='model-details-button right-component'>
                                    <button className="measure-add-button-remove clickable item-inactive brighten-on-hover" onClick={() => handleRemoveToCart(selectedProduct.Code)}>- Remover</button>
                                </div>
                                :
                                <div className='model-details-button right-component'>
                                    <button className="clickable measure-add-button brighten-on-hover" onClick={() => handleAddToCart(selectedProduct)}>+ Adicionar</button>
                                </div>
                            }
                        </div>
                    }
                </div>
                <div className="model-details">
                    <div className="model-details">
                        <h1>{model.Name}</h1>
                        <h2>Descrição</h2>
                        <p>{model.Description}</p>
                    </div>
                    <div className="model-details">
                    <h2>Detalhes</h2>
                    {
                        selectedProduct?.Observation ? 
                        <table className='client-table'>
                            <tbody>
                                {
                                    selectedProduct?.Observation?.split('\n').map((line, index) => (
                                    <tr key={index}>
                                        <td>{line.split(':')[0]}</td>
                                        <td>{line.split(':')[1]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        :
                        <h3>Sem detalhes</h3>
                    }
                    </div>
                </div>
            </div>
            {
                isMobile &&
                <div className="model-measures-container">
                    <div className='model-measures-container-title'>
                        <h1>Medidas</h1>
                    </div>
                    {model.Product.map((product) => (
                        <div key={product.Code} className="measure-item">
                            <input 
                                type="checkbox" 
                                id={product.Code} 
                                checked={selectedProduct?.Code === product.Code}
                                onChange={() => setSelectedProduct(product)}
                            />
                            <h3>{product.Description}</h3>
                        </div>
                    ))}
                    {
                        isItemInCart(selectedProduct?.Code) ?
                        <div className='model-details-button right-component'>
                            <button className="clickable measure-add-button-remove item-inactive brighten-on-hover" onClick={() => handleRemoveToCart(selectedProduct.Code)}>- Remover</button>
                        </div>
                        :
                        <div className='model-details-button right-component'>
                            <button className="clickable measure-add-button item-active brighten-on-hover" onClick={() => handleAddToCart(selectedProduct)}>+ Adicionar</button>
                        </div>
                    }
                    
                </div>
            }
        </div>
    );
};

export default ModelClientItemPage;
