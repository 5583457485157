import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

const DashboardBarChart = ({ title, data, yAxisLabel }) => {
  const chartSettings = {
    yAxis: [
      {
        label: yAxisLabel,
      },
    ],
    series: [
      {
        dataKey: 'value',
        color: 'var(--primary-color)', // Apply the primary color to all bars
      },
    ],
    height: 300,
    sx: {
      [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
        transform: 'translateX(-10px)',
      },
    },
  };

  return (
    <div style={{ width: '100%' }}>
      <h2>{title}</h2>
      <BarChart
        dataset={data}
        xAxis={[{ scaleType: 'band', dataKey: 'label' }]}
        {...chartSettings}
      />
    </div>
  );
};

export default DashboardBarChart;
