import React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import './DashboardPieChart.css'; // Create this CSS file to style the component

const DashboardPieChart = ({ title, total, data, legendItems }) => {
  return (
    <div className="dashboard-pie-chart">
      <h2>{title} ({total})</h2>
      <div className='chart-container'>
        <PieChart
          series={[
            {
              data: data,
            },
          ]}
          width={300}
          height={300}
        />
        {legendItems.map((item, index) => (
            <div className='div-flex-row' key={index}>
                <button className={item.className}></button>
                <p>
                    {item.label}: {item.value}
                </p>
            </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardPieChart;
