import './RequestRecoveryPasswordModal.css';
import React, {useState} from "react";
import personApi from "../../../services/apiService/personApi";
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../services/redux/loadingSlice';
import { toast } from "react-toastify";

const RequestRecoveryPasswordModal = ({isOpen, onClose}) => {
    const[mail, setMail] = useState('');
    
    const dispatch = useDispatch();
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        dispatch(setLoading(true));
        try{
            const response = await personApi.requestRecoveryPassword({
                Email: mail
            });
            
            if(response.code === 401){
                toast.error(response.message);
            }
            else{
                toast.success('Foi enviado um email de recuperação de senha, siga as instruções contidas no email');
            }
        }
        catch(err){
            toast.error('Erro ao solicitar recuperação de senha, entre em contato com o suporte!');
        }
        finally{
            dispatch(setLoading(false));
            onClose();
        }
    }

    if (!isOpen) return null;

    return(
        <div className="modal-backdrop">
            <button className="close-button" onClick={onClose}>
                &times;
            </button>
            <div className="modal-container-password">
                <h2>
                    Recuperação de senha
                </h2>
                <sub>
                    **Será enviado um email com o passo a passo para atualização da senha para o seu acesso, basta fornecer seu email de usuário.**
                </sub>
                <form className="recovery-password-form" onSubmit={handleSubmit}>
                    <label>Email:</label>
                    <input className='main-input' name='email' type="email" value={mail} onChange={(e) => setMail(e.target.value)} required/>
                    <button className='main-button' type="submit">Solicitar Recuperação de Senha</button>
                </form>
            </div>
        </div>
    )
}

export default RequestRecoveryPasswordModal;