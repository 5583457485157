import React from 'react';
import { Route, Routes } from 'react-router-dom';
import configService from '../services/configService';
import AdminLayout from '../layouts/admin/AdminLayout';
import DashboardPage from '../pages/admin/DashboardPage/DashboardPage';
import PersonListPage from '../pages/admin/PersonListPage/PersonListPage';
import PersonPage from '../pages/admin/PersonPage/PersonPage';
import ConfigurationPage from '../pages/admin/ConfigurationPage/ConfigurationPage';
import EditPassword from '../pages/common/EditPassword/EditPassword';
import LoggerListPage from '../pages/admin/LoggerListPage/LoggerListPage';
import Profile from '../pages/common/Profile/Profile';
import MailListPage from '../pages/admin/MailListPage/MailListPage';
import MailPage from '../pages/admin/MailPage/MailPage';
import ModelListPage from '../pages/admin/ModelListPage/ModelListPage';
import ModelPage from '../pages/admin/ModelPage/ModelPage';
import ProductListPage from '../pages/admin/ProductListPage/ProductListPage';
import ProductPage from '../pages/admin/ProductPage/ProductPage';
import ShoppingCartListPage from '../pages/admin/ShoppingCartListPage/ShoppingCartListPage';
import ShoppingCartDetailPage from '../pages/admin/ShoppingCartDetailPage/ShoppingCartDetailPage';
import ShoppingCartCreatePage from '../pages/admin/ShoppingCartCreatePage/ShoppingCartCreatePage';
import ShoopingCartLastStage from '../pages/admin/ShoopingCartLastStage/ShoopingCartLastStage';
import BrandListPage from '../pages/admin/BrandListPage/BrandListPage';
import CompanyListPage from '../pages/admin/CompanyListPage/CompanyListPage';
import GroupListPage from '../pages/admin/GroupListPage/GroupListPage';
import CategoryListPage from '../pages/admin/CategoryListPage/CategoryListPage';

const AdminRoutes = () => (
  <AdminLayout>
    <Routes>
      <Route path="/" element={<DashboardPage />} />
      <Route path="/admin" element={<DashboardPage />} />
      <Route path="/admin/dashboard" element={<DashboardPage />} />
      <Route path="/admin/configuracoes" element={<ConfigurationPage />} />
      <Route path="/admin/configuracoes/logs" element={<LoggerListPage />} />
      <Route path="/admin/configuracoes/emails" element={<MailListPage />} />
      <Route path="/admin/configuracoes/emails/:code" element={<MailPage />} />
      <Route path="/admin/configuracoes/editar-senha" element={<EditPassword />} />
      <Route path="/admin/clientes" element={<PersonListPage personType={configService.getClientPersonTypeCode()} personTypeName="Cliente" title="Clientes"/>} />
      <Route path="/admin/clientes/:code" element={<PersonPage title="Página do Cliente"/>} />
      <Route path="/admin/vendedores" element={<PersonListPage personType={configService.getSellerPersonTypeCode()} personTypeName="Vendedor" title="Vendedores"/>} />
      <Route path="/admin/vendedores/:code" element={<PersonPage title="Página do Vendedor"/>} />
      <Route path="/admin/administradores" element={<PersonListPage personType={configService.getAdminPersonTypeCode()} personTypeName="Administrador" title="Administradores"/>} />
      <Route path="/admin/administradores/:code" element={<PersonPage title="Página do Administrador"/>} />
      <Route path='/admin/perfil' element={<Profile />}/>
      <Route path='/admin/modelos' element={<ModelListPage />}/>
      <Route path='/admin/modelos/:code' element={<ModelPage />}/>
      <Route path='/admin/marcas' element={<BrandListPage />}/>
      <Route path='/admin/empresas' element={<CompanyListPage />}/>
      <Route path='/admin/grupos' element={<GroupListPage />}/>
      <Route path='/admin/categorias' element={<CategoryListPage />}/>
      <Route path='/admin/produtos' element={<ProductListPage />}/>
      <Route path='/admin/produtos/:code' element={<ProductPage />}/>
      <Route path='/admin/orcamentos' element={<ShoppingCartListPage />}/>
      <Route path='/admin/orcamentos/:code' element={<ShoppingCartDetailPage />}/>
      <Route path='/admin/orcamentos/novo' element={<ShoppingCartCreatePage />}/>
      <Route path='/admin/orcamentos/novo/fechamento' element={<ShoopingCartLastStage />}/>
      <Route path="/*" element={<DashboardPage />} />
      </Routes>
  </AdminLayout>
);

export default AdminRoutes;
