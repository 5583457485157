import React, { useState, useEffect } from 'react';

const DescriptionRegisterModal = ({ isOpen, onClose, onSubmit, showId = false, oldValue = '', oldId = '' }) => {
    const [item, setItem] = useState({
        Value: '',
        Id: '',
    });

    useEffect(() => {
        setItem((prevState) => ({
            ...prevState,
            Value: oldValue,
        }));
    }, [oldValue]);

    useEffect(() => {
        setItem((prevState) => ({
            ...prevState,
            Id: oldId,
        }));
    }, [oldId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setItem((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(item);
        clear();
        onClose();
    };

    const clear = () => {
        setItem({
            Value: '',
            Id: '',
        });
    };

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop-register">
            <div className="modal-container-register">
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                <form onSubmit={handleSubmit} className="register-form">
                    <div className="form-group-register form-group-inside">
                        <label>Nome:</label>
                        <input
                            type="text"
                            name="Value"
                            value={item.Value}
                            onChange={handleInputChange}
                            required
                        />
                    </div>

                    {showId && (
                        <div className="form-group-register form-group-inside">
                            <label>Código Interno:</label>
                            <input
                                type="text"
                                name="Id"
                                value={item.Id}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    )}

                    <div className="form-group-register option-next-register">
                        <button className="main-button" type="button" onClick={onClose}>
                            Cancelar
                        </button>
                        <button className="main-button" type="submit">
                            Salvar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default DescriptionRegisterModal;
