import React, {useState, useEffect} from 'react';
import './Footer.css';
import logo from '../../../assets/images/logo_white_text_transparent.png';
import albusLogo from '../../../assets/images/logo_branca_menor.png';

const Footer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <footer className="footer">
      <div className="footer__content">
        <div className="footer__links">
          <p className="clickable" onClick={() => (window.location.href = '/modelos?type=caminhao')}>Caminhão/Ônibus</p>
          <p className="clickable" onClick={() => (window.location.href = '/modelos?type=passeio')}>Passeio</p>
          <p className="clickable" onClick={() => (window.location.href = '/modelos?type=caminhonete')}>Caminhonetes/SUV</p>
          <p className="clickable" onClick={() => (window.location.href = '/modelos?type=van')}>Vans</p>
          <p className="clickable" onClick={() => (window.location.href = '/modelos?type=maquinas')}>Máquinas</p>
        </div>
        {!isMobile && <div className='column-2px'></div>}
        <div className="footer__logo">
            <div className="footer__logo">
              <img src={logo} alt="Logo"/>
            </div>
        </div>
        {!isMobile && <div className='column-2px'></div>}
        <div className="footer__social">
          <p>Plataforma</p>
          <div className="footer__icon"> 
            <p>Albus Tecnologia</p>
            <img src={albusLogo} alt='Logo Albus' />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
