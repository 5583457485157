import React, { useState, useEffect } from 'react';
import './ModelClientListPage.css';
import modelApi from '../../../services/apiService/modelApi';
import brandApi from '../../../services/apiService/brandApi';
import { setLoading } from '../../../services/redux/loadingSlice';
import { useDispatch } from 'react-redux';
import Pagination from '../../../components/common/Pagination/Pagination'; 
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import SearchIcon from '../../../components/icons/SearchIcon/SearchIcon';
import SectionWithImage from '../../../components/client/SectionWithImage/SectionWithImage';
import HrefHistory from '../../../components/client/HrefHistory/HrefHistory';
import groupApi from '../../../services/apiService/groupApi';

const ModelClientListPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const [brands, setBrands] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const quantity = 12; 
    const orderBy = "Created:Desc";
    const location = useLocation();

    useEffect(() => {
        const fetchModels = async () => {
            dispatch(setLoading(true));
            try {
                const brandParam = selectedBrands.join(';');
                const typesParam = selectedTypes.join(';');
                const response = await modelApi.getModelsPaginated({ 
                    page, 
                    quantity, 
                    orderBy, 
                    term: searchTerm, 
                    isActive: 1,
                    include: 'ModelImage',
                    brand: brandParam,
                    group: typesParam
                });
                setItems(response.Results);
                setTotalPages(response.TotalPages);
            } catch (error) {
                toast.error('Erro ao buscar os itens.');
            } finally {
                dispatch(setLoading(false));
            }
        };

        const fetchBrands = async () => {
            try {
                const response = await brandApi.getBrands();
                setBrands(response);
            } catch (error) {
                toast.error('Erro ao buscar as marcas.');
            }
        };

        const fetchGroups = async () => {
            try {
                const response = await groupApi.getAllTypeGroups();
                setGroups(response);
            } catch (error) {
                toast.error('Erro ao buscar os grupos.');
            }
        };

        fetchGroups();
        fetchBrands();
        fetchModels();


    }, [page, quantity, searchTerm, selectedBrands, selectedTypes, dispatch]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const typeParam = params.get('type');

        if (typeParam) {
            const upperCaseType = typeParam.replace('%20', ' ');
            setSelectedTypes([upperCaseType]);
        }

        const brandParam = params.get('brand');
        if (brandParam) {
            const upperCaseType = brandParam;
            setSelectedBrands([upperCaseType]);
        }
    }, [location]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    const handleBrandClick = (brandCode) => {
        setSelectedBrands((prevSelected) =>
            prevSelected.includes(brandCode)
                ? prevSelected.filter((code) => code !== brandCode) 
                : [...prevSelected, brandCode]
        );
    };

    const handleTypeClick = (type) => {
        setSelectedTypes((prevSelected) =>
            prevSelected.includes(type)
                ? prevSelected.filter((code) => code !== type) 
                : [...prevSelected, type]
        );
    };

    const openModel = (code) => {
        navigate(`${code}`);
    };

    return (
        <div>
            <SectionWithImage backgroundImageUrl={'https://storagealbus.blob.core.windows.net/padrao/normas-oficina-mecanica%201.png'} title={'Produtos'} />
            <HrefHistory/>
            <section className="model-list-section">
                <div className="model-filters">
                    <div className="filter-section-title">
                        <h2>Filtros</h2>
                    </div>
                    <div className='model-filters-input'>
                        <input
                            type="text"
                            placeholder="Pesquisar"
                            className="main-input"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <div className='form-group-input-icon'>
                            <SearchIcon size={20} color="black" />
                        </div>
                    </div>
                    <div className="filter-section">
                        <h3>Marcas</h3>
                        <div className="filter-options">
                            {brands && brands.length > 0 && (
                                brands.map((brand) => (
                                    <div key={brand.Code} className="filter-option">
                                        <input 
                                            type="checkbox" 
                                            id={brand.Code} 
                                            checked={selectedBrands.includes(brand.Code)}
                                            onChange={() => handleBrandClick(brand.Code)}
                                        />
                                        <label htmlFor={brand.Code}>{brand.Description}</label>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                    <div className="filter-section">
                        <h3>Veículos</h3>
                        <div className="filter-options">
                            {groups && groups.length > 0 && (
                                groups.map((group) => (
                                    <div key={group} className="filter-option">
                                        <input 
                                            type="checkbox" 
                                            id={group} 
                                            checked={selectedTypes.includes(group)}
                                            onChange={() => handleTypeClick(group)}
                                        />
                                        <label htmlFor={group}>{group}</label>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
                <div className="model-items">
                    <div className="model-items-grid">
                        {items.map((item) => (
                            <div key={item.Code} className="model-item-card" onClick={() => openModel(item.Code)}>
                                <img 
                                    src={item.ModelImage && item.ModelImage.length > 0 ? item.ModelImage[0].Url : 'https://storagealbus.blob.core.windows.net/padrao/nocontent.png'} 
                                    alt={item.Name} 
                                    className="model-item-image" 
                                />
                                <div className="model-item-details">
                                    <h5>{item.Name}</h5>
                                    <p>
                                        {item.Description.length > 200 
                                            ? `${item.Description.substring(0, 200)}...` 
                                            : item.Description}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <Pagination page={page} totalPages={totalPages} onPageChange={handlePageChange} />
                </div>
            </section>
        </div>
    );
};

export default ModelClientListPage;
