import './RecoveryPassword.css'; 
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import personApi from '../../../services/apiService/personApi';
import EyeOffIcon from '../../../components/icons/EyeOffIcon/EyeOffIcon';
import EyeIcon from '../../../components/icons/EyeIcon/EyeIcon';

const RecoveryPassword = () => {
    const { guid } = useParams(); 
    const navigate = useNavigate();

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const togglePasswordConfirmVisibility = () => {
        setShowPasswordConfirm(!showPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            toast.error('Senhas estão diferentes');
            return;
        }

        setLoading(true);

        try {
            const response = await personApi.recoveryPassword({
                guid,
                newPassword,
                confirmPassword
            });

            if(response.code === 401 || response.code === 402 || response.code === 403){
                toast.error(response.message);
            }
            else{
                toast.success('Senha alterada com sucesso!');
                toast.success('Login Liberado!');
                navigate('/'); 
            }
        } catch (error) {
            toast.error('Erro ao atualizar a senha!');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="recovery-password-container">
            <h2>Recuperar Senha</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Nova senha</label>
                    <div className='form-group-input'>
                        <input
                            type={showPassword ? "text" : "password"}
                            className="main-input"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                        <div className='form-group-input-icon margin-top-default option-link' onClick={togglePasswordVisibility}>
                        {
                            showPassword ?
                            <EyeOffIcon size={24} color='black'/>
                            :
                            <EyeIcon size={24} color='black'/>
                        }
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label>Confirma nova senha</label>
                    <div className='form-group-input'>
                        <input
                            type={showPasswordConfirm ? "text" : "password"}
                            className="main-input"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                        <div className='form-group-input-icon margin-top-default option-link' onClick={togglePasswordConfirmVisibility}>
                        {
                            showPasswordConfirm ?
                            <EyeOffIcon size={24} color='black'/>
                            :
                            <EyeIcon size={24} color='black'/>
                        }
                        </div>
                    </div>
                </div>
                <button type="submit" className="main-button" disabled={loading}>
                    {loading ? 'Atualizando...' : 'Atualizar'}
                </button>
          </form>
        </div>
    );
};

export default RecoveryPassword;
