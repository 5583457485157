import React from 'react';

const OpenIcon = ({ size = 24, color = '#000000' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill={color} transform="translate(85.333333, 64.000000)">
      <path d="M128,63.999444 L128,106.666444 L42.6666667,106.666667 L42.6666667,320 L256,320 L256,234.666444 L298.666,234.666444 L298.666667,362.666667 L0,362.666667 L0,64 L128,63.999444 Z M362.666667,0 L362.666667,170.666667 L320,170.666667 L320,72.835 L143.084945,249.751611 L112.915055,219.581722 L289.83,42.666 L192,42.6666667 L192,0 L362.666667,0 Z" />
    </g>
  </svg>
);

export default OpenIcon;
