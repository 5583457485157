import React, { useState, useEffect } from 'react';
import './HomePage.css';
import homeApi from '../../../services/apiService/homeApi';

const HomePage = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await homeApi.getHomeData();
        setData(response);
      } catch (error) {
        console.error('Error fetching home data:', error);
      }
    };
    fetchData();
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div className="home-client-page">
        {/* Section 1 */}
        <section
            className="home-section section-1"
            style={{
                backgroundImage: `url(${data.Section1.BackgroundImage})`,
            }}
            >
            <h1 className="section-1-title">{data.Section1.Title}</h1>
        </section>

        {/* Section 2 */}
        <section className="home-section section-2">
            <h2 className="section-2-title"><div className='column-4px'></div>{data.Section2.Title}</h2>
            <div className="items-container-section-2">
              {data.Section2.Items.map((item, index) => (
                <div key={index} className="item-section-2 clickable" onClick={() => (window.location.href = item.Href)}>
                  <img src={item.Img} alt={item.Description} className="item-section-2-img" />
                  <p className="item-description">{item.Description}</p>
                </div>
              ))}
            </div>
        </section>

        {/* Section 3 */}
        <section className="home-section section-3">
            <h2 className="section-3-title">{data.Section3.Title}</h2>
            <div className="items-container-section-3">
              {data.Section3.Items.map((item, index) => (
                <div key={index} className="item-section-3 clickable" onClick={() => (window.location.href = item.Href)}>
                  <img src={item.Img} alt={item.Description} className="item-section-3-img" />
                  <p className="item-section-3-description">{item.Description}</p>
                </div>
              ))}
            </div>
        </section>

        {/* Section 4 */}
        <section className="home-section section-4">
            <h2 className="section-4-title">{data.Section4.Title}</h2>
            <div className="items-container-section-4">
                {data.Section4.Items.map((item, index) => (
                    <div key={index} className="item-section-4 clickable" onClick={() => (window.location.href = item.Href)}>
                        <img src={item.Img} alt={item.Description} className="item-section-4-img" />
                        <p className="item-section-4-description">{item.Description}</p>
                    </div>
                ))}
            </div>
            <div className='button-section-4'>
                <button className='main-button' onClick={() => (window.location.href = '/modelos?type=caminhao')}> + Ver Mais</button>
            </div>
        </section>

        {/* Section 5 */}
        <section className="home-section section-5" id="historia">
            <h2 className="section-5-title">{data.Section5.Title}</h2>
            <div className="items-container-section-5">
                {data.Section5.Items.map((item, index) => (
                    <div key={index} className="item-section-5">
                        <img src={item.Img} alt={item.Description} className="item-section-5-img" />
                        <p className="item-section-5-item-title">{item.Title}</p>
                        <p className="item-section-5-description">{item.Description}</p>
                    </div>
                ))}
            </div>
        </section>
    </div>
  );
};

export default HomePage;
