import './ModelRegisterModal.css';
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../services/redux/loadingSlice';
import modelApi from '../../../services/apiService/modelApi';
import { toast } from 'react-toastify';

const ModelRegisterModal = ({ isOpen, onClose, onSubmit, codeModel=null }) => {
    const dispatch = useDispatch();
    const [model, setModel] = useState({
        Description: '',
        Name: '',
        ModelImages: [], 
    });
    const fileInputRef = useRef(null);

    useEffect(() => {
        const fetchModel = async (code) => {
        try {
            dispatch(setLoading(true));
            const response = await modelApi.getModelByCode(code);
            setModel(response);
          } catch (error) {
            toast.error('Erro ao carregar o modelo!');
          } finally {
            dispatch(setLoading(false));
        }
        };
        if(codeModel){
            fetchModel(codeModel);
        }
    }, [codeModel, dispatch]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setModel((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        const newImages = [];

        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result;
                newImages.push({ base64: base64String });
                if (newImages.length === files.length) {
                    setModel((prevState) => ({
                        ...prevState,
                        ModelImages: [...prevState.ModelImages, ...newImages],
                    }));
                }
            };
            reader.readAsDataURL(file);
        });

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleImageDelete = (index) => {
        setModel((prevState) => ({
            ...prevState,
            ModelImages: prevState.ModelImages.filter((_, i) => i !== index),
        }));

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(model);
        clear();
        onClose(); 
    };

    const clear = () => {
        setModel({
            Description: '',
            Name: '',
            ModelImages: [], 
        })
    }

    const handleButtonClick = () => {
        fileInputRef.current.click(); 
    };

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop-register">
            <div className="modal-container-register">
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                <form onSubmit={handleSubmit} className='register-form'>                    
                    <div className='form-group-register form-group-inside'>
                        <label>
                            Nome do Modelo:
                        </label>
                        <input
                            type="text"
                            name="Name"
                            value={model.Name}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className='form-group-register form-group-inside'>
                        <label>
                            Descrição:
                        </label>
                        <textarea
                            name="Description"
                            value={model.Description}
                            onChange={handleInputChange}
                            required
                            rows="8"
                        />
                    </div>
                    {
                        !codeModel && 
                        <>
                            <div className='form-group-register form-group-inside'>
                                <div className='add-image-option'>
                                    <label>
                                        Imagens do Modelo:
                                    </label>
                                    <label className='main-button' onClick={handleButtonClick}>
                                        Adicionar
                                    </label>
                                </div>
                                
                                <input
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    onChange={handleFileChange}
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                />
                            </div>

                            <div className='form-group-register form-group-inside'>
                                <div className="image-preview-container">
                                    {model.ModelImages.map((image, index) => (
                                        <div key={index} className="image-preview">
                                            <img src={image.base64} alt={`Model ${index + 1}`} />
                                            <button type="button" onClick={() => handleImageDelete(index)}>
                                                Delete
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    }
                    

                    <div className="form-group-register option-next-register">
                        <button className='main-button' type="button" onClick={onClose}>Cancelar</button>
                        <button className='main-button' type="submit">Salvar</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ModelRegisterModal;