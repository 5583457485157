import api from './serviceApi'; // Import the axios instance

const mailMessageApi = {
  /**
   * Fetch a mail message by code.
   * @param {string} code - The code of the mail message.
   * @param {string} include - Optional parameter to include additional data.
   * @returns {Promise<Object>} - The mail message data.
   */
  getMailMessageByCode: async (code, include = '') => {
    try {
      const response = await api.get(`/mail-message/${code}`, {
        params: { include }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching mail message by code:', error);
      throw error;
    }
  },

  /**
   * Fetch a list of mail messages with optional filters and pagination.
   * @param {Object} params - Query parameters such as page, quantity, term, orderBy, etc.
   * @returns {Promise<Object>} - The paginated list of mail messages.
   */
  getMailMessagesPaginated: async (params) => {
    try {
      const response = await api.get('/mail-message/pagged', { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching mail messages:', error);
      throw error;
    }
  },

  /**
   * Export a list of mail messages with filters.
   * @param {Object} params - Query parameters such as quantityMax, isActive, term, etc.
   * @returns {Promise<Blob>} - The exported file data.
   */
  exportMailMessages: async (params) => {
    try {
      const response = await api.get('/mail-message/export', { params });
      return response.data;
    } catch (error) {
      console.error('Error exporting mail messages:', error);
      throw error;
    }
  },

  /**
   * Fetch all mail messages.
   * @param {Object} [params] - Optional query parameters.
   * @returns {Promise<Object>} - The list of mail messages.
   */
  getAllMailMessages: async (params = {}) => {
    try {
      const response = await api.get('/mail-message', { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching mail messages:', error);
      throw error;
    }
  },

  /**
   * Resend mail messages.
   * @param {Object} [code] - Optional query parameters.
   * @returns {Promise<Object>} - The list of mail messages.
   */
  resendEmail: async (code, include = '') => {
    try {
      const response = await api.post(`/mail-message/resend-mail?code=${code}`, {
        params: { include }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching mail messages:', error);
      throw error;
    }
  }
};

export default mailMessageApi;
