import React, {useState,useEffect} from 'react';
import './LoginModal.css';
import { toast } from 'react-toastify';
import UserNameIcon from '../../icons/UserNameIcon/UserNameIcon';
import authApi from '../../../services/apiService/authApi';
import { useDispatch } from 'react-redux';
import { login } from '../../../services/redux/authSlice';
import { setLoading } from '../../../services/redux/loadingSlice';
import EyeIcon from '../../icons/EyeIcon/EyeIcon';
import EyeOffIcon from '../../icons/EyeOffIcon/EyeOffIcon';

const LoginModal = ({ isOpen, onClose, onRegisterClick, onRecoveryPassClick }) => {
  const[username, setUsername] = useState('');
  const[password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const dispatch = useDispatch();

  const handleLogin = (token) => {
    dispatch(login(token));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));

    try {
        const response = await authApi.login({ username: username, password: password });
        dispatch(setLoading(false));
            
        if(response.code === 401 || response.code === 408 || response.code === 405){
            toast.error(response.message);
        }
        else{
            handleLogin(response);
            toast.success('Bem Vindo!');
            setPassword('');
            onClose();
        }
    } catch (err) {
        dispatch(setLoading(false));
        toast.error('Usuário ou senha incorretos');
    }
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-container">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <div className='modal-content'>
            <div className='modal-login-side'>
                <div className='modal-title'>
                    <span>Login</span>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="form-group-login">
                        <label htmlFor="email">Email / Username</label>
                        <div className='form-group-input'>
                            <input type="email" id="email" placeholder='email' value={username} onChange={(e) => setUsername(e.target.value)} required />
                            <div className='form-group-input-icon'>
                                <UserNameIcon size={24} color='black'/>
                            </div>
                        </div>
                    </div>
                    <div className="form-group-login">
                        <label htmlFor="password">Senha</label>
                        <div className='form-group-input'>
                            <input type={showPassword ? "text" : "password"} id="password" placeholder='senha' value={password} onChange={(e) => setPassword(e.target.value)} required />
                            <div className='form-group-input-icon option-link' onClick={togglePasswordVisibility}>
                            {
                                showPassword ?
                                <EyeOffIcon size={24} color='black'/>
                                :
                                <EyeIcon size={24} color='black'/>
                            }
                            </div>
                        </div>
                    </div>
                    <div className="form-group-login">
                        <div className='forgot-pass'>
                            <label onClick={onRecoveryPassClick}>Esqueceu sua senha? Clique aqui!</label>
                            {
                                isMobile &&
                                <label onClick={onRegisterClick}>Não possui cadastro? Clique aqui!</label>
                            }
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className='submit-form'>
                            <button type="submit" className="main-button">
                                Login
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {
                !isMobile &&
                <div className='modal-login-cadastro-side'>
                    <div className='modal-login-cadastro-side-message'>
                        <span>Ainda não tem cadastro?</span>
                    </div>
                    <div className='modal-login-cadastro-side-button'>
                        <button type="submit" className="cadastre-button" onClick={onRegisterClick}>
                            Cadastre-se
                        </button>
                    </div>
                </div>
            }
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
