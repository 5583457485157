import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import shoppingCartApi from '../../../services/apiService/shoppingCartApi';
import personApi from '../../../services/apiService/personApi';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../services/redux/loadingSlice';
import './ShoppingCartResum.css';
import ConfirmModal from '../../modals/ConfirmModal/ConfirmModal';
import { clearCart, selectAllCartItems, removeItem } from '../../../services/redux/shoppingCartSlice';

const ShoppingCartResum = ({ personCode }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isModalChangeStatusOpen, setIsModalChangeStatusOpen] = useState(false);
    const initialCartItems = useSelector(selectAllCartItems).map(item => ({ ...item, Quantity: item.Quantity || 1 }));
    const [cart, setCart] = useState({
        Person: null,
        ShoppingCartItem: initialCartItems,
    });

    const openModal = () => setIsModalChangeStatusOpen(true);
    const closeModal = () => setIsModalChangeStatusOpen(false);

    useEffect(() => {
        const fetchCartDetails = async () => {
            try {
                dispatch(setLoading(true));
                const response = await personApi.getPersonByCode(personCode);
                setCart(prev => ({
                    ...prev,
                    Person: response,
                }));
            } catch (error) {
                toast.error('Erro ao carregar os detalhes do orçamento.');
            } finally {
                dispatch(setLoading(false));
            }
        };

        fetchCartDetails();
    }, [personCode, dispatch]);

    const handleYesModal = async () => {
        dispatch(setLoading(true));
        try {
            await shoppingCartApi.createShoppingCart(createBodyToUpload());
            toast.success('Criado com sucesso!');
            dispatch(clearCart());
            navigate('/admin/orcamentos');
        } catch (error) {
            toast.error('Erro ao criar orçamento: ' + error);
        } finally {
            dispatch(setLoading(false));
            closeModal();
        }
    };

    const createBodyToUpload = () => ({
        status: 'OPEN',
        personCode: cart.Person.Code,
        shoppingCartItem: cart.ShoppingCartItem.map(item => ({
            productCode: item.Code,
            quantity: item.Quantity,
        })),
    });

    const handleQuantityChange = (code, newQuantity) => {
        if (newQuantity < 1) return; // Prevent quantity less than 1
        setCart(prev => ({
            ...prev,
            ShoppingCartItem: prev.ShoppingCartItem.map(item =>
                item.Code === code ? { ...item, Quantity: newQuantity } : item
            ),
        }));
    };

    const handleRemoveItem = (code) => {
        dispatch(removeItem(code));
        setCart(prev => ({
            ...prev,
            ShoppingCartItem: prev.ShoppingCartItem.filter(item => item.Code !== code),
        }));
    };

    if (!cart.Person || !cart.ShoppingCartItem) {
        return <p>Loading...</p>;
    }

    const { Person, ShoppingCartItem } = cart;

    return (
        <div className="container-admin-page">
            <ConfirmModal
                isOpen={isModalChangeStatusOpen}
                title="Atenção"
                message="Deseja realmente criar o orçamento?"
                onYes={handleYesModal}
                onNo={closeModal}
                yesLabel="Confirm"
                noLabel="Cancel"
                confirmData={cart}
            />
            <div className="container-admin-page-title">
                <h1>Orçamento</h1>
                <button className="main-button" onClick={openModal}>
                    Fechar Pedido
                </button>
            </div>

            <div className="cart-info div-with-border">
                <h2>Informações do Cliente</h2>
                <p><strong>Nome:</strong> {Person?.Name}</p>
                <p><strong>Email:</strong> {Person?.Email}</p>
                <p><strong>Celular:</strong> {Person?.Mobile}</p>
                <p><strong>Documento:</strong> {Person?.PhysicalDocument || Person?.LegalDocument}</p>
            </div>

            <div className="cart-info div-with-border">
                <h2>Itens do Orçamento ({ShoppingCartItem.length})</h2>
                <div className="cart-items-list">
                    {ShoppingCartItem.map((item, index) => (
                        <div key={item.Code} className="cart-item">
                            <h3>Item {index + 1}</h3>
                            <div className="item-details">
                                <div className="image-container">
                                    {item.ProductImage?.length > 0 ? (
                                        <img
                                            src={item.ProductImage[0].Url}
                                            alt={item.Description}
                                            className="product-image"
                                        />
                                    ) : (
                                        <p>Sem imagem disponível</p>
                                    )}
                                </div>
                                <div className="info-container">
                                    <p><strong>Produto:</strong> {item.Description}</p>
                                    <p><strong>Quantidade:</strong> 
                                        <input
                                            type="number"
                                            className='main-input'
                                            value={item.Quantity}
                                            onChange={(e) => handleQuantityChange(item.Code, Number(e.target.value))}
                                            min="1"
                                        />
                                    </p>
                                    <p><strong>Preço Público:</strong> R$ {item.PublicPrice}</p>
                                    <p><strong>Código Público:</strong> {item.CodePub}</p>
                                    <p><strong>Modelo:</strong> {item.Model?.Name}</p>
                                    <p><strong>Marca:</strong> {item.Brand?.Description}</p>
                                    <p><strong>Grupo:</strong> {item.Group?.Description}</p>
                                    <p><strong>Empresa:</strong> {item.Company?.Description}</p>
                                    <p><strong>Categoria:</strong> {item.Category?.Description}</p>
                                    <button className="item-inactive clickable brighten-on-hover" onClick={() => handleRemoveItem(item.Code)}>Remover</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ShoppingCartResum;
