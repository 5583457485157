import api from './serviceApi'; // Import the axios instance

const companyApi = {
  /**
   * Fetch a company by code.
   * @param {string} include - Optional parameter to include additional data.
   * @returns {Promise<Object>} - The company data.
   */
  getCompanies: async (include = '') => {
    try {
      const response = await api.get(`/company`, {
        params: { include }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching company by code:', error);
      throw error;
    }
  },
  
  /**
   * Fetch a company by code.
   * @param {string} code - The code of the company.
   * @param {string} include - Optional parameter to include additional data.
   * @returns {Promise<Object>} - The company data.
   */
  getCompanyByCode: async (code, include = '') => {
    try {
      const response = await api.get(`/company/${code}`, {
        params: { include }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching company by code:', error);
      throw error;
    }
  },

  /**
   * Fetch a list of companies with optional filters and pagination.
   * @param {Object} params - Query parameters such as page, quantity, term, orderBy, etc.
   * @returns {Promise<Object>} - The paginated list of companies.
   */
  getCompaniesPaginated: async (params) => {
    try {
      const response = await api.get('/company/pagged', { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching companies:', error);
      throw error;
    }
  },

  /**
   * Export a list of companies with filters.
   * @param {Object} params - Query parameters such as quantityMax, isActive, term, etc.
   * @returns {Promise<Blob>} - The exported file data.
   */
  exportCompanies: async (params) => {
    try {
      const response = await api.get('/company/export', { params, responseType: 'blob' });
      return response.data;
    } catch (error) {
      console.error('Error exporting companies:', error);
      throw error;
    }
  },

  /**
   * Fetch all companies.
   * @param {Object} [params] - Optional query parameters.
   * @returns {Promise<Object>} - The list of companies.
   */
  getAllCompanies: async (params = {}) => {
    try {
      const response = await api.get('/company', { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching companies:', error);
      throw error;
    }
  },

  /**
   * Create a new company.
   * @param {Object} companyData - The company data to create.
   * @returns {Promise<Object>} - The created company data.
   */
  createCompany: async (companyData) => {
    try {
      const response = await api.post('/company', companyData);
      return response.data;
    } catch (error) {
      console.error('Error creating company:', error);
      throw error;
    }
  },

  /**
   * Update an existing company.
   * @param {Object} companyData - The company data to update.
   * @returns {Promise<Object>} - The updated company data.
   */
  updateCompany: async (code, companyData) => {
    try {
      const response = await api.put('/company/' + code, companyData);
      return response.data;
    } catch (error) {
      console.error('Error updating company:', error);
      throw error;
    }
  },

  /**
   * inativate a company by their code.
   * @param {string} companyCode - The company code to inativate.
   * @returns {Promise<Object>} - The result of the deletion.
   */
  setInactive: async (companyCode) => {
    try {
      const response = await api.put('/company/setInactive/' + companyCode);
      return response.data;
    } catch (error) {
      console.error('Error inativating company:', error);
      throw error;
    }
  },

  /**
   * active a company by their code.
   * @param {string} companyCode - The company code to active.
   * @returns {Promise<Object>} - The result of the deletion.
   */
  setActive: async (companyCode) => {
    try {
      const response = await api.put('/company/setActive/' + companyCode);
      return response.data;
    } catch (error) {
      console.error('Error inativating company:', error);
      throw error;
    }
  },

  /**
   * Delete a company.
   * @param {string} code - The code of the company to delete.
   * @returns {Promise<Object>} - The result of the deletion.
   */
  deleteCompany: async (code) => {
    try {
      const response = await api.delete('/company', {
        data: { code }
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting company:', error);
      throw error;
    }
  }
};

export default companyApi;
