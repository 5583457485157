import React from 'react';

const ShoppingCartIcon = ({ size = 24, color = 'white' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M1.66666 1.66667H8.33332L12.8 23.9833C12.9524 24.7507 13.3698 25.4399 13.9792 25.9305C14.5886 26.4211 15.3512 26.6817 16.1333 26.6667H32.3333C33.1155 26.6817 33.878 26.4211 34.4874 25.9305C35.0968 25.4399 35.5142 24.7507 35.6667 23.9833L38.3333 10H9.99999M16.6667 35C16.6667 35.9205 15.9205 36.6667 15 36.6667C14.0795 36.6667 13.3333 35.9205 13.3333 35C13.3333 34.0795 14.0795 33.3333 15 33.3333C15.9205 33.3333 16.6667 34.0795 16.6667 35ZM35 35C35 35.9205 34.2538 36.6667 33.3333 36.6667C32.4128 36.6667 31.6667 35.9205 31.6667 35C31.6667 34.0795 32.4128 33.3333 33.3333 33.3333C34.2538 33.3333 35 34.0795 35 35Z"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ShoppingCartIcon;
