import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './ShoppingCartListPage.css';
import SectionWithImage from '../../../components/client/SectionWithImage/SectionWithImage';
import { setLoading } from '../../../services/redux/loadingSlice';
import shoppingCartApi from '../../../services/apiService/shoppingCartApi';
import HrefHistory from '../../../components/client/HrefHistory/HrefHistory';
import Pagination from '../../../components/common/Pagination/Pagination';
import { selectUserCode } from '../../../services/redux/authSlice';
import { useSelector } from 'react-redux';
import configService from '../../../services/configService';
import { putDateOnPattern } from '../../../utils/functions';
import ConfirmModal from '../../../components/modals/ConfirmModal/ConfirmModal';

const CartPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [shoppingCarts, setShoppingCarts] = useState([]);
    const [selectedCart, setSelectedCart] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isModalChangeStatusOpen, setIsModalChangeStatusOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const userCode = useSelector(selectUserCode);
    const quantity = configService.getDefaultNumberOfItemsTable(); 
    const orderBy = "Created:Desc";

    const openModal = (item) => {
        setSelectedCart(item);
        setIsModalChangeStatusOpen(true);
    }

    const closeModal = () => {
        setIsModalChangeStatusOpen(false);
    }

    useEffect(() => {
        const fetchModel = async () => {
            dispatch(setLoading(true));
            try {
                const response = await shoppingCartApi.getShoppingCartsPaginated({ page, quantity, orderBy, term: userCode, include: 'Person,ShoppingCartItem' });
                setShoppingCarts(response.Results);
                setTotalPages(response.TotalPages);
                setTotalItems(response.TotalCount);
            } catch (error) {
                toast.error('Erro ao buscar os orçamentos.');
            } finally {
                dispatch(setLoading(false));
            }
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
          };
        window.addEventListener('resize', handleResize);

        fetchModel();
    }, [page, quantity, userCode, refresh, dispatch]);

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    const handleYesModal = async (item) => {
        dispatch(setLoading(true));
        try {
        await shoppingCartApi.cancelService(item.Code, {value: 'Solicitação de cancelamento pelo cliente!'});
          toast.success('Atualizado com sucesso!');
          setRefresh(prev => !prev);
        } catch (error) {
          toast.error('Error updating status:' + error);
        } finally {
          dispatch(setLoading(false));
          closeModal();
        }
      };
    
    const handleNoModal = () =>{
        closeModal();
    };
    
    const openClient = (code) => {
        navigate(`${code}`);
    }

    return (
        <div className='model-description'>
            <ConfirmModal
                isOpen={isModalChangeStatusOpen}
                title="Atenção"
                message={`Deseja realmente cancelar o orçamento?`}
                onYes={handleYesModal}
                onNo={handleNoModal}
                yesLabel="Confirm"
                noLabel="Cancel"
                confirmData={selectedCart}
            />
            <SectionWithImage
                backgroundImageUrl={'https://storagealbus.blob.core.windows.net/padrao/normas-oficina-mecanica%201.png'}
                title={'Lista de Orçamentos'}
            />
            <HrefHistory/>
            <div className='shopping-cart-page-container'>
                
                <table className="admin-table">
                    <thead>
                        <tr>
                            <th>Data</th>
                            <th>Status</th>
                            {!isMobile && <th>Quantidade de Itens</th>}
                            <th>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {shoppingCarts?.map((item) => (
                            <tr key={item.Code}>
                                <td>
                                    <p className="clickable option-link" onClick={() => openClient(item.Code)}>{putDateOnPattern(item.Created)}</p>
                                </td>
                                <td>
                                    <p className='clickable option-link' onClick={() => openClient(item.Code)}>{item.Status === 'OPEN' ? 'Aberto' : (item.Status === 'IN PROGRESS' ? 'Em atendimento' : (item.Status === 'CANCEL' ? 'Cancelado' : 'Concluído'))}</p>
                                </td>
                                {
                                    !isMobile &&
                                    <td>
                                        <p className="clickable option-link" onClick={() => openClient(item.Code)}>{item.ShoppingCartItem.length}</p>
                                    </td>
                                }
                                <td>
                                    {item.Status === 'OPEN' ? (
                                        <button className="clickable item-inactive brighten-on-hover" onClick={() => openModal(item)}>
                                            Solicitar cancelamento
                                        </button>
                                    ) : (
                                        <button className="clickable item-inactive brighten-on-hover" disabled>
                                            Ação indisponível
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <sub>Total de Orçamentos: {totalItems}</sub>
                <Pagination page={page} totalPages={totalPages} onPageChange={handlePageChange} />
            </div>
        </div>
    );
};

export default CartPage;
