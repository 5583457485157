import React, { useState, useEffect } from 'react';
import './LoggerListPage.css';
import loggerApi from '../../../services/apiService/loggerApi'; 
import { setLoading } from '../../../services/redux/loadingSlice';
import { useDispatch } from 'react-redux';
import configService from '../../../services/configService';
import Pagination from '../../../components/common/Pagination/Pagination'; 
import { toast } from 'react-toastify';
import { putDateOnPattern } from '../../../utils/functions';
import FilterComponent from '../../../components/admin/FilterComponent/FilterComponent';

const LoggerListPage = () => {
    const dispatch = useDispatch();
    const [logs, setLogs] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalClients, setTotalClients] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const quantity = configService.getDefaultNumberOfItemsTable(); 
    const orderBy = "Created:Desc";

    useEffect(() => {
        const fetchLogs = async () => {
        dispatch(setLoading(true));
        try {
            const response = await loggerApi.getLogsPagged({ page, quantity, orderBy, filtro: searchTerm });
            setLogs(response.Results);
            setTotalPages(response.TotalPages);
            setTotalClients(response.TotalCount);
        } catch (error) {
            toast.error('Erro ao buscar os logs.');
        } finally {
            dispatch(setLoading(false));
        }
        };
        fetchLogs();
    }, [page, quantity, searchTerm, dispatch]);

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    const search = ({term} = {}) => {
        setSearchTerm(term);
    }

    return (
    <div className="container-admin-page">
        <h1>Lista dos últimos logs</h1>
        <div className='container-admin-page-filters div-with-border'>
            <h3>Filtros</h3>
            <FilterComponent placeHolder={'Texto'} showTermFilter={true} showEndDate={false} showStartDate={false} submitFilter={search}/>
        </div>
        <div className='container-admin-page-table div-with-border'>
            <table className="admin-table">
                <thead>
                    <tr>
                        <th>Código</th>
                        <th>Mensagem</th>
                        <th>Data</th>
                    </tr>
                    </thead>
                <tbody>
                {logs.map((log) => (
                    <tr key={log.Id}>
                    <td>{log.Id}</td>
                    <td>{log.Message}</td>
                    <td>{putDateOnPattern(log.Created)}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            <sub>Total de Logs: {totalClients}</sub>
            <Pagination page={page} totalPages={totalPages} onPageChange={handlePageChange} />
        </div>
    </div>
    );
};

export default LoggerListPage;
