import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import shoppingCartApi from '../../../services/apiService/shoppingCartApi';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../services/redux/loadingSlice';
import './ShoppingCartDetailPage.css';
import ConfirmModal from '../../../components/modals/ConfirmModal/ConfirmModal';
import { putDateOnPattern } from '../../../utils/functions';

const ShoppingCartDetailPage = () => {
    const dispatch = useDispatch();
    const { code } = useParams();
    const [cart, setCart] = useState(null);
    const [isModalChangeStatusOpen, setIsModalChangeStatusOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const openModal = () => {
        setIsModalChangeStatusOpen(true);
    }

    const closeModal = () => {
        setIsModalChangeStatusOpen(false);
    }

    useEffect(() => {
        const fetchCartDetails = async () => {
            try {
                dispatch(setLoading(true));
                const response = await shoppingCartApi.getShoppingCartByCode(
                    code,
                    'Person,Seller,ShoppingCartItem.Product,ShoppingCartItem.Product.Company,ShoppingCartItem.Product.Group,ShoppingCartItem.Product.Brand,ShoppingCartItem.Product.ProductImage'
                );
                setCart(response);
            } catch (error) {
                toast.error('Erro ao carregar os detalhes do orçamento.');
            } finally {
                dispatch(setLoading(false));
            }
        };

        fetchCartDetails();
    }, [code, refresh, dispatch]);

    const handleYesModal = async (item) => {
        dispatch(setLoading(true));
        try {
          if(Status === 'OPEN') {
            await shoppingCartApi.startService(item.Code);
          } else {
            await shoppingCartApi.finishService(item.Code, {value: 'Atendimento concluído!'});
          }
          toast.success('Atualizado com sucesso!');
          setRefresh(prev => !prev);
        } catch (error) {
          toast.error('Error updating status:' + error);
        } finally {
          dispatch(setLoading(false));
          closeModal();
        }
      };
    
    const handleNoModal = () =>{
        closeModal();
    };

    if (!cart) {
        return <p>Loading...</p>;
    }

    const { Person, Seller, ShoppingCartItem, Created, Updated, Status } = cart;

    return (
        <div className="container-admin-page">
            <ConfirmModal
                isOpen={isModalChangeStatusOpen}
                title="Atenção"
                message={`Deseja realmente ${ Status === 'OPEN' ? 'iniciar o atendimento' : 'finalizar o atendimento'}? *Esta ação não poderá ser desfeita!*`}
                onYes={handleYesModal}
                onNo={handleNoModal}
                yesLabel="Confirm"
                noLabel="Cancel"
                confirmData={cart}
            />
            <div className='container-admin-page-title'>
                <h1>Orçamento {Status === 'OPEN' ? 'em aberto' : (Status === 'IN PROGRESS' ? 'em atendimento' : (Status === 'CANCEL' ? 'cancelado' : 'concluído'))}</h1>

                {
                    Status === 'OPEN' ? 
                    <button className='main-button' onClick={openModal}>
                        Iniciar Atendimento
                    </button>
                    : 
                    Status === 'IN PROGRESS' ? 
                    <button className='modal-button modal-no' onClick={openModal}>
                        Finalizar Atendimento
                    </button>
                    : 
                    Status === 'CANCEL' ? 
                    <button className='main-button-not-click modal-no' onClick={openModal}>
                        Cancelado pelo Cliente
                    </button>
                    : 
                    <button className='main-button-not-click modal-yes' onClick={openModal} disabled>
                        Concluído
                    </button>
                }
                
            </div>

            <div className="cart-info div-with-border">
                <h2>Informações do Cliente</h2>
                <p><strong>Nome:</strong> {Person?.Name}</p>
                <p><strong>Email:</strong> {Person?.Email}</p>
                <p><strong>Celular:</strong> {Person?.Mobile}</p>
                <p><strong>Documento:</strong> {Person?.PhysicalDocument || Person?.LegalDocument}</p>
            </div>

            <div>
                <div className="cart-info div-with-border">
                    <h2>Informações do Vendedor</h2>
                    <p><strong>Nome:</strong> {Seller?.Name}</p>
                    <p><strong>Email:</strong> {Seller?.Email}</p>
                    <p><strong>Celular:</strong> {Seller?.Mobile}</p>
                </div>
            </div>

            <div className="cart-info div-with-border">
                <h2>Itens do Orçamento</h2>
                <div className="cart-items-list">
                    {ShoppingCartItem.map((item, index) => (
                        <div key={item.Code} className="cart-item">
                            <h3>Item {index + 1}</h3>
                            <div className="item-details">
                                <div className="image-container">
                                    {item.Product.ProductImage && item.Product.ProductImage.length > 0 ? (
                                        <img src={item.Product.ProductImage[0].Url} alt={item.Product.Description} className="product-image" />
                                    ) : (
                                        <p>Sem imagem disponível</p>
                                    )}
                                </div>
                                <div className="info-container">
                                    <p><strong>Produto:</strong> {item.Product.Description}</p>
                                    <p><strong>Quantidade:</strong> {item.Quantity}</p>
                                    <p><strong>Preço Público:</strong> R$ {item.Product.PublicPrice}</p>
                                    <p><strong>Marca:</strong> {item.Product.Brand?.Name}</p>
                                    <p><strong>Grupo:</strong> {item.Product.Group?.Description}</p>
                                    <p><strong>Empresa:</strong> {item.Product.Company?.Description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="cart-info div-with-border margin-top-default">
                <h3>Informações do Orçamento</h3>
                <p><strong>Status:</strong> <span className={Status === 'OPEN' ? 'item-open' : (Status === 'IN PROGRESS' ? 'item-inactive' : 'item-active')}>{Status === 'OPEN' ? 'Aberto' : (Status === 'IN PROGRESS' ? 'Em atendimento' : (Status === 'CANCEL' ? 'Cancelado' : 'Concluído'))}</span></p>
                <p><strong>Data de Criação:</strong> {putDateOnPattern(Created)}</p>
                <p><strong>Data de Atualização:</strong> {putDateOnPattern(Updated)}</p>
            </div>
        </div>
    );
};

export default ShoppingCartDetailPage;
