import React from 'react';
import Navbar from '../../components/common/Navbar/Navbar'; 
import Footer from '../../components/common/Footer/Footer'; 
import './ClientLayout.css';

const ClientLayout = ({ children }) => {
  return (
    <div className="client-layout">
      {/* Navbar at the top */}
      <Navbar />

      {/* Main content, wrapped by the layout */}
      <main className="content">
        {children}
      </main>

      {/* Footer at the bottom */}
      <Footer />
    </div>
  );
};

export default ClientLayout;
