import React, { useState } from 'react';
import './EditPassword.css';
import EyeIcon from '../../../components/icons/EyeIcon/EyeIcon';
import EyeOffIcon from '../../../components/icons/EyeOffIcon/EyeOffIcon';
import { toast } from 'react-toastify';
import personApi from '../../../services/apiService/personApi';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../services/redux/loadingSlice';

const EditPassword = ({onClose = null}) => {
    const dispatch = useDispatch();
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePasswordVisibility = (setter) => {
        setter(prevState => !prevState);
    };

    const handleSave = async (e) => {
        e.preventDefault();

        if(newPassword !== confirmPassword){
            toast.error('Novas senhas não conferem');
        }
        else if(newPassword === currentPassword){
            toast.error('Nova senha não pode ser igual a senha anterior.');
        }
        else{
            dispatch(setLoading(true));
            try {
                await personApi.updatePasswordPerson({
                    currentPassword,
                    newPassword,
                    confirmPassword
                });
                dispatch(setLoading(false));
                toast.success('Alterado com sucesso!');

                if(onClose !== null){
                    onClose();
                }
            } catch (error) {
                dispatch(setLoading(false));
                toast.error('Erro ao alterar senha. Tente novamente.');
                console.error('Error updating password:', error);
            }
        }
    };

    return (
    <div className='container-admin-page'>
        <h1>
            Configurações
        </h1>

        <form className="container-admin-page-border" onSubmit={handleSave}>

            <div className="password-field">
                <label>Senha Atual</label>
                <div className="input-container">
                    <input
                        type={showCurrentPassword ? 'text' : 'password'}
                        placeholder="Digite sua senha atual"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required
                    />
                    <div className='form-group-input-icon option-link' onClick={() => togglePasswordVisibility(setShowCurrentPassword)}>
                    {
                        showCurrentPassword ?
                        <EyeOffIcon size={24} color='black'/>
                        :
                        <EyeIcon size={24} color='black'/>
                    }
                    </div>
                </div>
            </div>

            <div className="password-field">
                <label>Nova Senha</label>
                <div className="input-container">
                    <input
                        type={showNewPassword ? 'text' : 'password'}
                        placeholder="Digite sua nova senha"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                    <div className='form-group-input-icon option-link' onClick={() => togglePasswordVisibility(setShowNewPassword)}>
                        {
                            showNewPassword ?
                            <EyeOffIcon size={24} color='black'/>
                            :
                            <EyeIcon size={24} color='black'/>
                        }
                    </div>
                </div>
            </div>

            <div className="password-field">
                <label>Confirmar senha</label>
                <div className="input-container">
                    <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        placeholder="Confirme sua nova senha"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                    <div className='form-group-input-icon option-link' onClick={() => togglePasswordVisibility(setShowConfirmPassword)}>
                        {
                            showConfirmPassword ?
                            <EyeOffIcon size={24} color='black'/>
                            :
                            <EyeIcon size={24} color='black'/>
                        }
                    </div>
                </div>
            </div>
            
            {
                onClose === null ? 
                <div className='button-field-opton'>
                    <button type='submmit' className='admin-button'>Salvar</button>
                </div>
                :
                <div className='space-component'>
                    <button className='main-button' onClick={onClose}>Cancelar</button>
                    <button className='main-button' type="submit">Salvar</button>
                </div>
            }
        </form>
    </div>
  );
};

export default EditPassword;
