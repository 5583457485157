import api from './serviceApi'; // Import the axios instance

const dashboardApi = {
  /**
   * Fetch dashboard metrics.
   * @returns {Promise<Object>} - The metrics data.
   */
  getMetrics: async () => {
    try {
      const response = await api.get('/dashboard/metrics');
      return response.data;
    } catch (error) {
      console.error('Error fetching dashboard metrics:', error);
      throw error;
    }
  },

  /**
   * Fetch best-selling products data.
   * @returns {Promise<Object>} - The data for best-selling products.
   */
  getProductBestSeller: async () => {
    try {
      const response = await api.get('/dashboard/product-best-seller');
      return response.data;
    } catch (error) {
      console.error('Error fetching best-selling products:', error);
      throw error;
    }
  },

  /**
   * Fetch data on best sellers.
   * @returns {Promise<Object>} - The data for best sellers.
   */
  getBestSellers: async () => {
    try {
      const response = await api.get('/dashboard/best-sellers');
      return response.data;
    } catch (error) {
      console.error('Error fetching best sellers:', error);
      throw error;
    }
  },

  /**
   * Fetch daily sales data.
   * @returns {Promise<Object>} - The data for sales per day.
   */
  getSellPerDay: async () => {
    try {
      const response = await api.get('/dashboard/sell-per-day');
      return response.data;
    } catch (error) {
      console.error('Error fetching daily sales:', error);
      throw error;
    }
  }
};

export default dashboardApi;
