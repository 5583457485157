import api from './serviceApi'; // Import the axios instance

const productImageApi = {
  /**
   * Fetch a product by code.
   * @param {string} code - The code of the product.
   * @param {string} include - Optional parameter to include additional data.
   * @returns {Promise<Object>} - The product data.
   */
  getProductByCode: async (code, include = '') => {
    try {
      const response = await api.get(`/productImage/${code}`, {
        params: { include }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching product by code:', error);
      throw error;
    }
  },

  /**
   * Fetch a list of products with optional filters and pagination.
   * @param {Object} params - Query parameters such as quantity, term, orderBy, etc.
   * @returns {Promise<Object>} - The paginated list of products.
   */
  getProductsPaginated: async (params) => {
    try {
      const response = await api.get('/productImage/pagged', { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching products:', error);
      throw error;
    }
  },

  /**
   * Export a list of products with filters.
   * @param {Object} params - Query parameters such as quantityMax, isActive, term, etc.
   * @returns {Promise<Blob>} - The exported file data.
   */
  exportProducts: async (params) => {
    try {
      const response = await api.get('/productImage/export', { params, responseType: 'blob' });
      return response.data;
    } catch (error) {
      console.error('Error exporting products:', error);
      throw error;
    }
  },

  /**
   * Create a new product.
   * @param {Object} productData - The product data to create.
   * @returns {Promise<Object>} - The created product data.
   */
  createProduct: async (productData) => {
    try {
      const response = await api.post('/productImage', productData);
      return response.data;
    } catch (error) {
      console.error('Error creating product:', error);
      throw error;
    }
  },

  /**
   * Update an existing product.
   * @param {Object} productData - The product data to update.
   * @returns {Promise<Object>} - The updated product data.
   */
  updateProduct: async (productData) => {
    try {
      const response = await api.put('/productImage', productData);
      return response.data;
    } catch (error) {
      console.error('Error updating product:', error);
      throw error;
    }
  },

  /**
   * Delete a product.
   * @param {string} data - The code of the product to delete.
   * @returns {Promise<Object>} - The result of the deletion.
   */
  deleteProduct: async (data) => {
    try {
      // Use DELETE with data as configuration (for body in the delete request)
      await api.post('/productImage/delete', 
        data
      );
      // No return needed for 204 response
    } catch (error) {
      console.error('Error deleting image:', error);
      throw error;
    }
  }
};

export default productImageApi;
