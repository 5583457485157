import React from 'react';
import './SectionWithImage.css';

const SectionWithImage = ({ title, backgroundImageUrl }) => {
    return (
        <section
            className="home-section section-1"
            style={{ backgroundImage: `url(${backgroundImageUrl})` }}
        >
            <h1 className="section-1-title">{title}</h1>
        </section>
    );
};

export default SectionWithImage;
