// AddressInfoForm.js
import React, { useState, useEffect } from 'react';
import { maskCEP } from '../../../masks/masks';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../../services/redux/loadingSlice';
import cityApiService from '../../../../services/apiService/cityApiService';
import { toast } from 'react-toastify';

const AddressInfoForm = ({ addressData, setAddressData, handleAddressChange }) => {
    const dispatch = useDispatch();
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [stateSelected, setStateSelected] = useState();
    const [citySelected, setCitySelected] = useState();
    const [cepFound, setCepFound] = useState(true);

    useEffect(() => {
        async function getStates(){
            try {
                dispatch(setLoading(true));
                const response = await cityApiService.getAllStates();
                dispatch(setLoading(false));
        
                setStates(response.data);
            } catch (error) {
                toast.error('Erro ao carregar estados.');
            }
        }
        getStates();

    }, [dispatch])

    const handleCepBlur = async () => {
        try {
          dispatch(setLoading(true));
          const response = await cityApiService.getCityByCep(addressData.zipcode);
          dispatch(setLoading(false));
    
          if (response.data && response.data.State) {
            const { LongAddress, Complement, Neighborhood, City, CityCode, State } = response.data;
            
            setAddressData({
              ...addressData,
              longAddress: LongAddress,
              complement: Complement,
              neighborhood: Neighborhood,
              cityCode: CityCode,
              stateCode: State
            });
    
            setCities([{ Code: CityCode, Name: City }]);
            setStates([ State]);
            setCitySelected(CityCode);
            setStateSelected(State);
            setCepFound(true);
            
          } else {
            toast.error('CEP não encontrado.');
            setCepFound(false);
          }
        } catch (error) {
          setCepFound(false);
          dispatch(setLoading(false));
          toast.error('Erro ao buscar informações pelo CEP.');
        }
    };

    const handleStateChange = async (e) =>{
        handleAddressChange(e);
    
        if(!cepFound){
          dispatch(setLoading(true));
          const response = await cityApiService.getCityByState(e.target.value);
          dispatch(setLoading(false));
    
          if (response.data) {
            setCities(response.data);
          }
        }
    }

    return (
    <>
      <div className="form-group-register">
        <div className='form-group-inside'>
          <label>CEP</label>
          <input type="text" name="zipcode" value={maskCEP(addressData.zipcode)} onChange={handleAddressChange} onBlur={handleCepBlur} required/>
        </div>
        <div className='form-group-inside'>
          <label>Estado</label>
          <select name="stateCode" onChange={handleStateChange} disabled={cepFound} value={stateSelected}>
            <option value="">Selecione um estado</option>
            {states.map(state => <option key={state} value={state}>{state}</option>)}
          </select>
        </div>
        <div className='form-group-inside'>
          <label>Cidade</label>
          <select name="cityCode" onChange={handleAddressChange} disabled={cepFound} value={citySelected}>
            <option value="">Selecione uma cidade</option>
            {cities.map(city => <option key={city.Code} value={city.Code}>{city.Name}</option>)}
          </select>
        </div>
      </div>

      <div className="form-group-register">
        <div className='form-group-inside'>
          <label>Logradouro</label>
          <input type="text" name="longAddress" value={addressData.longAddress} onChange={handleAddressChange} required />
        </div>
      </div>

      <div className="form-group-register">
        <div className='form-group-inside'>
          <label>Número</label>
          <input type="text" name="number" value={addressData.number} onChange={handleAddressChange} required />  
        </div>
        <div className='form-group-inside'>
          <label>Bairro</label>
          <input type="text" name="neighborhood" value={addressData.neighborhood} onChange={handleAddressChange} required />  
        </div>
        <div className='form-group-inside'>
          <label>Complemento</label>
          <input type="text" name="complement" value={addressData.complement} onChange={handleAddressChange} />  
        </div>
      </div>

      <div className="form-group-register">
        <div className='form-group-inside'>
          <label>Ponto de Referência</label>
          <input type="text" name="additionalInformation" value={addressData.additionalInformation} onChange={handleAddressChange} />
        </div>
      </div>
    </>
  );
};

export default AddressInfoForm;
