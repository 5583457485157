// PersonalInfoForm.js
import React, { useState } from 'react';
import { maskCPF, maskCNPJ, maskPhone } from '../../../masks/masks';
import EyeIcon from '../../../icons/EyeIcon/EyeIcon';
import EyeOffIcon from '../../../icons/EyeOffIcon/EyeOffIcon';

const PersonalInfoForm = ({ formData, handleInputChange, inputRefs, isPhysical, setIsPhysical }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordVerify, setShowPassworderify] = useState(false);
    
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const togglePasswordVisibilityVerify = () => {
        setShowPassworderify(!showPasswordVerify);
    };

    return (
    <>
      <div className="form-group-register">
        <div className='section-register'>
          <div className={isPhysical ? 'section-register-option section-register-selected' : 'section-register-option section-register-no-selected'} onClick={() => setIsPhysical(true)}>
            <label className='section-register-option'>CPF</label>
          </div>
          <div className={!isPhysical ? 'section-register-option section-register-selected' : 'section-register-option section-register-no-selected'} onClick={() => setIsPhysical(false)}>
            <label className='section-register-option'>CNPJ</label>
          </div>
        </div>
      </div>

      <div className="form-group-register">
        <div className='form-group-inside form-group-inside-name'>
          <label>Nome</label>
          <input type="text" name="name" value={formData.name} onChange={handleInputChange} required ref={inputRefs.name}/>
        </div>
        <div className='form-group-inside form-group-inside-document'>
          {isPhysical ? (
            <div>
              <label>CPF</label>
              <input type="text" name="physicalDocument" value={maskCPF(formData.physicalDocument)} onChange={handleInputChange} required ref={inputRefs.physicalDocument}/>
            </div>
          ) : (
            <div>
              <label>CNPJ</label>
              <input type="text" name="legalDocument" value={maskCNPJ(formData.legalDocument)} onChange={handleInputChange} required ref={inputRefs.legalDocument}/>
            </div>
          )}
        </div>
      </div>

      <div className="form-group-register">
        <div className='form-group-inside'>
          <label>Telefone</label>
          <input type="text" name="phone1" value={maskPhone(formData.phone1)} onChange={handleInputChange} />
        </div>
        <div className='form-group-inside'>
          <label>Celular</label>
          <input type="text" name="mobile" value={maskPhone(formData.mobile)} onChange={handleInputChange} required ref={inputRefs.mobile}/>
        </div>
      </div>

      <div className="form-group-register">
        <div className='form-group-inside'>
          <label>Email</label>
          <input type="email" name="email" value={formData.email} onChange={handleInputChange} required ref={inputRefs.email}/>
        </div>
      </div>

      <div className="form-group-register">
        <div className='form-group-inside'>
          <label>Senha</label>
          <div className='form-group-input'>
            <input type={showPassword ? "text" : "password"}  name="password" value={formData.password} onChange={handleInputChange} required ref={inputRefs.password}/>
            <div className='form-group-input-icon option-link' onClick={togglePasswordVisibility}>
              {showPassword ? <EyeOffIcon size={24} color='black'/> : <EyeIcon size={24} color='black'/>}
            </div>
          </div>
        </div>
        <div className='form-group-inside'>
          <label>Confirma senha</label>
          <div className='form-group-input'>
            <input type={showPasswordVerify ? "text" : "password"}  name="passwordVerify" value={formData.passwordVerify} onChange={handleInputChange} required ref={inputRefs.passwordVerify}/>
            <div className='form-group-input-icon option-link' onClick={togglePasswordVisibilityVerify}>
              {showPasswordVerify ? <EyeOffIcon size={24} color='black'/> : <EyeIcon size={24} color='black'/>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalInfoForm;
