// src/services/redux/loadingSlice.js
import { createSlice } from '@reduxjs/toolkit';

const loginSlice = createSlice({
  name: 'login',
  initialState: {
    isOpen: false,
  },
  reducers: {
    openCloseLogin: (state, action) => {
      state.isOpen = action.payload;
    },
  },
});

export const { openCloseLogin } = loginSlice.actions;
export default loginSlice.reducer;
