import api from './serviceApi'; // Import the axios instance

const loggerApi = {
  /**
   * Fetch a log by its ID.
   * @param {number} id - The ID of the log.
   * @returns {Promise<Object>} - The log data.
   */
  getLogById: async (id) => {
    try {
      const response = await api.get(`/logger/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching log by ID:', error);
      throw error;
    }
  },

  /**
   * Fetch logs with optional filters such as date range, pagination, and ordering.
   * @param {Object} params - Query parameters such as dateBegin, dateFinal, filtro, page, quantity, etc.
   * @returns {Promise<Object>} - The filtered list of logs.
   */
  getLogsPagged: async (params) => {
    try {
      const response = await api.get('/logger/pagged', { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching logs:', error);
      throw error;
    }
  },

  /**
   * Fetch logs with optional filters such as date range, pagination, and ordering.
   * @param {Object} params - Query parameters such as dateBegin, dateFinal, filtro, page, quantity, etc.
   * @returns {Promise<Object>} - The filtered list of logs.
   */
  getLogs: async (params) => {
    try {
      const response = await api.get('/logger', { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching logs:', error);
      throw error;
    }
  }
};

export default loggerApi;
