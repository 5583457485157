import React, { useState, useEffect, useRef } from 'react';
import './ProductList.css';
import productApi from '../../../services/apiService/productApi';
import { setLoading } from '../../../services/redux/loadingSlice';
import { useDispatch, useSelector } from 'react-redux';
import configService from '../../../services/configService';
import Pagination from '../../../components/common/Pagination/Pagination'; 
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import OpenIcon from '../../../components/icons/OpenIcon/OpenIcon';
import FilterComponent from '../../../components/admin/FilterComponent/FilterComponent';
import { saveAs } from 'file-saver';
import ProductRegisterModal from '../../../components/modals/ProductRegisterModal/ProductRegisterModal';
import NoImageIcon from '../../../components/icons/NoImageIcon/NoImageIcon';
import ConfirmModal from '../../../components/modals/ConfirmModal/ConfirmModal';
import { addItem, removeItem, selectAllCartItems } from '../../../services/redux/shoppingCartSlice';

const ProductList = ({isResum = false}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isProductRegisterOpen, setisProductRegisterOpen] = useState(false);
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchBrand, setSearchBrand] = useState('');
    const [searchGroup, setSearchGroup] = useState('');
    const [searchCompany, setSearchCompany] = useState('');
    const [searchModel, setSearchModel] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [refresh, setRefresh] = useState(false);
    const quantity = configService.getDefaultNumberOfItemsTable(); 
    const orderBy = "Created:Desc";
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [itemSelected, setItemSelected] = useState();
    const fileInputRef = useRef(null);

    const cartItems = useSelector(selectAllCartItems) || [];
    const isItemInCart = (itemCode) => {
        if(cartItems !== undefined)
            return cartItems.find(cartItem => cartItem.Code === itemCode);
        return false;
    }

    const openRegisterProduct = () => {
        setisProductRegisterOpen(true);
    }

    const closeRegisterProduct = () => {
        setisProductRegisterOpen(false);
    }

    const openModalDelete = () => {
        setIsModalDeleteOpen(true);
    }

    const closeModalDelete = () => {
        setIsModalDeleteOpen(false);
    }

    useEffect(() => {
        const fetchProduct = async () => {
            dispatch(setLoading(true));
            try {
                const response = await productApi.getProductsPaginated({ page, quantity, orderBy, term: searchTerm, group: searchGroup, brand: searchBrand, model: searchModel, company: searchCompany, include: 'Company,Group,Brand,Model,Model.ModelImage,ProductImage,Category' });
                setItems(response.Results);
                setTotalPages(response.TotalPages);
                setTotalItems(response.TotalCount);
            } catch (error) {
                toast.error('Erro ao buscar os itens.');
            } finally {
                dispatch(setLoading(false));
            }
        };

        const fetchData = async () => {
            try {
                dispatch(setLoading(true));
                await fetchProduct();
            } finally {
                dispatch(setLoading(false));
            }
        };
        fetchData();
    }, [page, quantity, searchTerm, searchGroup, searchBrand, searchCompany, searchModel, refresh, dispatch]);

    const openProduct = (code) => {
        navigate(`${code}`);
    }

    const openProductInNewTab = (code) => {
        const url = `${window.location.origin}/${code}`;
        window.open(url, '_blank');
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    const search = ({ term, brand, group, company, model } = {}) => {
        setSearchTerm(term);
        setSearchBrand(brand);
        setSearchGroup(group);
        setSearchCompany(company);
        setSearchModel(model);
    };

    const exportFunction = async (term) => {
        try {
            dispatch(setLoading(true));
            const response = await productApi.exportProducts({ term: term });
            
            if (response.Status === 200 && response.Object) {
                saveAs(response.Object, 'reportProducts.csv');
                toast.success('Relatório gerado com sucesso!');
            } else {
                toast.error('Erro ao gerar o relatório 1');
            }
        } catch (error) {
            toast.error('Erro ao gerar o relatório');
        }
        finally{
            dispatch(setLoading(false));
        }
    };

    const createProduct = async (brand) =>{
        try{
            dispatch(setLoading(true));
            var response = await productApi.createProduct(brand);

            toast.success(response.Description + ' Criado com sucesso!');
            setRefresh((prev) => !prev);
        }
        catch(error){
            toast.error('Error :' + error);
        }
        finally{
            dispatch(setLoading(false));
        }
    }

    const handleYesModalDelete = async (item) => {
        dispatch(setLoading(true));
        try {
          if(item.IsActive) {
            await productApi.setInactive(item.Code);
          } else {
            await productApi.setActive(item.Code);
          }
          toast.success('Atualizado com sucesso!');
          setRefresh(prev => !prev);
        } catch (error) {
          toast.error('Error updating product status:' + error);
        } finally {
          dispatch(setLoading(false));
          setIsModalDeleteOpen(false);
        }
      };
    
    const handleNoModalDelete = () =>{
        closeModalDelete();
    };

    const deleteOption = (client) => {
        setItemSelected(client);
        openModalDelete();
    }

    const handleAddToCart = (item) => {
        dispatch(addItem(item));
        setRefresh((prev) => !prev);
    };

    const handleRemoveToCart = (item) => {
        dispatch(removeItem(item));
        setRefresh((prev) => !prev);
    };

    const handleFileChange = async (event) => {
        var file = event.target.files[0];
        if (!file) {
            toast.error('Selecione um arquivo CSV.');
            return;
        }
    
        const formData = new FormData();
        formData.append('file', file);
    
        try {
            dispatch(setLoading(true));
            const response = await productApi.importCsv(formData);
            toast.success(`${response.Message}`);
            toast.success(`${response.Items.length} itens importados com sucesso!`);

            setRefresh(prev => !prev);
        } catch (error) {
            toast.error(`Erro ao importar: ${error.response.data}`);
        } finally {
            dispatch(setLoading(false));
        }
    
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleImportClick = () => {
        fileInputRef.current.click(); 
    };

    return (
    <div>
        {
            !isResum &&
            <ProductRegisterModal isOpen={isProductRegisterOpen} onClose={closeRegisterProduct} onSubmit={createProduct} />
        }
        {
            !isResum &&
            <ConfirmModal
                isOpen={isModalDeleteOpen}
                title="Atenção"
                message={`Deseja realmente ${itemSelected?.IsActive ? 'desativar' : 'ativar'} o produto ${itemSelected?.CodePub}?`}
                onYes={handleYesModalDelete}
                onNo={handleNoModalDelete}
                yesLabel="Confirm"
                noLabel="Cancel"
                confirmData={itemSelected}
            />
        }
        <h1>Produtos</h1>
        <div className='container-admin-page-filters div-with-border'>
            <h3>Filtros</h3>
            <FilterComponent 
                placeHolder={'Código | Nome | Número interno'} 
                showTermFilter={true} 
                showEndDate={false}
                showStartDate={false} 
                showBrand={true}
                showGroup={true}
                showCompany={true}
                showModels={true}
                submitFilter={search} 
                exportFunction={exportFunction}/>
        </div>
        <div className='container-admin-page-table div-with-border'>
            <div className='right-component'>
                {
                    !isResum ?
                    <div className='gap-default product-buttons-import'>
                        <button className='main-button' onClick={openRegisterProduct}>Novo Produto</button>
                        <button className='main-button' onClick={handleImportClick}>Importar CSV</button>
                        <input
                            type="file"
                            accept=".csv"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                            id="file-input"
                            ref={fileInputRef}
                        />
                    </div>
                    :
                    <button className='main-button' onClick={() => navigate('fechamento')}>Fechar Orçamento</button>
                }
            </div>
            <table className="admin-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Código Público</th>
                        <th>Descrição</th>
                        <th>Modelo</th>
                        <th>Empresa</th>
                        <th>Grupo</th>
                        <th>Marca</th>
                        <th>Categoria</th>
                        {!isResum && <th>Preço público</th>}
                        {!isResum && <th>Status</th>}
                        {isResum && <th></th>}
                        {isResum && <th></th>}
                        <th></th>
                        </tr>
                    </thead>
                <tbody>
                {items.map((item) => (
                    <tr key={item.Code}>
                        {
                            item.ProductImage?.length > 0 ? (
                                <td>
                                    <span className='option-link' onClick={() => openProduct(item.Code)}>
                                        <img src={item.ProductImage[0].Url} alt="Produto" style={{ width: '4rem', height: '4rem' }} />
                                    </span>
                                </td>
                            ) : 
                            item.Model.ModelImage?.length > 0 ? (
                                <td>
                                    <span className='option-link' onClick={() => openProduct(item.Code)}>
                                        <img src={item.Model.ModelImage[0].Url} alt="Produto" style={{ width: '4rem', height: '4rem' }} />
                                    </span>
                                </td>
                            ) : (
                                <td>
                                    <span className='option-link' onClick={() => openProduct(item.Code)}>
                                        <NoImageIcon size={'4rem'}/>
                                    </span>
                                </td>
                            )
                        }
                        <td><span className='option-link' onClick={() => openProduct(item.Code)}>{item.CodePub}</span></td>
                        <td><span className='option-link' onClick={() => openProduct(item.Code)}>{item.Description}</span></td>
                        <td><span className='option-link' onClick={() => openProduct(item.Code)}>{item.Model.Name}</span></td>
                        <td><span className='option-link' onClick={() => openProduct(item.Code)}>{item.Company.Description}</span></td>
                        <td><span className='option-link' onClick={() => openProduct(item.Code)}>{item.Group.Description}</span></td>
                        <td><span className='option-link' onClick={() => openProduct(item.Code)}>{item.Brand.Description}</span></td>
                        <td><span className='option-link' onClick={() => openProduct(item.Code)}>{item.Category.Description}</span></td>
                        {!isResum && <td><span className='option-link' onClick={() => openProduct(item.Code)}>{item.PublicPrice}</span></td>}
                        {!isResum && <td className='clickable'><span className={item.IsActive ? 'item-active brighten-on-hover' : 'item-inactive brighten-on-hover'} onClick={() => deleteOption(item)}>{item.IsActive ? 'Ativo' : 'Inativo'}</span></td>}
                        {!isResum && <td><span className='option-link' onClick={() => openProduct(item.Code)}><OpenIcon/></span></td>}
                        {isResum && <td><span className='option-link' onClick={() => openProductInNewTab(item.Code)}><OpenIcon/></span></td>}
                        {isResum && (
                            isItemInCart(item.Code) ?
                            <td><button className='clickable item-inactive brighten-on-hover' onClick={() => handleRemoveToCart(item.Code)}>Remover</button></td>
                            :
                            <td><button className='clickable item-active brighten-on-hover' onClick={() => handleAddToCart(item)}>Adicionar</button></td>
                        )
                        }
                    </tr>
                ))}
                </tbody>
            </table>
            <sub>Total de Produtos: {totalItems}</sub>
            <Pagination page={page} totalPages={totalPages} onPageChange={handlePageChange} />
        </div>
    </div>
    );
}

export default ProductList;