import axios from 'axios';
import getCurrentEnvConfig from '../../configuration/envConfig';
import Config from '../../configuration/storageConfiguration';
import storageService from '../storageService';  // Use your storageService to manage tokens
import ConfigService from '../configService';

const apiUrl = getCurrentEnvConfig();

const api = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add request interceptor to set the token dynamically
api.interceptors.request.use(
  (config) => {
    config.headers['Channel-Code'] = ConfigService.getChannelCode();

    const token = storageService.getItem(Config['TOKEN']); // Dynamically fetch the token
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor to handle unauthorized (401) errors
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.code === 'ERR_NETWORK' || (error.response && error.response.status === 401)) {
      storageService.clear(); // Clear local storage on unauthorized access
      window.location.href = '/'; // Redirect to login page or handle unauthorized
    }
    return Promise.reject(error);
  }
);

export default api;
