import React from 'react';

const UserAddIcon = ({ size = 24, color = '#F3F3F3' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M26.6667 35V31.6667C26.6667 29.8986 25.9643 28.2029 24.714 26.9526C23.4638 25.7024 21.7681 25 20 25H8.33332C6.56521 25 4.86952 25.7024 3.61928 26.9526C2.36904 28.2029 1.66666 29.8986 1.66666 31.6667V35M33.3333 13.3333V23.3333M38.3333 18.3333H28.3333M20.8333 11.6667C20.8333 15.3486 17.8486 18.3333 14.1667 18.3333C10.4848 18.3333 7.49999 15.3486 7.49999 11.6667C7.49999 7.98477 10.4848 5 14.1667 5C17.8486 5 20.8333 7.98477 20.8333 11.6667Z"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default UserAddIcon;
