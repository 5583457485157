import api from './serviceApi'; // Import the axios instance

const categoryApi = {
  /**
   * Fetch a category by code.
   * @param {string} include - Optional parameter to include additional data.
   * @returns {Promise<Object>} - The category data.
   */
  getCategorys: async (include = '') => {
    try {
      const response = await api.get(`/category`, {
        params: { include }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching category by code:', error);
      throw error;
    }
  },
  /**
   * Fetch a category by code.
   * @param {string} code - The code of the category.
   * @param {string} include - Optional parameter to include additional data.
   * @returns {Promise<Object>} - The category data.
   */
  getCategoryByCode: async (code, include = '') => {
    try {
      const response = await api.get(`/category/${code}`, {
        params: { include }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching category by code:', error);
      throw error;
    }
  },

  /**
   * Fetch a list of categorys with optional filters and pagination.
   * @param {Object} params - Query parameters such as quantity, term, orderBy, etc.
   * @returns {Promise<Object>} - The paginated list of categorys.
   */
  getCategorysPaginated: async (params) => {
    try {
      const response = await api.get('/category/pagged', { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching categorys:', error);
      throw error;
    }
  },

  /**
   * Export a list of categorys with filters.
   * @param {Object} params - Query parameters such as quantityMax, isActive, term, etc.
   * @returns {Promise<Blob>} - The exported file data.
   */
  exportCategorys: async (params) => {
    try {
      const response = await api.get('/category/export', { params, responseType: 'blob' });
      return response.data;
    } catch (error) {
      console.error('Error exporting categorys:', error);
      throw error;
    }
  },

  /**
   * Create a new category.
   * @param {Object} categoryData - The category data to create.
   * @returns {Promise<Object>} - The created category data.
   */
  createCategory: async (categoryData) => {
    try {
      const response = await api.post('/category', categoryData);
      return response.data;
    } catch (error) {
      console.error('Error creating category:', error);
      throw error;
    }
  },

  /**
   * Update an existing category.
   * @param {Object} categoryData - The category data to update.
   * @returns {Promise<Object>} - The updated category data.
   */
  updateCategory: async (code, categoryData) => {
    try {
      const response = await api.put('/category/' + code, categoryData);
      return response.data;
    } catch (error) {
      console.error('Error updating category:', error);
      throw error;
    }
  },

/**
   * category a password.
   * @param {Object} categoryData - The category data to update.
   * @returns {Promise<Object>} - The updated category data.
   */
updatePasswordPerson: async (categoryData) => {
  try {
    const response = await api.put('/category/update-password', categoryData);
    return response.data;
  } catch (error) {
    console.error('Error updating password for person:', error);
    throw error;
  }
},

/**
   * inativate a category by their code.
   * @param {string} categoryCode - The category code to inativate.
   * @returns {Promise<Object>} - The result of the deletion.
   */
setInactive: async (categoryCode) => {
  try {
    const response = await api.put('/category/setInactive/' + categoryCode);
    return response.data;
  } catch (error) {
    console.error('Error inativating category:', error);
    throw error;
  }
},

/**
 * active a category by their code.
 * @param {string} categoryCode - The category code to active.
 * @returns {Promise<Object>} - The result of the deletion.
 */
setActive: async (categoryCode) => {
  try {
    const response = await api.put('/category/setActive/' + categoryCode);
    return response.data;
  } catch (error) {
    console.error('Error inativating category:', error);
    throw error;
  }
},

  /**
   * Delete a category.
   * @param {string} code - The code of the category to delete.
   * @returns {Promise<Object>} - The result of the deletion.
   */
  deleteCategory: async (code) => {
    try {
      const response = await api.delete('/category', {
        data: { code }
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting category:', error);
      throw error;
    }
  }
};

export default categoryApi;
