import api from './serviceApi'; // Import the axios instance

const personApi = {
  /**
   * Fetch a person by their code.
   * @param {string} code - The code of the person.
   * @param {string} include - Optional parameter to include additional data.
   * @returns {Promise<Object>} - The person data.
   */
  getPersonByCode: async (code, include = '') => {
    try {
      const response = await api.get(`/person/${code}`, {
        params: { include }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching person by code:', error);
      throw error;
    }
  },

  /**
   * Fetch a paginated list of persons with optional filters.
   * @param {Object} params - Query parameters such as page, quantity, term, orderBy, etc.
   * @returns {Promise<Object>} - The paginated list of persons.
   */
  getPersonsPaginated: async (params) => {
    try {
      const response = await api.get('/person/pagged', { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching persons paginated:', error);
      throw error;
    }
  },

  /**
   * Export a list of persons based on filters.
   * @param {Object} params - Query parameters such as quantityMax, isActive, term, etc.
   * @returns {Promise<Blob>} - The exported file data.
   */
  exportPersons: async (params) => {
    try {
      const response = await api.get('/person/export', { params, responseType: 'blob' });
      return response.data;
    } catch (error) {
      console.error('Error exporting persons:', error);
      throw error;
    }
  },

  /**
   * Create a new person.
   * @param {Object} personData - The person data to create.
   * @returns {Promise<Object>} - The created person data.
   */
  createPerson: async (personData) => {
    try {
      const response = await api.post('/person', personData);
      return response.data;
    } catch (error) {
      console.error('Error creating person:', error);
      throw error;
    }
  },

  /**
   * Update a person.
   * @param {Object} personData - The person data to update.
   * @returns {Promise<Object>} - The updated person data.
   */
  updatePerson: async (personData) => {
    try {
      const response = await api.put('/person', personData);
      return response.data;
    } catch (error) {
      console.error('Error updating person:', error);
      throw error;
    }
  },

  /**
   * inativate a person by their code.
   * @param {string} personCode - The person code to inativate.
   * @returns {Promise<Object>} - The result of the deletion.
   */
  setInactive: async (personCode) => {
    try {
      const response = await api.put('/person/setInactive/' + personCode);
      return response.data;
    } catch (error) {
      console.error('Error inativating person:', error);
      throw error;
    }
  },

  /**
   * active a person by their code.
   * @param {string} personCode - The person code to active.
   * @returns {Promise<Object>} - The result of the deletion.
   */
  setActive: async (personCode) => {
    try {
      const response = await api.put('/person/setActive/' + personCode);
      return response.data;
    } catch (error) {
      console.error('Error inativating person:', error);
      throw error;
    }
  },

  /**
     * Update a password.
     * @param {Object} personData - The person data to update.
     * @returns {Promise<Object>} - The updated person data.
     */
  updatePasswordPerson: async (personData) => {
    try {
      const response = await api.put('/person/update-password', personData);
      return response.data;
    } catch (error) {
      console.error('Error updating password for person:', error);
      throw error;
    }
  },

  /**
     * Request for recovery password.
     * @param {Object} personData - The person data to update.
     * @returns {Promise<Object>} - The updated person data.
     */
  requestRecoveryPassword: async (personData) => {
    try {
      const response = await api.post('/person/SetRecoveryPassword', personData);
      return response.data;
    } catch (error) {
      console.error('Error requesting to recovery password:', error);
      throw error;
    }
  },
  
  /**
     * Recovery password.
     * @param {Object} personData - The person data to update.
     * @returns {Promise<Object>} - The updated person data.
     */
  recoveryPassword: async (personData) => {
    try {
      const response = await api.put('/person/recover-password', personData);
      return response.data;
    } catch (error) {
      console.error('Error to recovery password:', error);
      throw error;
    }
  },

  /**
   * Fetch all persons with optional filters.
   * @param {Object} params - Optional query parameters.
   * @returns {Promise<Object>} - The list of persons.
   */
  getAllPersons: async (params = {}) => {
    try {
      const response = await api.get('/person', { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching persons:', error);
      throw error;
    }
  }
};

export default personApi;
