import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, } from 'react-redux';
import { putDateOnPattern } from '../../../utils/functions';
import './ShoppingCartDetailsPage.css';
import SectionWithImage from '../../../components/client/SectionWithImage/SectionWithImage';
import { setLoading } from '../../../services/redux/loadingSlice';
import shoppingCartApi from '../../../services/apiService/shoppingCartApi';
import HrefHistory from '../../../components/client/HrefHistory/HrefHistory';

const ShoppingCartDetailsPage = () => {
    const dispatch = useDispatch();
    const { code } = useParams();
    const [cart, setCart] = useState(null);

    useEffect(() => {
        const fetchCartDetails = async () => {
            try {
                dispatch(setLoading(true));
                const response = await shoppingCartApi.getShoppingCartByCode(
                    code,
                    'Person,Seller,ShoppingCartItem.Product,ShoppingCartItem.Product.Company,ShoppingCartItem.Product.Group,ShoppingCartItem.Product.Brand,ShoppingCartItem.Product.Model,ShoppingCartItem.Product.Model.ModelImage,ShoppingCartItem.Product.ProductImage'
                );
                setCart(response);
            } catch (error) {
                toast.error('Erro ao carregar os detalhes do orçamento.');
            } finally {
                dispatch(setLoading(false));
            }
        };

        fetchCartDetails();
    }, [code, dispatch]);

    if (!cart) {
        return <p>Loading...</p>;
    }

    const { Person, Seller, ShoppingCartItem, Created, Updated, Status } = cart;

    return (
        <div className='model-description'>
            <SectionWithImage
                backgroundImageUrl={'https://storagealbus.blob.core.windows.net/padrao/normas-oficina-mecanica%201.png'}
                title={'Orçamento'}
            />
            <HrefHistory/>
            <div className='cart-info-container'>
                <div className="cart-info div-with-border">
                    <h2>Informações do Cliente</h2>
                    <p><strong>Nome:</strong> {Person?.Name}</p>
                    <p><strong>Email:</strong> {Person?.Email}</p>
                    <p><strong>Celular:</strong> {Person?.Mobile}</p>
                    <p><strong>Documento:</strong> {Person?.PhysicalDocument || Person?.LegalDocument}</p>
                </div>

                <div>
                    <div className="cart-info div-with-border">
                        <h2>Informações do Vendedor</h2>
                        <p><strong>Nome:</strong> {Seller?.Name}</p>
                        <p><strong>Email:</strong> {Seller?.Email}</p>
                        <p><strong>Celular:</strong> {Seller?.Mobile}</p>
                    </div>
                </div>

                <div className="cart-info div-with-border">
                    <h2>Itens do Orçamento</h2>
                    <div className="cart-items-list">
                        {ShoppingCartItem.map((item, index) => (
                            <div key={item.Code} className="cart-item">
                                <h3>Item {index + 1}</h3>
                                <div className="item-details">
                                    <div className="image-container">
                                        {item.Product.ProductImage && item.Product.ProductImage.length > 0 ? (
                                            <img src={item.Product.ProductImage[0].Url} alt={item.Product.Description} className="product-image" />
                                        ) : item.Product.Model && item.Product.Model.ModelImage && item.Product.Model.ModelImage.length > 0 ? (
                                            <img src={item.Product.Model.ModelImage[0].Url} alt={item.Product.Description} className="product-image" />
                                        )
                                        : (
                                            <p>Sem imagem disponível</p>
                                        )}
                                    </div>
                                    <div className="info-container">
                                        <p><strong>Produto:</strong> {item.Product.Description}</p>
                                        <p><strong>Quantidade:</strong> {item.Quantity}</p>
                                        <p><strong>Preço Público:</strong> R$ {item.Product.PublicPrice}</p>
                                        <p><strong>Marca:</strong> {item.Product.Brand?.Name}</p>
                                        <p><strong>Grupo:</strong> {item.Product.Group?.Description}</p>
                                        <p><strong>Empresa:</strong> {item.Product.Company?.Description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="cart-info div-with-border margin-top-default">
                    <h3>Informações do Orçamento</h3>
                    <p><strong>Status:</strong> <span className={Status === 'OPEN' ? 'item-open' : (Status === 'IN PROGRESS' ? 'item-inactive' : 'item-active')}>{Status === 'OPEN' ? 'Aberto' : (Status === 'IN PROGRESS' ? 'Em atendimento' : (Status === 'CANCEL' ? 'Cancelado' : 'Concluído'))}</span></p>
                    <p><strong>Data de Criação:</strong> {putDateOnPattern(Created)}</p>
                    <p><strong>Data de Atualização:</strong> {putDateOnPattern(Updated)}</p>
                </div>
            </div>
        </div>
    );
};

export default ShoppingCartDetailsPage;
