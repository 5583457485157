import React from 'react';
import './ShoppingCartCreatePage.css';
import ProductList from '../../../components/admin/ProductList/ProductList';

const ShoppingCartCreatePage = () => {
    return (
        <div className="container-admin-page">
            <ProductList isResum={true}/>
        </div>
    )
};

export default ShoppingCartCreatePage;
