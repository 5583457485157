// validationUtils.js
export const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

export const isValidCPF = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, ''); // Remove any non-digit characters

  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;

  let sum = 0;
  for (let i = 0; i < 9; i++) {
      sum += parseInt(cpf.charAt(i)) * (10 - i);
  }

  let firstCheckDigit = (sum * 10) % 11;
  firstCheckDigit = firstCheckDigit === 10 ? 0 : firstCheckDigit;

  if (parseInt(cpf.charAt(9)) !== firstCheckDigit) return false;

  sum = 0;
  for (let i = 0; i < 10; i++) {
      sum += parseInt(cpf.charAt(i)) * (11 - i);
  }

  let secondCheckDigit = (sum * 10) % 11;
  secondCheckDigit = secondCheckDigit === 10 ? 0 : secondCheckDigit;

  return parseInt(cpf.charAt(10)) === secondCheckDigit;
};

export const isValidCNPJ = (cnpj) => {
  // Remove caracteres não numéricos
  cnpj = cnpj.replace(/[^\d]+/g, '');

  // Verifica se o CNPJ tem 14 dígitos ou é uma sequência repetida
  if (cnpj.length !== 14 || /^(\d)\1+$/.test(cnpj)) return false;

  // Função para calcular um dígito verificador
  const calculateDigit = (cnpj, multipliers) => {
    let sum = 0;

    for (let i = 0; i < multipliers.length; i++) {
      sum += parseInt(cnpj.charAt(i)) * multipliers[i];
    }

    const remainder = sum % 11;
    return remainder < 2 ? 0 : 11 - remainder;
  };

  // Multiplicadores para os dígitos
  const firstMultipliers = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  const secondMultipliers = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

  // Calcula os dígitos verificadores
  const firstDigit = calculateDigit(cnpj, firstMultipliers);
  const secondDigit = calculateDigit(cnpj, secondMultipliers);

  // Verifica se os dígitos calculados são iguais aos informados
  return (
    firstDigit === parseInt(cnpj.charAt(12)) &&
    secondDigit === parseInt(cnpj.charAt(13))
  );
};


export const validateRegistrationStep1 = (formData, isPhysical) => {
  const errors = {};
  
  if (!formData.name) errors.name = 'Nome é obrigatório.';
  if (isPhysical && !isValidCPF(formData.physicalDocument)) {
    errors.physicalDocument = 'CPF inválido.';
  } else if (!isPhysical && !isValidCNPJ(formData.legalDocument)) {
    errors.legalDocument = 'CNPJ inválido.';
  }
  if (!formData.mobile) errors.mobile = 'Celular é obrigatório.';
  if (!isValidEmail(formData.email)) errors.email = 'Email inválido.';
  if (!formData.password) errors.password = 'Senha é obrigatório.';
  if (formData.password !== formData.passwordVerify) errors.passwordVerify = 'As senhas não coincidem.';

  return errors;
};
