import React, { useState, useEffect } from 'react';
import './DashboardPage.css';
import dashboardApi from '../../../services/apiService/dashboard';
import DashboardPieChart from '../../../components/admin/DashboardPieChart/DashboardPieChart';
import DashboardBarChart from '../../../components/admin/DashboardBarChart/DashboardBarChart';

const DashboardPage = () => {
  const [metrics, setMetrics] = useState({});
  const [bestSellers, setBestSellers] = useState([]);
  const [productBestSellers, setProductBestSellers] = useState([]);
  const [salesPerDay, setSalesPerDay] = useState([]);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const metricsData = await dashboardApi.getMetrics();
        setMetrics(metricsData);
      } catch (error) {
        console.error("Erro ao buscar métricas:", error);
      }
    };

    const fetchBestSellers = async () => {
      try {
        const bestSellersData = await dashboardApi.getBestSellers();
        setBestSellers(bestSellersData);
      } catch (error) {
        console.error("Erro ao buscar melhores vendedores:", error);
      }
    };

    const fetchProductBestSellers = async () => {
      try {
        const productBestSellersData = await dashboardApi.getProductBestSeller();
        setProductBestSellers(productBestSellersData);
      } catch (error) {
        console.error("Erro ao buscar produtos mais vendidos:", error);
      }
    };

    const fetchSalesPerDay = async () => {
      try {
        const salesPerDayData = await dashboardApi.getSellPerDay();
        setSalesPerDay(salesPerDayData);
      } catch (error) {
        console.error("Erro ao buscar vendas por dia:", error);
      }
    };

    fetchMetrics();
    fetchBestSellers();
    fetchProductBestSellers();
    fetchSalesPerDay();
  }, []);

  const totalClients = metrics.QuantityClientsTotal || 0;
  const recentClients = metrics.QuantityClientsLast30Days || 0;
  const otherClients = totalClients - recentClients;
  const totalShoppingCart = metrics.QuantityShoppingParkTotal || 0;
  const recentShoppingCart = metrics.QuantityShoppingParkLast30Days || 0;
  const otherShoppingCart = totalShoppingCart - recentShoppingCart;

  return (
    <div className="container-admin-page">
      <h1>Dashboard</h1>

      <div className="dashboard-metrics div-with-border">
        <h2>Métricas</h2>
        <div className='dashboard-first-info'>
          <p>Total de Clientes: <strong>{metrics.QuantityClientsTotal}</strong></p>
          <p>Total de Orçamentos: <strong>{metrics.QuantityShoppingParkTotal}</strong></p>
          <p>Total de Produtos: <strong>{metrics.QuantityProducts}</strong></p>
          <p>Orçamentos Abertos: <strong>{metrics.QuantityShoppingCartOpen}</strong></p>
          <p>Orçamentos em Andamento: <strong>{metrics.QuantityShoppingCartInProgress}</strong></p>
          <p>Orçamentos Concluídos: <strong>{metrics.QuantityShoppingCartComplete}</strong></p>
          <p>Modelos Ativas: <strong>{metrics.QuantityModels}</strong></p>
          <p>Grupos Ativos: <strong>{metrics.QuantityGroups}</strong></p>
          <p>Empresas Ativas: <strong>{metrics.QuantityCompany}</strong></p>
        </div>
      </div>

      <div className="dashboard-metrics div-with-border dashboard-charts-info">
        <DashboardPieChart
          title="Clientes"
          total={totalClients}
          data={[
            { id: 'Clientes Recentes (30 Dias)', value: recentClients, color: 'var(--primary-color)' },
            { id: 'Demais Clientes', value: otherClients, color: 'var(--button-color-primary-hover)' },
          ]}
          legendItems={[
            { className: 'dashboard-legend-recents', label: 'Clientes Recentes (30 Dias)', value: recentClients },
            { className: 'dashboard-legend-old', label: 'Demais Clientes', value: otherClients },
          ]}
        />
        <DashboardPieChart
          title="Orçamentos"
          total={totalShoppingCart}
          data={[
            { id: 'Orçamentos Recentes (30 Dias)', value: recentShoppingCart, color: 'var(--primary-color)' },
            { id: 'Demais Orçamentos', value: otherShoppingCart, color: 'var(--button-color-primary-hover)' },
          ]}
          legendItems={[
            { className: 'dashboard-legend-recents', label: 'Orçamentos Recentes (30 Dias)', value: recentShoppingCart },
            { className: 'dashboard-legend-old', label: 'Demais Orçamentos', value: otherShoppingCart },
          ]}
        />
      </div>

      <div className="dashboard-metrics div-with-border">
        <DashboardBarChart
          title="Produtos Mais Vendidos"
          data={productBestSellers.map((product) => ({
            label: product.Description,
            value: product.Total,
          }))}
          yAxisLabel="Quantidade de produtos"
        />
      </div>

      <div className="dashboard-metrics div-with-border">
        <DashboardBarChart
          title="Melhores Vendedores"
          data={bestSellers.map((product) => ({
            label: product.Description,
            value: product.Total,
          }))}
          yAxisLabel="Quantidade de Orçamentos"
        />
      </div>

      <div className="dashboard-metrics div-with-border">
        <DashboardBarChart
          title="Orçamentos por Dia (Últimos 30 Dias)"
          data={salesPerDay.map((product) => ({
            label: product.Description,
            value: product.Total,
          }))}
          yAxisLabel="Quantidade de Orçamentos"
        />
      </div>
    </div>
  );
};

export default DashboardPage;
