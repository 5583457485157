import React from 'react';

const SettingsIcon = ({ size = 24, color = 'white' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4167 36.6667L14.75 31.3333C14.3889 31.1945 14.0486 31.0278 13.7292 30.8333C13.4097 30.6389 13.0972 30.4306 12.7917 30.2083L7.83333 32.2917L3.25 24.375L7.54167 21.125C7.51389 20.9306 7.5 20.7431 7.5 20.5625V19.4375C7.5 19.257 7.51389 19.0695 7.54167 18.875L3.25 15.625L7.83333 7.70834L12.7917 9.79168C13.0972 9.56945 13.4167 9.36112 13.75 9.16668C14.0833 8.97223 14.4167 8.80557 14.75 8.66668L15.4167 3.33334H24.5833L25.25 8.66668C25.6111 8.80557 25.9514 8.97223 26.2708 9.16668C26.5903 9.36112 26.9028 9.56945 27.2083 9.79168L32.1667 7.70834L36.75 15.625L32.4583 18.875C32.4861 19.0695 32.5 19.257 32.5 19.4375V20.5625C32.5 20.7431 32.4722 20.9306 32.4167 21.125L36.7083 24.375L32.125 32.2917L27.2083 30.2083C26.9028 30.4306 26.5833 30.6389 26.25 30.8333C25.9167 31.0278 25.5833 31.1945 25.25 31.3333L24.5833 36.6667H15.4167ZM20.0833 25.8333C21.6944 25.8333 23.0694 25.2639 24.2083 24.125C25.3472 22.9861 25.9167 21.6111 25.9167 20C25.9167 18.3889 25.3472 17.0139 24.2083 15.875C23.0694 14.7361 21.6944 14.1667 20.0833 14.1667C18.4444 14.1667 17.0625 14.7361 15.9375 15.875C14.8125 17.0139 14.25 18.3889 14.25 20C14.25 21.6111 14.8125 22.9861 15.9375 24.125C17.0625 25.2639 18.4444 25.8333 20.0833 25.8333Z"
      fill={color}
    />
  </svg>
);

export default SettingsIcon;
