import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearCart, selectAllCartItems, removeItem } from '../../../services/redux/shoppingCartSlice';
import { openCloseLogin } from '../../../services/redux/loginSlice';
import MessageModal from '../../../components/modals/MessageModal/MessageModal';
import './CartPage.css';
import SectionWithImage from '../../../components/client/SectionWithImage/SectionWithImage';
import { setLoading } from '../../../services/redux/loadingSlice';
import shoppingCartApi from '../../../services/apiService/shoppingCartApi';
import HrefHistory from '../../../components/client/HrefHistory/HrefHistory';

const CartPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const initialCartItems = useSelector(selectAllCartItems).map(item => ({ ...item, Quantity: item.Quantity || 0 }));
    const [cartItems, setCartItems] = useState(initialCartItems);
    const [isMessageOpen, setIsMessageOpen] = useState(false);
    const { isAuthenticated } = useSelector((state) => state.auth);

    const handleQuantityChange = (code, change) => {
        setCartItems(prevItems =>
            prevItems.map(item =>
                item.Code === code ? { ...item, Quantity: Math.max(0, item.Quantity + change) } : item
            )
        );
    };

    const handleRemoveItem = (code) => {
        dispatch(removeItem(code));
        setCartItems(prevItems => prevItems.filter(item => item.Code !== code));
    };

    const handleCheckout = async () => {
        if(isAuthenticated){
            dispatch(setLoading(true));
            try {
                await shoppingCartApi.createShoppingCart(createBodyToUpload());
                toast.success('Orçamento criado com sucesso!');
                dispatch(clearCart());
                navigate('/orcamentos');
            } catch (error) {
                toast.error('Erro ao criar orçamento: ' + error);
            } finally {
                dispatch(setLoading(false));
            }
        }
        else{
            setIsMessageOpen(true);
        }
    };

    const createBodyToUpload = () => ({
        status: 'OPEN',
        shoppingCartItem: cartItems.map(item => ({
            productCode: item.Code,
            quantity: item.Quantity,
        })),
    });

    const openLogin = () => {
        setIsMessageOpen(false);
        dispatch(openCloseLogin(true));
    }

    return (
        <div className='model-description'>
            <MessageModal isOpen={isMessageOpen} message={'Você precisa estar logado para fechar o carrinho!'} click={openLogin} optionText={'Logar'} />
            <SectionWithImage
                backgroundImageUrl={'https://storagealbus.blob.core.windows.net/padrao/normas-oficina-mecanica%201.png'}
                title={'Carrinho'}
            />
            <HrefHistory/>
            <div className='model-description-container'>
                <div className="cart-page-container">
                    <div className="cart-items-header">
                        <span>Produto</span>
                        <span>Quantidade</span>
                    </div>
                    <div className="cart-items-container">
                        {cartItems.map((item, index) => (
                            <div key={item.Code} className="cart-item">
                                <div className="item-image">
                                    {item.ProductImage?.length > 0 ? (
                                        <img src={item.ProductImage[0].Url} alt={item.Description} />
                                    ) : 
                                    item.Model?.ModelImage?.length > 0 ?(
                                        <img src={item.Model?.ModelImage[0].Url} alt={item.Description} />
                                    )
                                    :
                                    (
                                        <div className="no-image-placeholder" />
                                    )}
                                    <p className="item-name">{item.Model.Name} - {item.Description}</p>
                                </div>
                                <div className="item-quantity">
                                    <button className='clickable button-options-itens brighten-on-hover' onClick={() => handleQuantityChange(item.Code, -1)}>-</button>
                                    <input type="text" value={item.Quantity} readOnly />
                                    <button className='clickable button-options-itens brighten-on-hover' onClick={() => handleQuantityChange(item.Code, 1)}>+</button>
                                    <button className="clickable button-options-itens brighten-on-hover" onClick={() => handleRemoveItem(item.Code)}>x</button>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="cart-actions">
                        <button className="main-button" onClick={() => navigate('/products')}>+ Continuar adicionando</button>
                        <button className="clickable item-active brighten-on-hover" onClick={handleCheckout}>Fechar orçamento</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CartPage;
