import './ProductPage.css';
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import productApi from '../../../services/apiService/productApi';
import productImageApi from '../../../services/apiService/productImageApi';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../services/redux/loadingSlice';
import AddIcon from '../../../components/icons/AddIcon/AddIcon';
import EditIcon from '../../../components/icons/EditIcon/EditIcon';
import ProductRegisterModal from '../../../components/modals/ProductRegisterModal/ProductRegisterModal';

const ProductPage = () => {
    const dispatch = useDispatch();
    const { code } = useParams();
    const [product, setProduct] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [isProductRegisterOpen, setIsProductRegisterOpen] = useState(false);
    const fileInputRef = useRef(null);

    const openRegisterProduct = () => {
        setIsProductRegisterOpen(true);
    };

    const closeRegisterProduct = () => {
        setIsProductRegisterOpen(false);
    };

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                dispatch(setLoading(true));
                const response = await productApi.getProductByCode(code, 'Company,Group,Brand,Model,Model.ModelImage,ProductImage,Category');
                setProduct(response);
            } catch (error) {
                toast.error('Erro ao carregar o produto!');
            } finally {
                dispatch(setLoading(false));
            }
        };

        fetchProduct();
    }, [code, refresh, dispatch]);

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);

        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = async () => {
                try {
                    dispatch(setLoading(true));
                    const base64String = reader.result;
                    await productImageApi.createProduct({
                        ParentCode: product.Code,
                        Base64: base64String,
                    });
                    toast.success('Imagem adicionada com sucesso!');
                    setRefresh((prev) => !prev);
                } catch (error) {
                    toast.error('Erro ao adicionar imagem: ' + error.message);
                } finally {
                    dispatch(setLoading(false));
                }
            };
            reader.readAsDataURL(file);
        });

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleAddImageClick = () => {
        if (product.ProductImage && product.ProductImage.length === 5) {
            toast.error('Produto não pode conter mais que 5 imagens!');
        } else {
            fileInputRef.current.click();
        }
    };

    const handleImageDelete = async (code) => {
        try {
          dispatch(setLoading(true));
          var json = {
            Code: code
          }
          await productImageApi.deleteProduct(json);
          toast.success('Excluído com sucesso!');
          setRefresh((prev) => !prev);
        } catch (error) {
          toast.error('Houve erro ao excluir: ' + error.message);
        } finally {
          dispatch(setLoading(false));
        }
    };

    const updateProduct = async (productData) => {
        try {
            dispatch(setLoading(true));
            await productApi.updateProduct(productData);
            toast.success('Produto atualizado com sucesso!');
            setRefresh((prev) => !prev);
        } catch (error) {
            toast.error('Erro ao atualizar o produto: ' + error.message);
        } finally {
            dispatch(setLoading(false));
        }
    };

    if (!product) {
        return <p>Loading...</p>;
    }

    return (
        <div className="container-admin-page">
            <ProductRegisterModal isOpen={isProductRegisterOpen} onClose={closeRegisterProduct} onSubmit={updateProduct} codeProduct={product.Code} />
            <h1>Produto: {product.Description}</h1>

            <div className="product-info div-with-border">
                <div className='product-images-options'>
                    <h2>Informações do Produto:</h2>
                    <div className='clickable' onClick={openRegisterProduct}>
                        <EditIcon />
                    </div>
                </div>
                <p><strong>Código Público:</strong> {product.CodePub}</p>
                <p><strong>Código Interno:</strong> {product.InternCode}</p>
                <p><strong>Preço Público:</strong> {product.PublicPrice}</p>
                <p><strong>Unidade:</strong> {product.Unit}</p>
                <p><strong>Descrição Específica:</strong></p>
                <p>{product?.Observation?.split('\n').map((line, index) => (
                    <span key={index}>{line}<br /></span>
                ))}
                </p>
                <p><strong>Marca:</strong> {product.Brand ? product.Brand.Description : 'Não especificada'}</p>
                <p><strong>Grupo:</strong> {product.Group ? product.Group.GroupId + ' - ' + product.Group.Description : 'Não especificado'}</p>
                <p><strong>Empresa:</strong> {product.Company ? product.Company.CompanyId + ' - ' + product.Company.Description : 'Não especificada'}</p>
                <p><strong>Modelo:</strong> {product.Model ? product.Model.Name : 'Não especificada'}</p>
                <p><strong>Categoria:</strong> {product.Category ? product.Category.Description : 'Não especificada'}</p>
                <p><strong>Descrição do Modelo:</strong></p>
                <p className="product-description">
                    {product.Model?.Description?.split('\n').map((line, index) => (
                        <span key={index}>{line}<br /></span>
                    ))}
                </p>
            </div>

            <div className="product-info div-with-border margin-top-default">
                {product.ProductImage && product.ProductImage.length >= 0 && (
                    <div className="product-images-section">
                        <div className='product-images-options'>
                            <h3>Imagens ({product.ProductImage.length}):</h3>
                            <div className='clickable' onClick={handleAddImageClick}>
                                <AddIcon />
                            </div>
                        </div>
                        <input
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={handleFileChange}
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                        />
                        <div className='product-images-list'>
                            {product.ProductImage.map((image, index) => (
                                <div className="image-item" key={image.Code}>
                                    <div className='image-preview'>
                                        <img src={image.Url} alt={`${product.Description} - Imagem ${index + 1}`} />
                                        <button type="button" onClick={() => handleImageDelete(image.Code)}>
                                            Delete
                                        </button>
                                    </div>
                                    <span>Imagem {index + 1}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProductPage;
