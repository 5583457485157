import React from 'react';
import './ChangePasswordModal.css'; // Optional CSS for modal styles
import EditPassword from '../../../pages/common/EditPassword/EditPassword';

const ChangePasswordModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal-container-edit">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <EditPassword onClose={onClose} />
      </div>
    </div>
  );
};

export default ChangePasswordModal;
