import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ClientRoutes from './routes/ClientRoutes';
import AdminRoutes from './routes/AdminRoutes';
import SellerRoutes from './routes/SellerRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingModal from './components/modals/LoadingModal/LoadingModal';

function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const isSeller = useSelector((state) => state.auth.isSeller);
  const isLoading = useSelector((state) => state.loading.isLoading);

  return (
    <Router>
      <div className="App">
        <ToastContainer autoClose={2000} />
        {isLoading && <LoadingModal />}

        <Routes>
          {
            !isAuthenticated || (!isAdmin && !isSeller) ? 
            <Route path="/*" element={<ClientRoutes />} />
          : 
          isAdmin ?
            <Route path="/*" element={<AdminRoutes />} />
          :
          isSeller ?
            <Route path="/*" element={<SellerRoutes />} />
          :
            <Route path="/*" element={<ClientRoutes />} />
          }
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
