import React, { useState } from 'react';
import { maskCPF, maskCNPJ, maskPhone } from '../../../masks/masks';

const PersonalInfoEditForm = ({ formData, onSubmit, onClose }) => {
  const [name, setName] = useState(formData?.Name || '');
  const [phone, setPhone] = useState(formData?.Phone1 || '');
  const [mobile, setMobile] = useState(formData?.Mobile || '');
  const [document, setDocument] = useState(formData?.PhysicalDocument || formData?.LegalDocument || '');

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedData = { code: formData.Code, name, phone, mobile, document, isPhysical: formData?.IsPhysical === 1};
    onSubmit(updatedData);
  };

  return (
    <form onSubmit={handleSubmit} className='register-form'>
        <div className="personal-info-register">
            <h2>Atualização dos dados pessoais</h2>
            <div className='space-triple-bottom modal-edit-user'>
                <label>Nome</label>
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className='space-triple-bottom modal-edit-user'>
                <label>Telefone</label>
                <input type="text" value={maskPhone(phone)} onChange={(e) => setPhone(e.target.value)} />
            </div>
            <div className='space-triple-bottom modal-edit-user'>
                <label>Celular</label>
                <input type="text" value={maskPhone(mobile)} onChange={(e) => setMobile(e.target.value)} />
            </div>
            <div className='space-triple-bottom modal-edit-user'>
                <label>{formData?.IsPhysical === 1 ? 'CPF' : 'CNPJ'}</label>
                <input type="text" value={formData?.IsPhysical === 1 ? maskCPF(document) : maskCNPJ(document)} onChange={(e) => setDocument(e.target.value)} />
            </div>
            <div className='space-component'>
                <button className='main-button' onClick={onClose}>Cancelar</button>
                <button className='main-button' type="submit">Salvar</button>
            </div>
        </div>
    </form>
  );
};

export default PersonalInfoEditForm;
