import './ProductRegisterModal.css';
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../services/redux/loadingSlice';
import productApi from '../../../services/apiService/productApi';
import brandApi from '../../../services/apiService/brandApi';
import groupApi from '../../../services/apiService/groupApi';
import companyApi from '../../../services/apiService/companyApi';
import categoryApi from '../../../services/apiService/categoryApi';
import AddIcon from '../../icons/AddIcon/AddIcon';
import ModelRegisterModal from '../ModelRegisterModal/ModelRegisterModal';
import DescriptionRegisterModal from '../DescriptionRegisterModal/DescriptionRegisterModal';
import { toast } from 'react-toastify';
import modelApi from '../../../services/apiService/modelApi';

const ProductRegisterModal = ({ isOpen, onClose, onSubmit, codeProduct = null }) => {
    const dispatch = useDispatch();
    const [product, setProduct] = useState({
        InternCode: '',
        CodePub: '',
        Description: '',
        CompanyCode: '',
        GroupCode: '',
        BrandCode: '',
        ModelCode: '',
        PublicPrice: '',
        Unit: '',
        CategoryCode: '',
        Observation: '',
        ProductImages: [],
    });
    const [brandList, setBrandList] = useState([]);
    const [modelList, setModelList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const fileInputRef = useRef(null);
    const [isBrandRegisterOpen, setIsBrandRegisterOpen] = useState(false);
    const [isModelRegisterOpen, setIsModelRegisterOpen] = useState(false);
    const [isGroupRegisterOpen, setIsGroupRegisterOpen] = useState(false);
    const [isCompanyRegisterOpen, setIsCompanyRegisterOpen] = useState(false);
    const [isCategoryRegisterOpen, setIsCategoryRegisterOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const openRegisterBrand = () => {
        setIsBrandRegisterOpen(true);
    }

    const closeRegisterBrand = () => {
        setIsBrandRegisterOpen(false);
    }

    const openRegisterModel = () => {
        setIsModelRegisterOpen(true);
    }

    const closeRegisterModel = () => {
        setIsModelRegisterOpen(false);
    }

    const openRegisterGroup = () => {
        setIsGroupRegisterOpen(true);
    }

    const closeRegisterGroup = () => {
        setIsGroupRegisterOpen(false);
    }

    const openRegisterCompany = () => {
        setIsCompanyRegisterOpen(true);
    }

    const closeRegisterCompany = () => {
        setIsCompanyRegisterOpen(false);
    }

    const openRegisterCategory = () => {
        setIsCategoryRegisterOpen(true);
    }

    const closeRegisterCategory = () => {
        setIsCategoryRegisterOpen(false);
    }

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await productApi.getProductByCode(codeProduct);
                setProduct(response);
            } catch (error) {
                toast.error('Erro ao carregar o produto!');
            }
        };

        const fetchBrands = async () => {
            try {
                const response = await brandApi.getBrands();
                setBrandList(response);
            } catch (error) {
                toast.error('Erro ao carregar as marcas!');
            }
        };

        const fetchModel = async () => {
            try {
                const response = await modelApi.getModels('ModelImage');
                setModelList(response);
            } catch (error) {
                toast.error('Erro ao carregar os modelos!');
            }
        };

        const fetchGroups = async () => {
            try {
                const response = await groupApi.getGroups();
                setGroupList(response);
            } catch (error) {
                toast.error('Erro ao carregar os grupos!');
            }
        };

        const fetchCompanies = async () => {
            try {
                const response = await companyApi.getCompanies();
                setCompanyList(response);
            } catch (error) {
                toast.error('Erro ao carregar as empresas!');
            }
        };

        const fetchCategories = async () => {
            try {
                const response = await categoryApi.getCategorys();
                setCategoryList(response);
            } catch (error) {
                toast.error('Erro ao carregar as categorias!');
            }
        };

        const fetchData = async () => {
            try {
                dispatch(setLoading(true));
                if (codeProduct) {
                    await fetchProduct();
                }
                await fetchBrands();
                await fetchGroups();
                await fetchCompanies();
                await fetchModel();
                await fetchCategories();
            } finally {
                dispatch(setLoading(false));
            }
        };

        fetchData();
    }, [codeProduct, refresh, dispatch]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProduct((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        const newImages = [];

        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result;
                newImages.push({ base64: base64String });
                if (newImages.length === files.length) {
                    setProduct((prevState) => ({
                        ...prevState,
                        ProductImages: [...prevState.ProductImages, ...newImages],
                    }));
                }
            };
            reader.readAsDataURL(file);
        });

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleImageDelete = (index) => {
        setProduct((prevState) => ({
            ...prevState,
            ProductImages: prevState.ProductImages.filter((_, i) => i !== index),
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(product);
        clear();
        onClose();
    };

    const clear = () => {
        setProduct({
            InternCode: '',
            CodePub: '',
            Description: '',
            CompanyCode: '',
            GroupCode: '',
            BrandCode: '',
            ModelCode: '',
            PublicPrice: '',
            Unit: '',
            CategoryCode: '',
            Observation: '',
            ProductImages: [],
        });
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const createBrand = async (brand) =>{
        try{
            dispatch(setLoading(true));
            var response = await brandApi.createBrand(brand);

            toast.success(response.Description + ' criado com sucesso!');
            setRefresh((prev) => !prev);
        }
        catch(error){
            toast.error('Error :' + error);
        }
        finally{
            dispatch(setLoading(false));
        }
    }

    const createModel = async (model) =>{
        try{
            dispatch(setLoading(true));
            var response = await modelApi.createModel(model);

            toast.success(response.Name + ' criado com sucesso!');
            setRefresh((prev) => !prev);
        }
        catch(error){
            toast.error('Error :' + error);
        }
        finally{
            dispatch(setLoading(false));
        }
    }

    const createGroup = async (group) =>{
        try{
            dispatch(setLoading(true));
            var response = await groupApi.createGroup(group);

            toast.success(response.Description + ' criado com sucesso!');
            setRefresh((prev) => !prev);
        }
        catch(error){
            toast.error('Error :' + error);
        }
        finally{
            dispatch(setLoading(false));
        }
    }

    const createCompany = async (company) =>{
        try{
            dispatch(setLoading(true));
            var response = await companyApi.createCompany(company);

            toast.success(response.Description + ' criado com sucesso!');
            setRefresh((prev) => !prev);
        }
        catch(error){
            toast.error('Error :' + error);
        }
        finally{
            dispatch(setLoading(false));
        }
    }

    const createCategory = async (category) =>{
        try{
            dispatch(setLoading(true));
            var response = await categoryApi.createCategory(category);

            toast.success(response.Description + ' criado com sucesso!');
            setRefresh((prev) => !prev);
        }
        catch(error){
            toast.error('Error :' + error);
        }
        finally{
            dispatch(setLoading(false));
        }
    }

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop-register">
            <ModelRegisterModal isOpen={isModelRegisterOpen} onClose={closeRegisterModel} onSubmit={createModel} />
            <DescriptionRegisterModal isOpen={isBrandRegisterOpen} onClose={closeRegisterBrand} onSubmit={createBrand} />
            <DescriptionRegisterModal isOpen={isGroupRegisterOpen} onClose={closeRegisterGroup} onSubmit={createGroup} showId={true}/>
            <DescriptionRegisterModal isOpen={isCompanyRegisterOpen} onClose={closeRegisterCompany} onSubmit={createCompany} showId={true}/>
            <DescriptionRegisterModal isOpen={isCategoryRegisterOpen} onClose={closeRegisterCategory} onSubmit={createCategory} />
            <div className="modal-container-register">
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                <form onSubmit={handleSubmit} className="register-form">
                    <div className="form-group-register form-group-inside">
                        <label>Descrição do Produto:</label>
                        <input
                            type="text"
                            name="Description"
                            value={product.Description}
                            onChange={handleInputChange}
                            required
                        />
                    </div>

                    <div className='form-group-register'>
                        <div className="form-group-register form-group-inside">
                            <div className='add-option-by-side'>
                                <label>
                                    Modelo:
                                </label>
                                <div className='add-option-by-side-icon clickable' onClick={openRegisterModel}>
                                    <AddIcon color='white'/>
                                </div>
                            </div>

                            <select
                                name="ModelCode"
                                value={product.ModelCode}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Selecione o Modelo</option>
                                {modelList.map((modelo) => (
                                    <option key={modelo.Code} value={modelo.Code}>
                                        {modelo.Name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group-register form-group-inside">
                            <div className='add-option-by-side'>
                                <label>
                                    Grupo:
                                </label>
                                <div className='add-option-by-side-icon clickable' onClick={openRegisterGroup}>
                                    <AddIcon color='white'/>
                                </div>
                            </div>
                            <select
                                name="GroupCode"
                                value={product.GroupCode}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Selecione o Grupo</option>
                                {groupList.map((group) => (
                                    <option key={group.Code} value={group.Code}>
                                        {group.GroupId}-{group.Description}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group-register form-group-inside">
                            <div className='add-option-by-side'>
                                <label>
                                    Empresa:
                                </label>
                                <div className='add-option-by-side-icon clickable' onClick={openRegisterCompany}>
                                    <AddIcon color='white'/>
                                </div>
                            </div>
                            <select
                                name="CompanyCode"
                                value={product.CompanyCode}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Selecione a Empresa</option>
                                {companyList.map((company) => (
                                    <option key={company.Code} value={company.Code}>
                                        {company.CompanyId} - {company.Description}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className='form-group-register'>
                        <div className='form-group-register form-group-inside'>
                            <label>Código Público:</label>
                            <input
                                type="text"
                                name="CodePub"
                                value={product.CodePub}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className='form-group-register form-group-inside'>
                            <label>Código Interno:</label>
                            <input
                                type="text"
                                name="InternCode"
                                value={product.InternCode}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group-register form-group-inside">
                            <div className='add-option-by-side'>
                                <label>
                                    Marca:
                                </label>
                                <div className='add-option-by-side-icon clickable' onClick={openRegisterBrand}>
                                    <AddIcon color='white'/>
                                </div>
                            </div>
                            <select
                                className='brand-select'
                                name="BrandCode"
                                value={product.BrandCode}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Selecione a Marca</option>
                                {brandList.map((brand) => (
                                    <option key={brand.Code} value={brand.Code}>
                                        {brand.Description}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className='form-group-register'>
                        <div className="form-group-register form-group-inside">
                            <div className='add-option-by-side'>
                                <label>
                                    Categoria:
                                </label>
                                <div className='add-option-by-side-icon clickable' onClick={openRegisterCategory}>
                                    <AddIcon color='white'/>
                                </div>
                            </div>
                            <select
                                name="CategoryCode"
                                value={product.CategoryCode}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Selecione a Categoria</option>
                                {categoryList.map((category) => (
                                    <option key={category.Code} value={category.Code}>
                                        {category.Description}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='form-group-register form-group-inside'>
                            <label>Unidade:</label>
                            <input
                                type="text"
                                name="Unit"
                                value={product.Unit}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className='form-group-register form-group-inside'>
                            <label>Preço Público:</label>
                            <input
                                type="text"
                                name="PublicPrice"
                                value={product.PublicPrice}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className='form-group-register form-group-inside'>
                        <label>
                            Descrição Específica:
                        </label>
                        <textarea
                            name="Observation"
                            value={product.Observation}
                            onChange={handleInputChange}
                            required
                            rows="8"
                        />
                    </div>

                    {
                        !codeProduct && 
                        <>
                            <div className="form-group-register form-group-inside">
                                <div className='add-image-option'>
                                    <label>
                                        Imagens do Produto:
                                    </label>
                                    <label className='main-button' onClick={handleButtonClick}>
                                        Adicionar
                                    </label>
                                </div>
                                <input
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    onChange={handleFileChange}
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                />
                            </div>
                            <div className="form-group-register form-group-inside">
                                <div className="image-preview-container">
                                    {product.ProductImages && product.ProductImages.map((image, index) => (
                                        <div key={index} className="image-preview">
                                            <img src={image.base64} alt={`Product ${index + 1}`} />
                                            <button type="button" onClick={() => handleImageDelete(index)}>
                                                Delete
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    }

                    <div className="form-group-register option-next-register">
                        <button className="main-button" type="button" onClick={onClose}>
                            Cancelar
                        </button>
                        <button className="main-button" type="submit">
                            Salvar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ProductRegisterModal;
