import React from 'react';
import { useParams } from 'react-router-dom';
import './PersonPage.css';
import Profile from '../../common/Profile/Profile';

const PersonPage = ({ title }) => {
  const { code } = useParams();

  return (
    <div className="container-admin-page">
        <h1>{title}</h1>
      
        <Profile code={code}/>
    </div>
  );
};

export default PersonPage;
