import React, { useState, useEffect } from 'react';
import './FilterComponent.css';
import brandApi from '../../../services/apiService/brandApi';
import modelApi from '../../../services/apiService/modelApi';
import groupApi from '../../../services/apiService/groupApi';
import companyApi from '../../../services/apiService/companyApi';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../services/redux/loadingSlice';
import { toast } from 'react-toastify';

const FilterComponent = ({ placeHolder, showTermFilter, showStartDate=false, showEndDate=false, showBrand=false, showGroup=false, showCompany=false, showModels=false, submitFilter=false, exportFunction }) => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [brandList, setBrandList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [showMoreFilter, setShowMoreFilter] = useState(false);
  const [filter, setFilter] = useState({
    CompanyCode: '',
    GroupCode: '',
    BrandCode: '',
    ModelCode: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilter((prevState) => ({
        ...prevState,
        [name]: value,
    }));
  };

  useEffect(() => {
    const fetchBrands = async () => {
        try {
            const response = await brandApi.getBrands();
            setBrandList(response);
        } catch (error) {
            toast.error('Erro ao carregar as marcas!');
        }
    };

    const fetchModels = async () => {
      try {
          const response = await modelApi.getModels({ include: "ModelImage" });
          setModelList(response);
      } catch (error) {
          toast.error('Erro ao carregar os models!');
      }
    };

    const fetchGroups = async () => {
        try {
            const response = await groupApi.getGroups();
            setGroupList(response);
        } catch (error) {
            toast.error('Erro ao carregar os grupos!');
        }
    };

    const fetchCompanies = async () => {
        try {
            const response = await companyApi.getCompanies();
            setCompanyList(response);
        } catch (error) {
            toast.error('Erro ao carregar as empresas!');
        }
    };

    const fetchData = async () => {
        try {
            dispatch(setLoading(true));
            if(showBrand)
              await fetchBrands();
            if(showGroup)
              await fetchGroups();
            if(showCompany)
              await fetchCompanies();
            if(showModels)
              await fetchModels();
        } finally {
            dispatch(setLoading(false));
        }
    };

    fetchData();
  }, [showBrand, showGroup, showCompany, showModels, dispatch]);

  const handleSubmit = () => {
    submitFilter({
      term: term || undefined,
      startDate: startDate || undefined,
      endDate: endDate || undefined,
      brand: filter.BrandCode || undefined,
      group: filter.GroupCode || undefined,
      company: filter.CompanyCode || undefined,
      model: filter.ModelCode || undefined
    });
  };

  const exportReport = () => {
    exportFunction(term || undefined, startDate || undefined, endDate || undefined);
  };

  return (
    <div className="filter-container">
      <div className='filter-container-main'>
        <div className='filter-itens'>
          <div className='filter-basic'>
            {showTermFilter && (
              <input
                type="text"
                placeholder={placeHolder}
                className="main-input"
                value={term}
                onChange={(e) => setTerm(e.target.value)}
              />
            )}
            {showStartDate && (
              <input
                type="date"
                placeholder="Data Inicial"
                className="main-input"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            )}
            {showEndDate && (
              <input
                type="date"
                placeholder="Data Final"
                className="main-input"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            )}
          </div>
        </div>

        <div className='filter-buttons'>
          <button onClick={handleSubmit} className="main-button">
            Filtrar
          </button>
          {
            exportFunction && (
              <button onClick={exportReport} className="main-button">
                Exportar
              </button>
            )
          }

          {
            showBrand || showCompany || showGroup ? 
              <button onClick={() => setShowMoreFilter(prev => !prev)} className="main-button">
                {
                  showMoreFilter ? 
                  'Menos Filtros'
                  :
                  'Mais Filtros'
                }
              </button>
              : 
              <></>
          }
        </div>
      </div>

      {
        showMoreFilter &&
        <div className='filters-more'>
          {
            showModels && 
            <div className="filters-more-item">
                <label>
                    Modelos:
                </label>

                <select
                    name="ModelCode"
                    className="main-input"
                    value={filter.ModelCode}
                    onChange={handleInputChange}
                    required
                >
                    <option value="">Selecione o Modelo</option>
                    {modelList.map((model) => (
                        <option key={model.Code} value={model.Code}>
                            {model.Name}
                        </option>
                    ))}
                </select>
            </div>
          }
          {
            showBrand && 
            <div className="filters-more-item">
                <label>
                    Marca:
                </label>

                <select
                    name="BrandCode"
                    className="main-input"
                    value={filter.BrandCode}
                    onChange={handleInputChange}
                    required
                >
                    <option value="">Selecione a Marca</option>
                    {brandList.map((brand) => (
                        <option key={brand.Code} value={brand.Code}>
                            {brand.Description}
                        </option>
                    ))}
                </select>
            </div>
          }
          {
            showGroup && 
            <div className="filters-more-item">
                <label>
                    Grupo:
                </label>
                <select
                    name="GroupCode"
                    className="main-input"
                    value={filter.GroupCode}
                    onChange={handleInputChange}
                    required
                >
                    <option value="">Selecione o Grupo</option>
                    {groupList.map((group) => (
                        <option key={group.Code} value={group.Code}>
                            {group.Description}
                        </option>
                    ))}
                </select>
            </div>
          }
          {
            showCompany &&
            <div className="filters-more-item">
                <label>
                    Empresa:
                </label>
                <select
                    name="CompanyCode"
                    className="main-input"
                    value={filter.CompanyCode}
                    onChange={handleInputChange}
                    required
                >
                    <option value="">Selecione a Empresa</option>
                    {companyList.map((company) => (
                        <option key={company.Code} value={company.Code}>
                            {company.CompanyId} - {company.Description}
                        </option>
                    ))}
                </select>
            </div>
          }
        </div>
      }
    </div>
  );
};

export default FilterComponent;
