import api from './serviceApi';  // Import the axios instance from serviceApi.js
import Config from '../../configuration/storageConfiguration';
import storageService from '../storageService';

const authApi = {
  /**
   * Sends a POST request to get the authentication token and user profile data.
   * @param {Object} credentials - An object containing username and password.
   * @returns {Promise<Object>} - Returns a promise that resolves to the full response data.
   */
  login: async (credentials) => {
    try {
      const response = await api.post('/Token', credentials); // Send POST request with credentials
      const { access_token, is_admin, is_seller, username } = response.data; // Extract data from the response

      // Store the extracted information in local storage
      storageService.setItem(Config.TOKEN, access_token);
      storageService.setItem(Config.ADMIN, is_admin);
      storageService.setItem(Config.SELLER, is_seller);
      storageService.setItem(Config.NAME, username);

      // Return the complete response data
      return response.data;
    } catch (error) {
      console.error('Error during login:', error);
      throw error; // Rethrow error to handle it in the calling function
    }
  }
};
export default authApi;
