import React, { useState, useEffect } from 'react';
import './CompanyListPage.css';
import companyApi from '../../../services/apiService/companyApi';
import { setLoading } from '../../../services/redux/loadingSlice';
import { useDispatch } from 'react-redux';
import configService from '../../../services/configService';
import Pagination from '../../../components/common/Pagination/Pagination'; 
import { toast } from 'react-toastify';
import { putDateOnPattern } from '../../../utils/functions';
import { useNavigate } from 'react-router-dom';
import FilterComponent from '../../../components/admin/FilterComponent/FilterComponent';
import { saveAs } from 'file-saver';
import EditIcon from '../../../components/icons/EditIcon/EditIcon';
import DescriptionRegisterModal from '../../../components/modals/DescriptionRegisterModal/DescriptionRegisterModal';
import ConfirmModal from '../../../components/modals/ConfirmModal/ConfirmModal';

const CompanyListPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const [totalClients, setTotalClients] = useState(0);
    const [isCompanyRegisterOpen, setIsCompanyRegisterOpen] = useState(false);
    const [isCompanyEditOpen, setIsCompanyEditOpen] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const quantity = configService.getDefaultNumberOfItemsTable(); 
    const orderBy = "Created:Desc";

    const openRegisterCompany = () => {
        setIsCompanyRegisterOpen(true);
    }

    const closeRegisterCompany = () => {
        setIsCompanyRegisterOpen(false);
    }

    const closeEditCompany = () => {
        setIsCompanyEditOpen(false);
    }

    useEffect(() => {
        const fetchLogs = async () => {
            dispatch(setLoading(true));
            try {
                const response = await companyApi.getCompaniesPaginated({ page, quantity, orderBy, term: searchTerm });
                setItems(response.Results);
                setTotalPages(response.TotalPages);
                setTotalClients(response.TotalCount);
            } catch (error) {
                toast.error('Erro ao buscar os itens.');
            } finally {
                dispatch(setLoading(false));
            }
        };
        fetchLogs();
    }, [page, quantity, searchTerm, refresh, dispatch]);

    const openClient = (code) => {
        navigate(`${code}`);
    }

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    const search = ({term} = {}) => {
        setSearchTerm(term);
    }

    const openEditCompany = (company) => {
        setSelectedCompany(company);
        setIsCompanyEditOpen(true);
    };

    const exportFunction = async (term) => {
        try {
            dispatch(setLoading(true));
            const response = await companyApi.exportCompanys({ term: term });
            
            if (response.Status === 200 && response.Object) {
                saveAs(response.Object, 'reportCompanys.csv');
                toast.success('Relatório gerado com sucesso!');
            } else {
                toast.error('Erro ao gerar o relatório 1');
            }
        } catch (error) {
            toast.error('Erro ao gerar o relatório');
        }
        finally{
            dispatch(setLoading(false));
        }
    };

    const createCompany = async (company) =>{
        try{
            dispatch(setLoading(true));
            var response = await companyApi.createCompany(company);

            toast.success(response.Description + ' criado com sucesso!');
            setRefresh((prev) => !prev);
        }
        catch(error){
            toast.error('Error :' + error);
        }
        finally{
            dispatch(setLoading(false));
        }
    }

    const updateCompany = async (company) =>{
        try{
            dispatch(setLoading(true));
            var response = await companyApi.updateCompany(selectedCompany.Code, company);

            toast.success(response.Value + ' atualizado com sucesso!');
            setRefresh((prev) => !prev);
        }
        catch(error){
            toast.error('Error :' + error);
        }
        finally{
            dispatch(setLoading(false));
        }
    }

    const handleYesModalDelete = async (item) => {
        dispatch(setLoading(true));
        try {
          if(item.IsActive) {
            await companyApi.setInactive(item.Code);
          } else {
            await companyApi.setActive(item.Code);
          }
          toast.success(item.Value + ' atualizado com sucesso!');
          // Trigger the refresh to re-fetch data
          setRefresh(prev => !prev);
        } catch (error) {
          toast.error('Error updating item status:' + error);
        } finally {
          dispatch(setLoading(false));
          setIsModalDeleteOpen(false);
        }
      };
    
      const handleNoModalDelete = () =>{
        setIsModalDeleteOpen(false);
      };

    const deleteOption = (company) => {
        setSelectedCompany(company);
        setIsModalDeleteOpen(true);
    }

    return (
    <div className="container-admin-page">
        <DescriptionRegisterModal
            isOpen={isCompanyRegisterOpen || isCompanyEditOpen}
            onClose={isCompanyRegisterOpen ? closeRegisterCompany : closeEditCompany}
            onSubmit={isCompanyRegisterOpen ? createCompany : updateCompany}
            oldValue={isCompanyEditOpen && selectedCompany ? selectedCompany.Description : ''}
            oldId={isCompanyEditOpen && selectedCompany ? selectedCompany.CompanyId : ''}
            showId={true}

        />
        <ConfirmModal
          isOpen={isModalDeleteOpen}
          title="Atenção"
          message={`Deseja realmente ${selectedCompany?.IsActive ? 'desativar' : 'ativar'} a empresa ${selectedCompany?.Description}?`}
          onYes={handleYesModalDelete}
          onNo={handleNoModalDelete}
          yesLabel="Confirm"
          noLabel="Cancel"
          confirmData={selectedCompany}
        />
        <h1>Lista das empresas</h1>
        <div className='container-admin-page-filters div-with-border'>
            <h3>Filtros</h3>
            <FilterComponent placeHolder={'Nome'} showTermFilter={true} submitFilter={search} exportFunction={exportFunction}/>
        </div>
        <div className='container-admin-page-table div-with-border'>
            <div className='right-component'>
                <button className='main-button' onClick={openRegisterCompany}>Nova Empresa</button>
            </div>
            <table className="admin-table">
                <thead>
                    <tr>
                        <th>Código</th>
                        <th>Código Interno</th>
                        <th>Descrição</th>
                        <th>Criado</th>
                        <th>Atualizado</th>
                        <th>Status</th>
                        <th></th>
                        </tr>
                    </thead>
                <tbody>
                {items.map((item) => (
                    <tr key={item.Code}>
                        <td><span className='option-link' onClick={() => openClient(item.Code)}>{item.Code}</span></td>
                        <td><span className='option-link' onClick={() => openClient(item.Code)}>{item.CompanyId}</span></td>
                        <td><span className='option-link' onClick={() => openClient(item.Code)}>{item.Description}</span></td>
                        <td><span className='option-link' onClick={() => openClient(item.Code)}>{putDateOnPattern(item.Created)}</span></td>
                        <td><span className='option-link' onClick={() => openClient(item.Code)}>{putDateOnPattern(item.Updated)}</span></td>
                        <td className='clickable'><span className={item.IsActive ? 'item-active brighten-on-hover' : 'item-inactive brighten-on-hover'} onClick={() => deleteOption(item)}>{item.IsActive ? 'Ativo' : 'Inativo'}</span></td>
                        <td><span className='option-link' onClick={() => openEditCompany(item)}><EditIcon/></span></td>
                    </tr>
                ))}
                </tbody>
            </table>
            <sub>Total de Empresas: {totalClients}</sub>
            <Pagination page={page} totalPages={totalPages} onPageChange={handlePageChange} />
        </div>
    </div>
    );
};

export default CompanyListPage;
