import api from './serviceApi'; // Import the axios instance

const addressApi = {
  /**
   * Fetch an address by its code.
   * @param {string} code - The code of the address.
   * @param {string} include - Optional parameter to include additional data.
   * @returns {Promise<Object>} - The address data.
   */
  getAddressByCode: async (code, include = '') => {
    try {
      const response = await api.get(`/address/${code}`, {
        params: { include }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching address by code:', error);
      throw error;
    }
  },

  /**
   * Fetch a paginated list of addresses with optional filters.
   * @param {Object} params - Query parameters such as page, quantity, term, orderBy, etc.
   * @returns {Promise<Object>} - The paginated list of addresses.
   */
  getAddressesPaginated: async (params) => {
    try {
      const response = await api.get('/address/pagged', { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching addresses paginated:', error);
      throw error;
    }
  },

  /**
   * Export a list of addresses based on filters.
   * @param {Object} params - Query parameters such as quantityMax, isActive, term, etc.
   * @returns {Promise<Blob>} - The exported file data.
   */
  exportAddresses: async (params) => {
    try {
      const response = await api.get('/address/export', { params, responseType: 'blob' });
      return response.data;
    } catch (error) {
      console.error('Error exporting addresses:', error);
      throw error;
    }
  },

  /**
   * Fetch all addresses with optional filters.
   * @param {Object} params - Optional query parameters.
   * @returns {Promise<Object>} - The list of addresses.
   */
  getAllAddresses: async (params = {}) => {
    try {
      const response = await api.get('/address', { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching addresses:', error);
      throw error;
    }
  },

  /**
   * Create a new address.
   * @param {Object} addressData - The address data to create.
   * @returns {Promise<Object>} - The created address data.
   */
  createAddress: async (addressData) => {
    try {
      const response = await api.post('/address', addressData);
      return response.data;
    } catch (error) {
      console.error('Error creating address:', error);
      throw error;
    }
  },

  /**
   * Update an address.
   * @param {Object} addressData - The address data to update.
   * @returns {Promise<Object>} - The updated address data.
   */
  updateAddress: async (addressData) => {
    try {
      const response = await api.put('/address', addressData);
      return response.data;
    } catch (error) {
      console.error('Error updating address:', error);
      throw error;
    }
  },

  /**
   * Delete an address by its code.
   * @param {string} addressCode - The address code to delete.
   * @returns {Promise<Object>} - The result of the deletion.
   */
  deleteAddress: async (addressCode) => {
    try {
      const response = await api.delete('/address', {
        data: { code: addressCode }
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting address:', error);
      throw error;
    }
  }
};

export default addressApi;
