import './ConfigurationPage.css';
import { useNavigate } from 'react-router-dom';

const ConfigurationPage = () => {
  const navigate = useNavigate();
  
  const openLink = (link) => {
    navigate(link);
  }

  return(
        <div className="container-admin-page">
            <h1>
                Configurações
            </h1>

            <div className='configurations-list-options'>
                <div className='configuration-options-column'>
                    <div className='configuration-option clickable brighten-on-hover' onClick={() => openLink('editar-senha')}>
                        <span>Alterar Senha</span>
                    </div>
                </div>
                <div className='configuration-options-column'>
                    <div className='configuration-option clickable brighten-on-hover' onClick={() => openLink('logs')}>
                        <span>Tela de Logs</span>
                    </div>
                </div>
                <div className='configuration-options-column'>
                    <div className='configuration-option clickable brighten-on-hover' onClick={() => openLink('emails')}>
                        <span>Emails enviados</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfigurationPage;