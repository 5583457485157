import React from 'react';

const UsersIcon = ({ size = 24, color = 'white' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M28.3333 35V31.6667C28.3333 29.8986 27.6309 28.2029 26.3807 26.9526C25.1305 25.7024 23.4348 25 21.6667 25H8.33332C6.56521 25 4.86952 25.7024 3.61928 26.9526C2.36904 28.2029 1.66666 29.8986 1.66666 31.6667V35M38.3333 35V31.6667C38.3322 30.1895 37.8406 28.7546 36.9356 27.5872C36.0306 26.4198 34.7635 25.5859 33.3333 25.2167M26.6667 5.21667C28.1007 5.58384 29.3717 6.41784 30.2794 7.58718C31.1871 8.75653 31.6797 10.1947 31.6797 11.675C31.6797 13.1553 31.1871 14.5935 30.2794 15.7628C29.3717 16.9322 28.1007 17.7662 26.6667 18.1333M21.6667 11.6667C21.6667 15.3486 18.6819 18.3333 15 18.3333C11.3181 18.3333 8.33332 15.3486 8.33332 11.6667C8.33332 7.98477 11.3181 5 15 5C18.6819 5 21.6667 7.98477 21.6667 11.6667Z"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default UsersIcon;
