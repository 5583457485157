import React, { useRef, useState } from 'react';
import './UserRegistrationModal.css';
import { toast } from 'react-toastify';
import { validateRegistrationStep1 } from '../../../utils/validationUtils';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../services/redux/loadingSlice';
import { login } from '../../../services/redux/authSlice';
import { useNavigate } from 'react-router-dom';
import personApi from '../../../services/apiService/personApi';
import authApi from '../../../services/apiService/authApi';
import ConfigService from '../../../services/configService';
import PersonalInfoForm from './components/PersonalInfoForm';
import AddressInfoForm from './components/AddressInfoForm';

const UserRegistrationModal = ({ isOpen, onClose, personTypeCode = ConfigService.getClientPersonTypeCode(), loginAfterDone = true }) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1); // 1 for personal info, 2 for address info
  const [isPhysical, setIsPhysical] = useState(true);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    isPhysical: 1,
    isLegal: 0,
    phone1:'',
    mobile: '',
    password: '',
    passwordVerify: '',
    physicalDocument: '',
    legalDocument: '',
    personTypeCode: personTypeCode,
    address: [],
  });
  const [addressData, setAddressData] = useState({
    longAddress: '',
    number: '',
    complement: '',
    neighborhood: '',
    zipcode: '',
    cityCode: '',
    stateCode: '',
    additionalInformation: ''
  });

  const inputRefs = {
    name: useRef(null),
    physicalDocument: useRef(null),
    legalDocument: useRef(null),
    mobile: useRef(null),
    email: useRef(null),
    password: useRef(null),
    passwordVerify: useRef(null),
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
  
    setAddressData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNext = async () => {
    const errors = validateRegistrationStep1(formData, isPhysical);

    if (Object.keys(errors).length === 0) {
      setStep(2); // Go to step 2 only if the fetch is successful
    } else {
      const firstErrorKey = Object.keys(errors)[0];
      const firstErrorRef = inputRefs[firstErrorKey]?.current;

      if (firstErrorRef) {
        firstErrorRef.focus();
        toast.error(errors[firstErrorKey]);
      } else {
        toast.error(errors[firstErrorKey]);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));

    try {
      var data = formData;
      data.isLegal = isPhysical ? 0 : 1;
      data.isPhysical = isPhysical ? 1 : 0;
      data.address = [addressData];
      
      await personApi.createPerson(data);
      dispatch(setLoading(false));
      toast.success('Usuário cadastrado com sucesso!');

      if(loginAfterDone){
        dispatch(setLoading(true));
        const response = await authApi.login({ username: data.email, password: data.password });
        dispatch(setLoading(false));
  
        dispatch(login(response));
        navigate('/');
        toast.success('Bem Vindo!');
      }
      
      clean();
      setStep(1);
      onClose();
    } catch (error) {
      toast.error(error.response.data);
    }
    finally{
      dispatch(setLoading(false));
    }
  };

  const clean = () => {
    setFormData({
      email: '',
      name: '',
      isPhysical: 1,
      isLegal: 0,
      phone1:'',
      mobile: '',
      password: '',
      passwordVerify: '',
      physicalDocument: '',
      legalDocument: '',
      personTypeCode: personTypeCode,
      address: {
        longAddress: '',
        number: '',
        complement: '',
        neighborhood: '',
        zipcode: '',
        cityCode: '',
        stateCode: '',
        additionalInformation: '',
      },
    });
    setAddressData(
      {
        longAddress: '',
        number: '',
        complement: '',
        neighborhood: '',
        zipcode: '',
        cityCode: '',
        stateCode: '',
        additionalInformation: ''
      }
    );
  }

  if (!isOpen) return null;

  return (
    <div className="modal-backdrop-register">
      <div className="modal-container-register">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <form onSubmit={handleSubmit} className='register-form'>
          {step === 1 && (
            <div className="personal-info-register">
              <div className='modal-title space-triple-bottom'>
                <span>Cadastro</span>
              </div>
              
              <PersonalInfoForm formData={formData} handleInputChange={handleInputChange} inputRefs={inputRefs} isPhysical={isPhysical} setIsPhysical={setIsPhysical}/>

              <div className="form-group-register option-next-register">
                <button className='main-button' type="button" onClick={() => clean()}>
                  Limpar
                </button>
                <button className='main-button' type="button" onClick={handleNext}>
                  Próximo
                </button>
              </div>
            </div>
          )}

          {step === 2 && (
            <div className="address-info">
              <div className='modal-title space-triple-bottom'>
                <span>Informações de Endereço</span>
              </div>

              <AddressInfoForm addressData={addressData} handleAddressChange={handleAddressChange} setAddressData={setAddressData}/>

              <div className="form-group-register option-next-register">
                <button className='main-button' type="button" onClick={() => setStep(1)}>
                  Voltar
                </button>
                <button className='main-button' type="submit">
                  Cadastrar
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default UserRegistrationModal;
