import config from '../config/config.json';

class ConfigService {
  constructor() {
    this.config = config;
  }

  getApiUrl() {
    return this.config.apiUrl;
  }

  getChannelCode() {
    return this.config.channelCode;
  }

  getClientPersonTypeCode() {
    return this.config.ClientPersonTypeCode;
  }

  getAdminPersonTypeCode() {
    return this.config.AdminPersonTypeCode;
  }

  getSellerPersonTypeCode() {
    return this.config.SellerPersonTypeCode;
  }

  getDefaultNumberOfItemsTable() {
    return this.config.DefaultNumberOfItemsTable;
  }
}

var configService =  new ConfigService();
export default configService;
