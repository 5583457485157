import api from './serviceApi'; // Import the axios instance

const modelApi = {
  /**
   * Fetch a model by code.
   * @param {string} include - Optional parameter to include additional data.
   * @returns {Promise<Object>} - The model data.
   */
  getModels: async (include = '') => {
    try {
      const response = await api.get(`/model`, {
        params: { include }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching model by code:', error);
      throw error;
    }
  },

  /**
   * Fetch a list of models with optional filters.
   * @param {Object} params - Query parameters such as page, quantity, term, orderBy, etc.
   * @returns {Promise<Object>} - The paginated list of products.
   */
  getModelsByDescription: async (params) => {
    try {
      const response = await api.get('/model/by-description', { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching products by description:', error);
      throw error;
    }
  },

  /**
   * Fetch a model by code.
   * @param {string} code - The code of the model.
   * @param {string} include - Optional parameter to include additional data.
   * @returns {Promise<Object>} - The model data.
   */
  getModelByCode: async (code, include = '') => {
    try {
      const response = await api.get(`/model/${code}`, {
        params: { include }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching model by code:', error);
      throw error;
    }
  },

  /**
   * Fetch a list of models with optional filters and pagination.
   * @param {Object} params - Query parameters such as quantity, term, orderBy, etc.
   * @returns {Promise<Object>} - The paginated list of models.
   */
  getModelsPaginated: async (params) => {
    try {
      const response = await api.get('/model/pagged', { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching models:', error);
      throw error;
    }
  },

  /**
   * Export a list of models with filters.
   * @param {Object} params - Query parameters such as quantityMax, isActive, term, etc.
   * @returns {Promise<Blob>} - The exported file data.
   */
  exportModels: async (params) => {
    try {
      const response = await api.get('/model/export', { params, responseType: 'blob' });
      return response.data;
    } catch (error) {
      console.error('Error exporting models:', error);
      throw error;
    }
  },

  /**
   * Create a new model.
   * @param {Object} modelData - The model data to create.
   * @returns {Promise<Object>} - The created model data.
   */
  createModel: async (modelData) => {
    try {
      const response = await api.post('/model', modelData);
      return response.data;
    } catch (error) {
      console.error('Error creating model:', error);
      throw error;
    }
  },

  /**
   * Update an existing model.
   * @param {Object} modelData - The model data to update.
   * @returns {Promise<Object>} - The updated model data.
   */
  updateModel: async (modelData) => {
    try {
      const response = await api.put('/model', modelData);
      return response.data;
    } catch (error) {
      console.error('Error updating model:', error);
      throw error;
    }
  },

/**
   * inativate a model by their code.
   * @param {string} modelCode - The model code to inativate.
   * @returns {Promise<Object>} - The result of the deletion.
   */
setInactive: async (modelCode) => {
  try {
    const response = await api.put('/model/setInactive/' + modelCode);
    return response.data;
  } catch (error) {
    console.error('Error inativating model:', error);
    throw error;
  }
},

/**
 * active a model by their code.
 * @param {string} modelCode - The model code to active.
 * @returns {Promise<Object>} - The result of the deletion.
 */
setActive: async (modelCode) => {
  try {
    const response = await api.put('/model/setActive/' + modelCode);
    return response.data;
  } catch (error) {
    console.error('Error inativating model:', error);
    throw error;
  }
},

  /**
   * Delete a model.
   * @param {string} code - The code of the model to delete.
   * @returns {Promise<Object>} - The result of the deletion.
   */
  deleteModel: async (code) => {
    try {
      const response = await api.delete('/model', {
        data: { code }
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting model:', error);
      throw error;
    }
  }
};

export default modelApi;
