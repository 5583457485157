import api from './serviceApi'; // Import the axios instance

// Define the endpoints
const cityApiService = {
  getCity: (code, include) => api.get(`/city/${code}`, {
    params: { include },
  }),

  exportCities: (quantityMax, isActive, term, orderBy, include) => api.get('/city/export', {
    params: { quantityMax, isActive, term, orderBy, include },
  }),

  getCitiesPaginated: (page, quantity, isActive, term, orderBy, include) => api.get('/city/pagged', {
    params: { page, quantity, isActive, term, orderBy, include },
  }),

  importCities: (file) => {
    const formData = new FormData();
    formData.append('file', file);
    return api.post('/city/import', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },

  getAllStates: () => api.get('/city/GetAllStates'),

  getCityByCep: (cep) => api.get('/city/GetByCep', {
    params: { cep },
  }),

  getCityByState: (state) => api.get('/city/GetByState', {
    params: { state },
  }),

  getAllCities: (include) => api.get('/city', {
    params: { include },
  }),

  createCity: (cityData) => api.post('/city', cityData),

  updateCity: (cityData) => api.put('/city', cityData),

  deleteCity: (cityData) => api.delete('/city', { data: cityData }),
};

export default cityApiService;
