import React, {useState} from 'react';
import './ShoopingCartLastStage.css';
import ClientListPage from '../../../components/admin/ClientList/ClientList';
import ShoppingCartResum from '../../../components/admin/ShoppingCartResum/ShoppingCartResum';

const ShoopingCartLastStage = () => {
    const[clientCode, setClienteCode] = useState('');

    return (
        <div className="container-admin-page">
            {
                clientCode ? 
                <ShoppingCartResum personCode={clientCode}/>
                :
                <ClientListPage selectClient={setClienteCode}/>
            }
        </div>
    )
};

export default ShoopingCartLastStage;
