import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ClientLayout from '../layouts/client/ClientLayout';
import HomePage from '../pages/clients/HomePage/HomePage';
import Profile from '../pages/common/Profile/Profile';
import { useSelector } from 'react-redux';
import { selectUserCode } from '../services/redux/authSlice';
import RecoveryPassword from '../pages/common/RecoveryPassword/RecoveryPassword';
import BrandClientListPage from '../pages/clients/ModelClientListPage/ModelClientListPage';
import ModelClientItemPage from '../pages/clients/ModelClientItemPage/ModelClientItemPage';
import CartPage from '../pages/clients/CartPage/CartPage';
import ShoppingCartListPage from '../pages/clients/ShoppingCartListPage/ShoppingCartListPage';
import ShoppingCartDetailsPage from '../pages/clients/ShoppingCartDetailsPage/ShoppingCartDetailsPage';

const ClientRoutes = () => {
  const userCode = useSelector(selectUserCode);

  return(
    <ClientLayout>
      <Routes>
        <Route path="/" element={<HomePage />} />
        {
          userCode &&
          <>
            <Route path="/perfil" element={<Profile children={true}/>} />
            <Route path="/orcamentos" element={<ShoppingCartListPage />} />
            <Route path="/orcamentos/:code" element={<ShoppingCartDetailsPage />} />
          </>
        }

        {
          !userCode &&
          <>
            <Route path='recovery-pass/:guid' element={<RecoveryPassword/>}/>
          </>
        }
        <Route path="/modelos" element={<BrandClientListPage />} />
        <Route path="/modelos/:guid" element={<ModelClientItemPage />} />
        <Route path="/carrinho" element={<CartPage />} />
        <Route path="/*" element={<HomePage />} />
      </Routes>
    </ClientLayout>
  ) 
};

export default ClientRoutes;
