import React, { useState, useEffect } from 'react';
import './MailListPage.css';
import mailMessageApi from '../../../services/apiService/mailMessageApi';
import { setLoading } from '../../../services/redux/loadingSlice';
import { useDispatch } from 'react-redux';
import configService from '../../../services/configService';
import Pagination from '../../../components/common/Pagination/Pagination'; 
import { toast } from 'react-toastify';
import { putDateOnPattern } from '../../../utils/functions';
import { useNavigate } from 'react-router-dom';
import OpenIcon from '../../../components/icons/OpenIcon/OpenIcon';
import FilterComponent from '../../../components/admin/FilterComponent/FilterComponent';
import { saveAs } from 'file-saver';

const MailListPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const [totalClients, setTotalClients] = useState(0);
    const [refresh, setRefresh] = useState(false);
    const quantity = configService.getDefaultNumberOfItemsTable(); 
    const orderBy = "Created:Desc";

    useEffect(() => {
        const fetchLogs = async () => {
            dispatch(setLoading(true));
            try {
                const response = await mailMessageApi.getMailMessagesPaginated({ page, quantity, orderBy, include: 'Person', term: searchTerm });
                setItems(response.Results);
                setTotalPages(response.TotalPages);
                setTotalClients(response.TotalCount);
            } catch (error) {
                toast.error('Erro ao buscar os itens.');
            } finally {
                dispatch(setLoading(false));
            }
        };
        fetchLogs();
    }, [page, quantity, refresh, searchTerm, dispatch]);

    const openClient = (code) => {
        navigate(`${code}`);
    }

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    const reenviarEmail = async (code) => {
        dispatch(setLoading(true));
        try{
            var result = await mailMessageApi.resendEmail(code);

            if(result.MailMessageStatus === 'Sent')
                toast.success('Email reenviado!');
            else 
                toast.error('Erro ao reenviar o email');
        }
        catch(err){
            toast.error('Erro ao reenviar o email');
        }
        finally{
            dispatch(setLoading(false));
            setRefresh(prev => !prev);
        }
    }

    const search = ({term} = {}) => {
        setSearchTerm(term);
    }

    const exportFunction = async (term) => {
        try {
            dispatch(setLoading(true));
            const response = await mailMessageApi.exportMailMessages({ term: term });
            
            if (response.Status === 200 && response.Object) {
                saveAs(response.Object, 'reportMail.csv');
                toast.success('Relatório gerado com sucesso!');
            } else {
                toast.error('Erro ao gerar o relatório 1');
            }
        } catch (error) {
            toast.error('Erro ao gerar o relatório');
        }
        finally{
            dispatch(setLoading(false));
        }
    };

    return (
    <div className="container-admin-page">
        <h1>Lista dos emails enviados</h1>
        <div className='container-admin-page-filters div-with-border'>
            <h3>Filtros</h3>
            <FilterComponent placeHolder={'Remetente | Destinatário | Assunto'} showTermFilter={true} showEndDate={false} showStartDate={false} submitFilter={search} exportFunction={exportFunction}/>
        </div>
        <div className='container-admin-page-table div-with-border'>
            <table className="admin-table">
                <thead>
                    <tr>
                        <th>Remetente</th>
                        <th>Destinatário</th>
                        <th>Assunto</th>
                        <th>Data Envio</th>
                        <th>Status</th>
                        <th>Opção</th>
                        <th></th>
                        </tr>
                    </thead>
                <tbody>
                {items.map((item) => (
                    <tr key={item.Code}>
                        <td><span className='option-link' onClick={() => openClient(item.Code)}>{item.Person.Email}</span></td>
                        <td><span className='option-link' onClick={() => openClient(item.Code)}>{item.To}</span></td>
                        <td><span className='option-link' onClick={() => openClient(item.Code)}>{item.Subject}</span></td>
                        <td><span className='option-link' onClick={() => openClient(item.Code)}>{putDateOnPattern(item.Created)}</span></td>
                        <td><span className='option-link' onClick={() => openClient(item.Code)}>{item.MailMessageStatus === 'Sent' ? 'Enviado' : 'Erro ao enviar'}</span></td>
                        <td><button className='main-button' disabled={item.MailMessageStatus === 'Sent'} onClick={() => reenviarEmail(item.Code)}>Reenviar</button></td>
                        <td><span className='option-link' onClick={() => openClient(item.Code)}><OpenIcon/></span></td>
                    </tr>
                ))}
                </tbody>
            </table>
            <sub>Total de Emails: {totalClients}</sub>
            <Pagination page={page} totalPages={totalPages} onPageChange={handlePageChange} />
        </div>
    </div>
    );
};

export default MailListPage;
