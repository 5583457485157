import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../services/redux/loadingSlice';
import mailMessageApi from '../../../services/apiService/mailMessageApi';
import './MailPage.css';

const MailPage = () => {
  const dispatch = useDispatch();
  const { code } = useParams();
  const [mail, setMail] = useState(null);

  useEffect(() => {
    const fetchMail = async () => {
      try {
        dispatch(setLoading(true));
        const response = await mailMessageApi.getMailMessageByCode(code, 'Person');
        setMail(response);
      } catch (error) {
        toast.error('Erro ao carregar o email!');
      } finally {
        dispatch(setLoading(false));
      }
    };

    fetchMail();
  }, [code, dispatch]);

  const downloadHtmlContent = () => {
    const blob = new Blob([mail.Body], { type: 'text/html;charset=utf-8' });
    saveAs(blob, `${mail.Subject.replace(/ /g, '_')}.html`);
  };

  const handleResendEmail = async () => {
    try {
      dispatch(setLoading(true));
      await mailMessageApi.resendEmail(mail.Code);
      toast.success('Email reenviado com sucesso!');
    } catch (error) {
      toast.error('Falha ao reenviar. tente novamente!');
    }
    finally{
      dispatch(setLoading(false));
    }
  };

  if (!mail) {
    return <p>Loading...</p>;
  }

  return (
    <div className="container-admin-page">
        <h1>Detalhes do email</h1>

        <div className="mail-info div-with-border">
            <h2>Informações básicas:</h2>
            <p><strong>Assunto:</strong> {mail.Subject}</p>
            <p><strong>Destinatário:</strong> {mail.To}</p>
            <p><strong>Status:</strong> {mail.MailMessageStatus === 'Sent' ? 'Enviado' : 'Não enviado'}</p>
            <p><strong>Nome:</strong> {mail.Person.Name}</p>
            <p><strong>Email:</strong> {mail.Person.Email}</p>
            <button className="main-button" onClick={downloadHtmlContent}>
            Download HTML
            </button>
            {mail.MailMessageStatus !== 'Sent' && (
            <button className="main-button" onClick={handleResendEmail}>
                Resend Email
            </button>
            )}
        </div>
    </div>
  );
};

export default MailPage;
