import api from './serviceApi'; // Import the axios instance

const modelImageApi = {
  /**
   * Fetch a model by code.
   * @param {string} code - The code of the model.
   * @param {string} include - Optional parameter to include additional data.
   * @returns {Promise<Object>} - The model data.
   */
  getModelByCode: async (code, include = '') => {
    try {
      const response = await api.get(`/modelImage/${code}`, {
        params: { include }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching model by code:', error);
      throw error;
    }
  },

  /**
   * Fetch a list of models with optional filters and pagination.
   * @param {Object} params - Query parameters such as quantity, term, orderBy, etc.
   * @returns {Promise<Object>} - The paginated list of models.
   */
  getModelsPaginated: async (params) => {
    try {
      const response = await api.get('/modelImage/pagged', { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching models:', error);
      throw error;
    }
  },

  /**
   * Export a list of models with filters.
   * @param {Object} params - Query parameters such as quantityMax, isActive, term, etc.
   * @returns {Promise<Blob>} - The exported file data.
   */
  exportModels: async (params) => {
    try {
      const response = await api.get('/modelImage/export', { params, responseType: 'blob' });
      return response.data;
    } catch (error) {
      console.error('Error exporting models:', error);
      throw error;
    }
  },

  /**
   * Create a new model.
   * @param {Object} modelData - The model data to create.
   * @returns {Promise<Object>} - The created model data.
   */
  createModel: async (modelData) => {
    try {
      const response = await api.post('/modelImage', modelData);
      return response.data;
    } catch (error) {
      console.error('Error creating model:', error);
      throw error;
    }
  },

  /**
   * Update an existing model.
   * @param {Object} modelData - The model data to update.
   * @returns {Promise<Object>} - The updated model data.
   */
  updateModel: async (modelData) => {
    try {
      const response = await api.put('/modelImage', modelData);
      return response.data;
    } catch (error) {
      console.error('Error updating model:', error);
      throw error;
    }
  },

  /**
   * Delete a model.
   * @param {string} data - The code of the model to delete.
   * @returns {Promise<Object>} - The result of the deletion.
   */
  deleteModel: async (data) => {
    try {
      // Use DELETE with data as configuration (for body in the delete request)
      await api.post('/modelImage/delete', 
        data
      );
      // No return needed for 204 response
    } catch (error) {
      console.error('Error deleting image:', error);
      throw error;
    }
  }
};

export default modelImageApi;
