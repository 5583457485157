import React, {useState} from 'react';
import './AdminHeader.css';
import UserCustomIcon from '../../icons/UserCustomIcon/UserCustomIcon';
import { useSelector } from 'react-redux';
import { selectUserName, logout } from '../../../services/redux/authSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const AdminHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userName = useSelector(selectUserName);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleUserIconClick = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    dispatch(logout());
    toast.success('Volte sempre!');
    setDropdownOpen(false);
    navigate('/');
  };

  return (
    <header className="admin-header">
      <div className='admin-header__all'>
        <div className="admin-header__left">
          <h1>Painel Administrativo</h1>
        </div>
        <div className='navbar__menu-item'>
          <div className="admin-header__right" onClick={handleUserIconClick}>
            <span>Olá, {userName}</span>
            <UserCustomIcon size={32} color="black" />
          </div>
          {isDropdownOpen && (
            <div className="navbar__dropdown">
              <a href="/admin/perfil" className="navbar__dropdown-item">Perfil</a>
              <div onClick={handleLogout} className="navbar__dropdown-item clickable">Sair</div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default AdminHeader;
