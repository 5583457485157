import api from './serviceApi'; // Import the axios instance

const shoppingCartApi = {
  /**
   * Fetch a shopping cart by its code.
   * @param {string} code - The code of the shopping cart.
   * @param {string} include - Optional parameter to include additional data.
   * @returns {Promise<Object>} - The shopping cart data.
   */
  getShoppingCartByCode: async (code, include = '') => {
    try {
      const response = await api.get(`/shopping-cart/${code}`, {
        params: { include }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching shopping cart by code:', error);
      throw error;
    }
  },

  /**
   * Fetch a paginated list of shopping carts with optional filters.
   * @param {Object} params - Query parameters such as page, quantity, term, orderBy, etc.
   * @returns {Promise<Object>} - The paginated list of shopping carts.
   */
  getShoppingCartsPaginated: async (params) => {
    try {
      const response = await api.get('/shopping-cart/pagged', { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching shopping carts paginated:', error);
      throw error;
    }
  },

  /**
   * Export a list of shopping carts based on filters.
   * @param {Object} params - Query parameters such as quantityMax, isActive, term, etc.
   * @returns {Promise<Blob>} - The exported file data.
   */
  exportShoppingCarts: async (params) => {
    try {
      const response = await api.get('/shopping-cart/export', { params, responseType: 'blob' });
      return response.data;
    } catch (error) {
      console.error('Error exporting shopping carts:', error);
      throw error;
    }
  },

  /**
   * Create a new shopping cart.
   * @param {Object} cartData - The shopping cart data to create.
   * @returns {Promise<Object>} - The created shopping cart data.
   */
  createShoppingCart: async (cartData) => {
    try {
      const response = await api.post('/shopping-cart', cartData);
      return response.data;
    } catch (error) {
      console.error('Error creating shopping cart:', error);
      throw error;
    }
  },

  /**
   * Update an existing shopping cart.
   * @param {Object} cartData - The shopping cart data to update.
   * @returns {Promise<Object>} - The updated shopping cart data.
   */
  updateShoppingCart: async (cartData) => {
    try {
      const response = await api.put('/shopping-cart', cartData);
      return response.data;
    } catch (error) {
      console.error('Error updating shopping cart:', error);
      throw error;
    }
  },

  /**
   * Update an existing shopping cart.
   * @param {Object} cartData - The shopping cart data to update.
   * @returns {Promise<Object>} - The updated shopping cart data.
   */
  startService: async (code) => {
    try {
      const response = await api.put('/shopping-cart/start-service/' + code);
      return response.data;
    } catch (error) {
      console.error('Error updating shopping cart:', error);
      throw error;
    }
  },

  /**
   * Update an existing shopping cart.
   * @param {Object} cartData - The shopping cart data to update.
   * @returns {Promise<Object>} - The updated shopping cart data.
   */
  finishService: async (code, cartData) => {
    try {
      const response = await api.put('/shopping-cart/finish-service/' + code, cartData);
      return response.data;
    } catch (error) {
      console.error('Error updating shopping cart:', error);
      throw error;
    }
  },

  /**
   * Update an existing shopping cart.
   * @param {Object} cartData - The shopping cart data to update.
   * @returns {Promise<Object>} - The updated shopping cart data.
   */
  cancelService: async (code, cartData) => {
    try {
      const response = await api.put('/shopping-cart/cancel-service/' + code, cartData);
      return response.data;
    } catch (error) {
      console.error('Error updating shopping cart:', error);
      throw error;
    }
  },

  /**
   * Delete a shopping cart by its code.
   * @param {Object} cartData - The shopping cart data to delete.
   * @returns {Promise<Object>} - The result of the deletion.
   */
  deleteShoppingCart: async (cartData) => {
    try {
      const response = await api.delete('/shopping-cart', { data: cartData });
      return response.data;
    } catch (error) {
      console.error('Error deleting shopping cart:', error);
      throw error;
    }
  },

  /**
   * Fetch all shopping carts with optional filters.
   * @param {Object} params - Optional query parameters.
   * @returns {Promise<Object>} - The list of shopping carts.
   */
  getAllShoppingCarts: async (params = {}) => {
    try {
      const response = await api.get('/shopping-cart', { params });
      return response.data;
    } catch (error) {
      console.error('Error fetching shopping carts:', error);
      throw error;
    }
  }
};

export default shoppingCartApi;
