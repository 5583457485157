import React, { useState, useEffect } from 'react';

const RegisterGrupoModal = ({ isOpen, onClose, onSubmit, oldDescription = '', oldId = '', oldType = '' }) => {
    const [item, setItem] = useState({
        Description: '',
        GroupId: '',
        Type: '',
    });

    useEffect(() => {
        setItem((prevState) => ({
            ...prevState,
            Description: oldDescription,
        }));
    }, [oldDescription]);

    useEffect(() => {
        setItem((prevState) => ({
            ...prevState,
            GroupId: oldId,
        }));
    }, [oldId]);

    useEffect(() => {
        setItem((prevState) => ({
            ...prevState,
            Type: oldType,
        }));
    }, [oldType]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setItem((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(item);
        clear();
        onClose();
    };

    const clear = () => {
        setItem({
            Description: '',
            GroupId: '',
            Type: '',
        });
    };

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop-register">
            <div className="modal-container-register">
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                <form onSubmit={handleSubmit} className="register-form">
                    <div className="form-group-register form-group-inside">
                        <label>Nome:</label>
                        <input
                            type="text"
                            name="Description"
                            value={item.Description}
                            onChange={handleInputChange}
                            required
                        />
                    </div>

                    <div className="form-group-register form-group-inside">
                        <label>Tipo:</label>
                        <input
                            type="text"
                            name="Type"
                            value={item.Type}
                            onChange={handleInputChange}
                            required
                        />
                    </div>

                    <div className="form-group-register form-group-inside">
                        <label>Código Interno:</label>
                        <input
                            type="text"
                            name="GroupId"
                            value={item.GroupId}
                            onChange={handleInputChange}
                            required
                        />
                    </div>

                    <div className="form-group-register option-next-register">
                        <button className="main-button" type="button" onClick={onClose}>
                            Cancelar
                        </button>
                        <button className="main-button" type="submit">
                            Salvar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default RegisterGrupoModal;
